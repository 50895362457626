import React, { Suspense } from 'react';
import { Canvas } from '@react-three/fiber';
import { OrbitControls, PerspectiveCamera } from "@react-three/drei";
import * as THREE from 'three';
import Lights from './Lights';
import Loader from './Loader';

// Import all Token components
import Token0 from './0-ZERO-COIN-FRONT-ZRC20-ZERO-COIN';
import Token1 from './1-GECKO-ERC20-ZERO-TOKEN';
import Token2 from './2-TIGER-ERC20-ZERO-TOKEN';
import Token3 from './3-RHINO-ERC20-ZERO-TOKEN';
import Token4 from './4-STEGO-ERC20-ZERO-TOKEN';
import Token5 from './5-CHEETO-ERC20-ZERO-TOKEN';
import Token6 from './6-BISON-ERC20-ZERO-TOKEN';
import Token7 from './7-DINO-ERC20-ZERO-TOKEN';
import Token8 from './8-MOA-ERC20-ZERO-TOKEN';
import Token9 from './9-WOOLY-ERC20-ZERO-TOKEN';
import Token10 from './10-SABRE-ERC20-ZERO-TOKEN';
import Token11 from './11-QUAGGA-ERC20-ZERO-TOKEN';
import Token12 from './12-PANDA-ERC20-ZERO-TOKEN';
import Token13 from './13-PTERAN-ERC20-ZERO-TOKEN';
import Token14 from './14-THYLAC-ERC20-ZERO-TOKEN';
import Token15 from './15-AUROCH-ERC20-ZERO-TOKEN';
import Token16 from './16-IVORY-ERC20-ZERO-TOKEN';
import Token17 from './17-PANG-ERC20-ZERO-TOKEN';
import Token18 from './18-CONDOR-ERC20-ZERO-TOKEN';
import Token19 from './19-BAIJI-ERC20-ZERO-TOKEN';
import Token20 from './20-GLYPTO-ERC20-ZERO-TOKEN';
import Token21 from './21-MEGAL-ERC20-ZERO-TOKEN';
import Token22 from './22-DIRE-ERC20-ZERO-TOKEN';
import Token23 from './23-QUETZAL-ERC20-ZERO-TOKEN';
import Token24 from './24-LYNX-ERC20-ZERO-TOKEN';
import Token25 from './25-SAURO-ERC20-ZERO-TOKEN';
import Token26 from './26-OKAPI-ERC20-ZERO-TOKEN';
import Token27 from './27-KAKAPO-ERC20-ZERO-TOKEN';
import Token28 from './28-VAQUITA-ERC20-ZERO-TOKEN';
import Token29 from './29-GORILLA-ERC20-ZERO-TOKEN';
import Token30 from './30-RED-WOLF-ERC20-ZERO-TOKEN';
import Token31 from './31-TORTOISE-ERC20-ZERO-TOKEN';
import Token32 from './32-OWL-ERC20-ZERO-TOKEN';
import Token33 from './33-MANATEE-ERC20-ZERO-TOKEN';
import Token34 from './34-GIBBON-ERC20-ZERO-TOKEN';
import Token35 from './35-RIVER-ERC20-ZERO-TOKEN';
import Token36 from './36-AMUR-ERC20-ZERO-TOKEN';
import Token37 from './37-ADDAX-ERC20-ZERO-TOKEN';
import Token38 from './38-TUATARA-ERC20-ZERO-TOKEN';
import Token39 from './39-IBIS-ERC20-ZERO-TOKEN';
import Token40 from './40-ELEPHANT-ERC20-ZERO-TOKEN';
import Token41 from './41-LEMUR-ERC20-ZERO-TOKEN';
import Token42 from './42-DODO-ERC20-ZERO-TOKEN';
import Token43 from './43-HARPY-ERC20-ZERO-TOKEN';
import Token44 from './44-WOLF-ERC20-ZERO-TOKEN';
import Token45 from './45-SUN-BEAR-ERC20-ZERO-TOKEN';
import Token46 from './46-ORCA-ERC20-ZERO-TOKEN';
import Token47 from './47-PIKA-ERC20-ZERO-TOKEN';
import Token48 from './48-SHARK-ERC20-ZERO-TOKEN';
import Token49 from './49-MYNAH-ERC20-ZERO-TOKEN';
import Token50 from './50-CAMEL-ERC20-ZERO-TOKEN';

const tokenComponents = [
  Token0, Token1, Token2, Token3, Token4, Token5, Token6, Token7, Token8, Token9, Token10,
  Token11, Token12, Token13, Token14, Token15, Token16, Token17, Token18, Token19, Token20,
  Token21, Token22, Token23, Token24, Token25, Token26, Token27, Token28, Token29, Token30,
  Token31, Token32, Token33, Token34, Token35, Token36, Token37, Token38, Token39, Token40,
  Token41, Token42, Token43, Token44, Token45, Token46, Token47, Token48, Token49, Token50
];

const ModelView2 = ({ activeIndex }) => {
  const TokenComponent = tokenComponents[activeIndex];

  if (!TokenComponent) {
    return null; // Handle case where TokenComponent is undefined
  }

  return (
    <Canvas>
      <Suspense fallback={<Loader />}>
        <ambientLight intensity={0.6} />
        <PerspectiveCamera makeDefault position={[0, 0, 5]} /> {/* Adjust the position to fit your model */}
        <Lights />
        <OrbitControls enableZoom={true} enablePan={true} rotateSpeed={0.4} target={new THREE.Vector3(0, 0, 0)} />
        <TokenComponent scale={[0.24, 0.24, 0.24]} />
      </Suspense>
    </Canvas>
  );
};

export default ModelView2;
