export const CoreFeatures = [
  {
    title: "Global Payment Processing",
    description: 
      "Process payments in multiple currencies across borders, supporting both fiat and digital currencies for seamless global transactions.",
    image: "/assets/images/zero-pay/global-payment-processing.webp",
  },
  {
    title: "Multi-Currency Support",
    description: 
      "Accept and process payments in various currencies with real-time currency conversion, supporting global commerce and reducing currency risks.",
    image: "/assets/images/zero-pay/multi-currency-support.webp",
  },
  {
    title: "Smart Payment Routing",
    description: 
      "Automatically selects the most cost-effective payment channel, ensuring lower fees and faster transaction times.",
    image: "/assets/images/zero-pay/smart-payment-routing.webp",
  },
  {
    title: "Real-Time Payments",
    description: 
      "Process payments in real-time, enabling businesses to receive funds instantly, enhancing liquidity and cash flow.",
    image: "/assets/images/zero-pay/real-time-payments.webp",
  },
  {
    title: "Payment Gateway Integration",
    description: 
      "Seamlessly integrates with Stripe, PayPal, and other popular payment gateways to enhance payment options for customers.",
    image: "/assets/images/zero-pay/payment-gateway-integration.webp",
  },
  {
    title: "Customizable Checkout",
    description: 
      "Merchants can customize their payment flows and checkout experience to match their brand. Customizations include logos, colours, and layouts to improve UX and increase conversions.",
    image: "/assets/images/zero-pay/customizable-checkout.webp",
  }
];


export const PaymentCapabilities = [
  {
    title: "One-Click Payments",
    description: 
      "Allow returning customers to complete purchases instantly with pre-saved payment details, streamlining the checkout process for faster conversions.",
    image: "/assets/images/zero-pay/one-click-payments.webp",
  },
  {
    title: "Multi-Payment Options",
    description: 
      "Offer customers a wide range of payment methods including credit/debit cards, e-wallets, and crypto payments, giving them the flexibility they need.",
    image: "/assets/images/zero-pay/multi-payment-options.webp",
  },
  {
    title: "Split Payments",
    description: 
      "Enable customers to split payments across multiple methods, such as paying with part credit card and part wallet balance, offering flexibility and convenience.",
    image: "/assets/images/zero-pay/split-payments.webp",
  },
  {
    title: "Localized Payment Methods",
    description: 
      "Support region-specific payment methods to cater to local preferences and increase customer satisfaction in global markets.",
    image: "/assets/images/zero-pay/localized-payment-methods.webp",
  },
  {
    title: "Pre-Authorization & Hold",
    description: 
      "Place payment holds on customer funds to guarantee payment security before shipping goods or offering services, reducing risk for merchants.",
    image: "/assets/images/zero-pay/payment-hold-preauthorization.webp",
  },
  {
    title: "Express Checkout",
    description: 
      "Fast-track the checkout process with a single-tap payment option, allowing repeat customers to skip the cart and pay directly from product pages.",
    image: "/assets/images/zero-pay/express-checkout.webp",
  }
];


export const TechnicalArchitecture = [
  {
    title: "Microservices Architecture",
    description: 
      "Leverages a microservices approach to isolate payment, currency conversion, and fraud detection services for fast updates and enhanced scalability.",
    image: "/assets/images/zero-pay/microservices-architecture.webp",
  },
  {
    title: "Cloud-Native Design",
    description: 
      "Built to run on AWS, Azure, or Google Cloud, ensuring global availability, fast deployments, and high scalability.",
    image: "/assets/images/zero-pay/cloud-native-design.webp",
  },
  {
    title: "Event-Driven Design",
    description: 
      "Uses an event-driven architecture with RabbitMQ message queues for real-time updates on payment status, chargebacks, and settlements.",
    image: "/assets/images/zero-pay/event-driven-design.webp",
  },
  {
    title: "Data Encryption & Tokenization",
    description: 
      "Implements data encryption and tokenization to protect sensitive payment information, ensuring regulatory compliance and data security.",
    image: "/assets/images/zero-pay/data-encryption.webp",
  },
  {
    title: "Role-Based Access Control (RBAC)",
    description: 
      "Role-based access ensures secure control over access to critical system components and payment data.",
    image: "/assets/images/zero-pay/rbac.webp",
  },
  {
    title: "Serverless Functions",
    description: 
      "Leverages serverless functions for low-latency, event-triggered payments and real-time settlement notifications.",
    image: "/assets/images/zero-pay/serverless-functions.webp",
  }
];


export const UseCases = [
  {
    title: "E-Commerce Payments",
    description: 
      "Facilitate payments for online stores with multi-currency support and customizable checkout options for higher conversions.",
    image: "/assets/images/zero-pay/ecommerce-payments.webp",
  },
  {
    title: "Subscription Services",
    description: 
      "Enable subscription billing for SaaS platforms, streaming services, and membership-based companies with support for recurring payments.",
    image: "/assets/images/zero-pay/subscription-services.webp",
  },
  {
    title: "Cross-Border Payments",
    description: 
      "Support global payments with real-time currency conversion and instant payouts for gig workers, freelancers, and international businesses.",
    image: "/assets/images/zero-pay/cross-border-payments.webp",
  },
  {
    title: "Retail & POS Payments",
    description: 
      "Enable in-store payments with support for contactless payments, QR codes, and POS systems for retail merchants.",
    image: "/assets/images/zero-pay/retail-pos-payments.webp",
  },
  {
    title: "Gig Economy Payments",
    description: 
      "Support seamless payments for freelancers, gig workers, and digital nomads, offering instant payouts and reduced fees.",
    image: "/assets/images/zero-pay/gig-economy-payments.webp",
  },
  {
    title: "Marketplace Payments",
    description: 
      "Facilitate multi-party payments and disbursements in online marketplaces and vendor platforms.",
    image: "/assets/images/zero-pay/marketplace-payments.webp",
  }
];


export const SecurityAndCompliance = [
  {
    title: "PCI-DSS Certification",
    description: 
      "Ensures compliance with PCI-DSS standards to protect customer payment data and maintain the highest security standards.",
    image: "/assets/images/zero-pay/pci-dss-certification.webp",
  },
  {
    title: "End-to-End Encryption",
    description: 
      "Applies end-to-end encryption to protect payment data during transmission, ensuring secure data transfers.",
    image: "/assets/images/zero-pay/end-to-end-encryption.webp",
  },
  {
    title: "Fraud Detection & Prevention",
    description: 
      "Uses AI-driven algorithms to detect and block fraudulent transactions in real time, protecting businesses from financial loss.",
    image: "/assets/images/zero-pay/fraud-detection.webp",
  },
  {
    title: "KYC & AML Compliance",
    description: 
      "Adheres to Know Your Customer (KYC) and Anti-Money Laundering (AML) regulations to ensure business compliance.",
    image: "/assets/images/zero-pay/kyc-aml-compliance.webp",
  },
  {
    title: "Audit Logs & Monitoring",
    description: 
      "Provides detailed audit logs of all payment transactions, ensuring businesses have full visibility into payment activity.",
    image: "/assets/images/zero-pay/audit-logs.webp",
  },
  {
    title: "Tokenized Payments",
    description: 
      "Tokenizes payment card data, ensuring that customer payment details are never stored directly.",
    image: "/assets/images/zero-pay/tokenized-payments.webp",
  }
];


export const BusinessBenefits = [
  {
    title: "Faster Transactions",
    description: 
      "Process payments in real time, reducing delays and giving businesses faster access to working capital.",
    image: "/assets/images/zero-pay/faster-transactions.webp",
  },
  {
    title: "Global Reach",
    description: 
      "Accept payments from customers worldwide in multiple currencies, enhancing customer satisfaction and conversions.",
    image: "/assets/images/zero-pay/global-reach.webp",
  },
  {
    title: "Reduced Transaction Costs",
    description: 
      "Lower operational costs and avoid high fees by routing payments via cost-effective channels using smart routing technology.",
    image: "/assets/images/zero-pay/reduced-costs.webp",
  },
  {
    title: "Increased Revenue Opportunities",
    description: 
      "Provide a broader range of payment options to customers, increasing the potential for higher conversions and repeat business.",
    image: "/assets/images/zero-pay/increased-revenue.webp",
  },
  {
    title: "Enhanced Cash Flow",
    description: 
      "Instant settlements and real-time payment processing ensure faster access to funds, improving business liquidity and cash flow.",
    image: "/assets/images/zero-pay/enhanced-cash-flow.webp",
  },
  {
    title: "Data-Driven Decision Making",
    description: 
      "Advanced analytics provide valuable insights into payment trends, customer preferences, and operational efficiency, enabling data-driven decisions.",
    image: "/assets/images/zero-pay/data-driven-insights.webp",
  }
];


export const FutureEnhancements = [
  {
    title: "AI-Powered Payment Routing",
    description: 
      "Use AI to predict and select optimal payment routes, further reducing fees and enhancing speed.",
    image: "/assets/images/zero-pay/ai-payment-routing.webp",
  },
  {
    title: "Instant Settlements",
    description: 
      "Enable instant payments, allowing businesses to receive payments immediately, eliminating the need for daily settlements.",
    image: "/assets/images/zero-pay/instant-settlements.webp",
  },
  {
    title: "DeFi Integration",
    description: 
      "Integrate with decentralized finance (DeFi) protocols to allow blockchain-based payments and settlements using smart contracts.",
    image: "/assets/images/zero-pay/defi-integration.webp",
  },
  {
    title: "Multi-Currency Wallets",
    description: 
      "Merchants will be able to hold, send, and receive payments in multiple currencies within a unified wallet system.",
    image: "/assets/images/zero-pay/multi-currency-wallets.webp",
  },
  {
    title: "Enhanced Fraud Detection",
    description: 
      "Next-generation fraud detection with AI-driven predictive models that block suspicious activities before they happen.",
    image: "/assets/images/zero-pay/enhanced-fraud-detection.webp",
  },
  {
    title: "Biometric Authentication",
    description: 
      "Support biometric login (fingerprint, FaceID) for customer payments, providing a faster and more secure user experience.",
    image: "/assets/images/zero-pay/biometric-authentication.webp",
  }
];
