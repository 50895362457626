import React from 'react';
import {
  blackImg,
  blueImg,
  highlightFirstVideo,
  highlightFourthVideo,
  highlightSecondVideo,
  highlightThirdVideo,
  whiteImg,
  yellowImg,
} from "../utils";

// Navigation lists
export const navLists = [
  "Home",            // Main landing page
  "Zero-Coin",       // Your unique feature or currency
  "Crypto-Store",    // Access to the store for crypto-related products
  "Banking",         // Banking-related features
  "Wallet",          // Wallet management tools and features
  "Investments",     // Crypto investments and savings plans
  "ICO",             // Initial Coin Offering or token sale details
  "Products",        // Showcase your platform's products and offerings
  "Services",        // Broad services offered by the platform
  "Support",         // User support and FAQ access
  "About Us",        // Information about the organisation
  "Roadmap",         // Future milestones and plans
  "Tokenomics",      // Details on token distribution and utility  
];

// Items inside the "More" dropdown
export const moreNavLists = [
  "Governance",      // Decentralised governance features
  "Developers",      // Developer resources like APIs and documentation
  "Market Data",     // Real-time token and market data
  "Portfolio",       // Portfolio tracking for users
  "Dashboard",       // Personalised user dashboard
  "NFT Marketplace", // NFT marketplace access
  "Partners",        // Collaboration and partner information
  "Blog",            // Industry insights, articles, and updates
  "Compliance",      // Regulatory compliance and KYC/AML information
  "Contact Us",      // Ways to contact the organisation
  "Careers",          // Job opportunities
  "Privacy Policy",
  "Terms of Use",
  "Sales Policy",
  "Legal",
  "Site Map",
];

// Highlight slides data
export const hightlightsSlides = [
  {
    id: 1,
    textLists: [
      "Introducing ZERO-BANK.",
      "Revolutionary Digital Banking.",
      "Unmatched Security and Innovation.",
    ],
    video: highlightFirstVideo,
    videoDuration: 4,
    
  },
  {
    id: 2,
    textLists: [
      "Discover Zero Blockchain.",
      "Leading-Edge Blockchain Solutions.",
      "So light. Ultimate Efficiency and Trust."
    ],
    video: highlightSecondVideo,
    videoDuration: 5,
    videoClassName: "video-wide" // Add this line
  },
  {
    id: 3,
    textLists: [
      "Experience ZERO-BANK.",
      "Next-Gen Banking.",
      "Superior User Experience.",
    ],
    video: highlightThirdVideo,
    videoDuration: 2,
    
  },
  {
    id: 4,
    textLists: [
      "Unveil Zero Blockchain.",
      "Blockchain Redefined.",
      "Maximum Performance and Reliability."
    ],
    video: highlightFourthVideo,
    videoDuration: 3.63,
    
  },
];

// Models data
export const models = [
  {
    id: 1,
    title: "Zero-Bank ERC-20 Tokens",
    color: ["#8F8A81", "#ffe7b9", "#6f6c64"],
    img: yellowImg,
  },  
];

// Sizes data
export const sizes = [
  { label: 'ZERO-COIN', value: "size0" },
  { label: 'GECKO', value: "size1" },
  { label: 'TIGER', value: "size2" },
  { label: 'RHINO', value: "size3" },
  { label: 'STEGO', value: "size4" },
  { label: 'CHEETO', value: "size5" },
  { label: 'BISON', value: "size6" },
  { label: 'DINO', value: "size7" },
  { label: 'MOA', value: "size8" },
  { label: 'WOOLY', value: "size9" },
  { label: 'SABRE', value: "size10" },
  { label: 'QUAGGA', value: "size11" },
  { label: 'PANDA', value: "size12" },
  { label: 'PTERAN', value: "size13" },
  { label: 'THYLAC', value: "size14" },
  { label: 'AUROCH', value: "size15" },
  { label: 'IVORY', value: "size16" },
  { label: 'PANG', value: "size17" },
  { label: 'CONDOR', value: "size18" },
  { label: 'BAIJI', value: "size19" },
  { label: 'GLYPTO', value: "size20" },
  { label: 'MEGAL', value: "size21" },
  { label: 'DIRE', value: "size22" },
  { label: 'QUETZAL', value: "size23" },
  { label: 'LYNX', value: "size24" },
  { label: 'SAURO', value: "size25" },    
  { label: 'OKAPI', value: "size26" },
  { label: 'KAKAPO', value: "size27" },
  { label: 'VAQUITA', value: "size28" },
  { label: 'GORILLA', value: "size29" },
  { label: 'REDWOLF', value: "size30" },
  { label: 'TORTOISE', value: "size31" },
  { label: 'OWL', value: "size32" },
  { label: 'MANATEE', value: "size33" },
  { label: 'GIBBON', value: "size34" },
  { label: 'RIVER', value: "size35" },
  { label: 'AMUR', value: "size36" },
  { label: 'ADDAX', value: "size37" },
  { label: 'TUATARA', value: "size38" },
  { label: 'IBIS', value: "size39" },
  { label: 'ELEPHANT', value: "size40" },
  { label: 'LEMUR', value: "size41" },
  { label: 'DODO', value: "size42" },
  { label: 'HARPY', value: "size43" },
  { label: 'WOLF', value: "size44" },
  { label: 'SUNBEAR', value: "size45" },
  { label: 'ORCA', value: "size46" },
  { label: 'PIKA', value: "size47" },
  { label: 'SHARK', value: "size48" },
  { label: 'MYNAH', value: "size49" },
  { label: 'CAMEL', value: "size50" },    
];

// Footer links data
export const footerLinks = [
  "Privacy Policy",
  "Terms of Use",
  "Sales Policy",
  "Legal",
  "Site Map",
];

// Video component definition
const VideoComponent = ({ video, videoDuration, videoStyle }) => {
  return (
    <video style={videoStyle} duration={videoDuration} controls>
      <source src={video} type="video/mp4" />
      Your browser does not support the video tag.
    </video>
  );
};

// Highlight slide component definition
const HighlightSlide = ({ slide }) => {
  return (
    <div>
      {slide.textLists.map((text, index) => (
        <p key={index}>{text}</p>
      ))}
      <VideoComponent
        video={slide.video}
        videoDuration={slide.videoDuration}
        videoStyle={slide.videoStyle}
      />
    </div>
  );
};

// Highlights component definition
const Highlights = ({ slides }) => {
  return (
    <div>
      {slides.map((slide) => (
        <HighlightSlide key={slide.id} slide={slide} />
      ))}
    </div>
  );
};

const navItems = navLists.map((nav) => {
  const path = nav === "Home" ? "/" : `/${nav.toLowerCase().replace(/\s+/g, '-')}`;
  return {
    id: `${nav.toLowerCase().replace(/\s+/g, '-')}-button`,
    title: nav,
    path,
  };
});

// Export the Highlights component
export default Highlights;
