import React, { useEffect, useState, useRef } from "react";
import AnimatedTitle from "../components/AnimatedTitle";
import { TiArrowDown, TiArrowUp  } from "react-icons/ti";
import BackToProductsButton from "../components/BackToProductsButton";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Footer from "../components/Footer";

gsap.registerPlugin(ScrollTrigger);

const ZeroComply = () => {
  const [scrollDownClicked, setScrollDownClicked] = useState(false);
  const [isBottom, setIsBottom] = useState(false);

  const sectionRefs = useRef([]);
  const scrollDownRef = useRef(null);
  const scrollTopRef = useRef(null);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    sectionRefs.current.forEach((section, index) => {
      if (section) {
        gsap.fromTo(
          section,
          { opacity: 0, y: 50 },
          {
            opacity: 1,
            y: 0,
            duration: 1,
            delay: index * 0.2,
            scrollTrigger: {
              trigger: section,
              start: "top 80%",
            },
          }
        );
      }
    });

    if (scrollDownRef.current) {
      gsap.fromTo(
        scrollDownRef.current,
        { opacity: 0, y: -20 },
        { opacity: 1, y: 0, duration: 1 }
      );
    }

    if (scrollTopRef.current) {
      gsap.fromTo(
        scrollTopRef.current,
        { opacity: 0, y: 20 },
        {
          opacity: 1,
          y: 0,
          duration: 0.5,
          scrollTrigger: {
            trigger: scrollTopRef.current,
            start: "top bottom",
          },
        }
      );
    }
  }, []);

  const checkIfBottom = () => {
    if (
      window.innerHeight + window.scrollY >=
      document.body.offsetHeight - 200
    ) {
      setIsBottom(true);
    } else {
      setIsBottom(false);
    }
  };

  const scrollToContent = () => {
    const content = document.getElementById("content");
    if (content) {
      const contentOffsetTop = content.offsetTop;
      window.scrollTo({
        top: contentOffsetTop - 100,
        behavior: "smooth",
      });
    }
    setScrollDownClicked(true);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setScrollDownClicked(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", checkIfBottom);
    return () => {
      window.removeEventListener("scroll", checkIfBottom);
    };
  }, []);

  const complyDetails = [
    {
      title: "Automated Compliance",
      description:
        "Zero-Comply streamlines compliance processes with automation, reducing the time and effort needed to meet regulatory standards.",
      image: "/assets/images/zero-comply/automated-compliance.webp",
    },
    {
      title: "Risk Assessment",
      description:
        "Conduct advanced risk assessments to identify and mitigate potential compliance risks efficiently.",
      image: "/assets/images/zero-comply/risk-assessment.webp",
    },
    {
      title: "Customisable Reports",
      description:
        "Generate tailored compliance reports to satisfy diverse regulatory requirements with ease.",
      image: "/assets/images/zero-comply/custom-reports.webp",
    },
  ];

  return (
    <div className="w-screen min-h-screen bg-black text-blue-50">
      {/* Hero Section */}
      <div className="relative w-screen h-screen overflow-hidden">
        <video
          src="/videos/zero-comply-hero.mp4"
          autoPlay
          loop
          muted
          className="absolute left-0 top-0 size-full object-cover"
        />
        <div className="absolute inset-0 flex flex-col items-center justify-center bg-black/60">
          <AnimatedTitle
            title="Zero-Comply"
            containerClass="text-center text-blue-50 font-zentry"
          />
        </div>
      </div>

      {/* Scroll Down Button */}
      {!scrollDownClicked && (
        <div
          ref={scrollDownRef}
          className="fixed bottom-10 left-1/2 transform -translate-x-1/2 flex items-center gap-2 p-2 bg-transparent border border-gray-400 rounded-full shadow-lg cursor-pointer hover:bg-gray-800 transition-all duration-300"
          onClick={scrollToContent}
        >
          <TiArrowDown size={24} className="text-white/40 hover:text-white" />
          <span className="text-white/40 hover:text-white">SCROLL DOWN</span>
        </div>
      )}

      {/* Content Section */}
      <div id="content" className="px-10 py-20 mx-auto max-w-7xl">
        {/* Introduction */}
        <div className="mb-10 text-center">
          <h2 className="text-3xl font-bold">Regulatory Compliance Simplified</h2>
          <p className="mt-3">
            Zero-Comply empowers financial institutions with efficient and automated compliance solutions.
          </p>
        </div>

        {/* Compliance Details */}
        <div className="grid gap-10 md:grid-cols-2 lg:grid-cols-3">
          {complyDetails.map((item, index) => (
            <div
              key={index}
              className="relative p-6 bg-black/70 rounded-lg shadow-lg border border-gray-400 overflow-hidden group hover:scale-105 transition-transform"
              ref={(el) => (sectionRefs.current[index] = el)}
            >
              <div
                className="absolute inset-0 z-0 bg-cover bg-center opacity-20 group-hover:opacity-30 transition-opacity duration-300"
                style={{ backgroundImage: `url(${item.image})` }}
              ></div>
              <div className="relative z-10">
                <h3 className="text-xl font-bold">{item.title}</h3>
                <p className="mt-4">{item.description}</p>
              </div>
            </div>
          ))}
        </div>

        {/* Highlights Section */}
        <div className="mt-20 space-y-16">
          <div className="flex flex-col-reverse items-center gap-10 md:flex-row">
            <div className="w-full md:w-1/2">
              <img
                src="/assets/images/zero-comply/ai-driven.webp"
                alt="AI-Driven Compliance"
                className="rounded-lg shadow-lg"
              />
            </div>
            <div className="w-full md:w-1/2">
              <h3 className="text-2xl font-bold">AI-Driven Compliance</h3>
              <p className="mt-4">
                Leverage AI technology to monitor and adapt to evolving regulatory landscapes.
              </p>
            </div>
          </div>
          <div className="flex flex-col items-center gap-10 md:flex-row">
            <div className="w-full md:w-1/2">
              <h3 className="text-2xl font-bold">Real-Time Monitoring</h3>
              <p className="mt-4">
                Stay ahead of potential compliance issues with real-time monitoring and alerts.
              </p>
            </div>
            <div className="w-full md:w-1/2">
              <img
                src="/assets/images/zero-comply/real-time-monitoring.webp"
                alt="Real-Time Monitoring"
                className="rounded-lg shadow-lg"
              />
            </div>
          </div>
        </div>

        {/* Back to Products Button */}
        <div className="flex justify-center mt-10">
          <BackToProductsButton />
        </div>
      </div>

      {/* Back to Top Button */}
      {isBottom && (
        <div
          ref={scrollTopRef}
          className="fixed bottom-10 left-5 flex items-center gap-2 p-2 bg-transparent border border-gray-400 rounded-full shadow-lg cursor-pointer hover:bg-gray-800 transition-all duration-300"
          onClick={scrollToTop}
        >
          <TiArrowUp
            size={24}
            className="text-white/40 hover:text-white transition-transform duration-300"
          />
          <span className="text-white/40 hover:text-white">BACK TO TOP</span>
        </div>
      )}

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default ZeroComply;
