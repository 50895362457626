import React, { useEffect, useState, useRef } from "react";
import { TiArrowDown, TiArrowUp } from "react-icons/ti";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Footer from "./Footer";
import AnimatedTitle from "./AnimatedTitle";

gsap.registerPlugin(ScrollTrigger);

const Partners = () => {
  const [scrollDownClicked, setScrollDownClicked] = useState(false);
  const [isBottom, setIsBottom] = useState(false);
  const sectionRefs = useRef([]);
  const scrollDownRef = useRef(null);
  const scrollTopRef = useRef(null);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    // GSAP Animations for sections
    sectionRefs.current.forEach((section, index) => {
      if (section) {
        gsap.fromTo(
          section,
          { opacity: 0, y: 50 },
          {
            opacity: 1,
            y: 0,
            duration: 1,
            delay: index * 0.2,
            scrollTrigger: {
              trigger: section,
              start: "top 80%",
            },
          }
        );
      }
    });

    if (scrollDownRef.current) {
      gsap.fromTo(
        scrollDownRef.current,
        { opacity: 0, y: -20 },
        { opacity: 1, y: 0, duration: 1 }
      );
    }

    if (scrollTopRef.current) {
      gsap.fromTo(
        scrollTopRef.current,
        { opacity: 0, y: 20 },
        {
          opacity: 1,
          y: 0,
          duration: 0.5,
          scrollTrigger: {
            trigger: scrollTopRef.current,
            start: "top bottom",
          },
        }
      );
    }
  }, []);

  const checkIfBottom = () => {
    if (
      window.innerHeight + window.scrollY >=
      document.body.offsetHeight - 200
    ) {
      setIsBottom(true);
    } else {
      setIsBottom(false);
    }
  };

  const scrollToContent = () => {
    const content = document.getElementById("content");
    if (content) {
      const contentOffsetTop = content.offsetTop;
      window.scrollTo({
        top: contentOffsetTop - 100,
        behavior: "smooth",
      });
    }
    setScrollDownClicked(true);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setScrollDownClicked(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", checkIfBottom);
    return () => {
      window.removeEventListener("scroll", checkIfBottom);
    };
  }, []);

  const partners = [
    {
      name: "Ethereum Foundation",
      description:
        "A key partner supporting the infrastructure for our blockchain technology.",
      logo: "/images/partners/ethereum.png",
    },
    {
      name: "Polygon Network",
      description:
        "Collaborating on scalable blockchain solutions for seamless transactions.",
      logo: "/images/partners/polygon.png",
    },
    {
      name: "Chainlink",
      description:
        "Providing robust oracle solutions to integrate real-world data into our ecosystem.",
      logo: "/images/partners/chainlink.png",
    },
    {
      name: "ZeroARTNFT",
      description:
        "Our NFT marketplace partner, enabling the creation and trade of unique digital assets.",
      logo: "/images/partners/zeroartnft.png",
    },
  ];

  return (
    <div className="w-screen min-h-screen bg-black text-blue-50">
      {/* Hero Section */}
      <div className="relative w-screen h-[96vh] overflow-hidden">
        <div className="absolute inset-0 flex flex-col items-center justify-center bg-black/60">
          <AnimatedTitle
            title="Our Partners"
            containerClass="text-center text-blue-50 font-zentry"
          />
        </div>
      </div>

      {/* Scroll Down Button */}
      {!scrollDownClicked && (
        <div
          ref={scrollDownRef}
          className="fixed bottom-10 left-1/2 transform -translate-x-1/2 flex items-center gap-2 p-2 bg-transparent border border-gray-400 rounded-full shadow-lg cursor-pointer hover:bg-gray-800 transition-all duration-300"
          onClick={scrollToContent}
        >
          <TiArrowDown size={24} className="text-white/40 hover:text-white" />
          <span className="text-white/40 hover:text-white">SCROLL DOWN</span>
        </div>
      )}

      {/* Spacer to push content below initial viewport */}
      <div className="h-screen" id="spacer"></div>

      {/* Partners Section */}
      <div id="content" className="px-10 py-20 mx-auto max-w-7xl">
        <h2 className="text-3xl font-bold text-center mb-10">
          Partnering for Innovation
        </h2>
        <p className="text-center mb-16">
          We collaborate with industry leaders to drive innovation and
          revolutionise the blockchain space.
        </p>
        <div className="grid gap-10 md:grid-cols-2 lg:grid-cols-3">
          {partners.map((partner, index) => (
            <div
              key={index}
              className="dashboard-section p-6 bg-black/70 rounded-lg shadow-lg text-center"
              ref={(el) => (sectionRefs.current[index] = el)}
            >
              <img
                src={partner.logo}
                alt={partner.name}
                className="w-20 h-20 mx-auto mb-4"
              />
              <h3 className="text-xl font-bold">{partner.name}</h3>
              <p className="mt-4">{partner.description}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Back to Top Button */}
      {isBottom && (
        <div
          ref={scrollTopRef}
          className="fixed bottom-10 left-5 flex items-center gap-2 p-2 bg-transparent border border-gray-400 rounded-full shadow-lg cursor-pointer hover:bg-gray-800 transition-all duration-300"
          onClick={scrollToTop}
        >
          <TiArrowUp
            size={24}
            className="text-white/40 hover:text-white transition-transform duration-300"
          />
          <span className="text-white/40 hover:text-white">BACK TO TOP</span>
        </div>
      )}

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default Partners;
