import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { gsap } from 'gsap';

const HeroButton = ({ children, onClick, ariaLabel, additionalClasses }) => {
    const buttonRef = useRef(null);

    useEffect(() => {
        // Initial animation for the button on render
        gsap.fromTo(
            buttonRef.current,
            { opacity: 0, scale: 0.9 }, // Initial state
            {
                opacity: 1,
                scale: 1,
                duration: 1,
                ease: 'elastic.out(1, 0.3)', // Smooth elastic bounce
            }
        );
    }, []);

    const handleMouseEnter = () => {
        // Animation on hover
        gsap.to(buttonRef.current, {
            scale: 1.1,
            boxShadow: '0px 0px 10px silver',
            duration: 0.3,
            ease: 'power1.out',
        });
    };

    const handleMouseLeave = () => {
        // Reset animation on hover exit
        gsap.to(buttonRef.current, {
            scale: 1,
            boxShadow: '0px 0px 2px silver',
            duration: 0.3,
            ease: 'power1.inOut',
        });
    };

    return (
        <button
            ref={buttonRef}
            onClick={onClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            aria-label={ariaLabel}
            className={`relative text-gray-50 font-semibold rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 bg-transparent border-2 border-gray-300 hover:bg-gray-700 hover:text-blue-300 transition-all duration-300 ${additionalClasses}`}
            type="button"
        >
            <span
                className="relative z-10 px-4 py-2"
                style={{
                    textShadow: '0px 0px 1px silver',
                }}
            >
                {children}
            </span>
            <div
                className="absolute inset-0 border-2 border-silver rounded-full z-0"
                style={{
                    boxShadow: '0px 0px 2px silver',
                }}
            ></div>
        </button>
    );
};

HeroButton.propTypes = {
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func.isRequired,
    ariaLabel: PropTypes.string.isRequired,
    additionalClasses: PropTypes.string,
};

HeroButton.defaultProps = {
    additionalClasses: '',
};

export default HeroButton;
