export const CoreFeatureDetails = {
  "Real-Time Market Data": {
    title: "Real-Time Market Data",
    description: `
      Access live updates on stocks, forex, commodities, and indices from global markets to make timely and data-driven investment decisions.
      
      Key Features:
      **Live Data Feeds:** Receive real-time price updates from stock, forex, commodity, and index markets.
      **Custom Alerts:** Set alerts for significant market movements.
      **Global Market Access:** Access data from multiple global exchanges.
    `,
    image: "/assets/images/zero-pulse/real-time-market-data.webp"
  },

  "Portfolio Management": {
    title: "Portfolio Management",
    description: `
      Customise and track investment portfolios with comprehensive performance metrics, risk analysis, and diversification insights.
      
      Key Features:
      **Customizable Portfolios:** Create and manage multiple investment portfolios.
      **Performance Metrics:** Track ROI, P&L, and diversification data.
      **Risk Insights:** Access risk scores and stress-test portfolios.
    `,
    image: "/assets/images/zero-pulse/portfolio-management.webp"
  },

  "Advanced Analytics": {
    title: "Advanced Analytics",
    description: `
      Leverage trend analysis, technical indicators, and predictive models to generate actionable insights for smarter investments.
      
      Key Features:
      **Trend Analysis:** View past and current price trends to predict future performance.
      **Predictive Models:** AI-powered models for potential future movements.
      **Technical Indicators:** Access MACD, RSI, and moving averages for better decision-making.
    `,
    image: "/assets/images/zero-pulse/advanced-analytics.webp"
  },

  "Financial Reporting": {
    title: "Financial Reporting",
    description: `
      Generate detailed reports on performance, P&L, and cash flow, enabling users to track investment success and profitability.
      
      Key Features:
      **Performance Reports:** Generate P&L, cash flow, and financial overview reports.
      **Custom Reports:** Export custom reports in PDF or Excel format.
      **Historical Tracking:** Analyze past reports for trend analysis.
    `,
    image: "/assets/images/zero-pulse/financial-reporting.webp"
  },

  "Custom Alerts": {
    title: "Custom Alerts",
    description: `
      Receive notifications for price changes, breaking news, and market trends, keeping users informed of critical market movements.
      
      Key Features:
      **Price Alerts:** Get notified for price changes on tracked assets.
      **News Alerts:** Get breaking news updates for relevant market events.
      **Trend Alerts:** Receive alerts on market momentum shifts.
    `,
    image: "/assets/images/zero-pulse/custom-alerts.webp"
  },

  "Risk Management Tools": {
    title: "Risk Management Tools",
    description: `
      Customise stop-loss, trailing stops, and position sizing to manage exposure and protect portfolio performance.
      
      Key Features:
      **Custom Stop-Loss:** Set limits to automatically sell assets.
      **Trailing Stops:** Adjust stop limits as asset prices increase.
      **Exposure Control:** Manage exposure levels for better risk control.
    `,
    image: "/assets/images/zero-pulse/risk-management-tools.webp"
  },

  "Default": {
    title: "Default Title",
    description: "Content not available.",
    image: "/assets/images/default-image.webp"
  }
};

export const UserExperienceEnhancementsFeaturesDetails = {
  "Customised User Journey": {
    title: "Customised User Journey",
    description: `
      User journey maps for beginner, intermediate, and expert investors to offer a tailored experience for all skill levels.
      
      Key Features:
      **User Segmentation:** Custom journeys for beginners, intermediates, and experts.
      **Guided Walkthroughs:** Step-by-step guidance for new users.
      **Personalised Content:** Tailored educational content for user segments.
    `,
    image: "/assets/images/zero-pulse/user-journey-map.webp"
  },

  "Intuitive Interface": {
    title: "Intuitive Interface",
    description: `
      A sleek, intuitive design with clear action buttons, visual aids, and interactive dashboards for an easy-to-navigate experience.
      
      Key Features:
      **Interactive Dashboards:** Customizable dashboard layouts.
      **UX-Centric Design:** Clear calls-to-action and intuitive navigation.
      **Responsive Design:** Optimised for desktop, mobile, and tablets.
    `,
    image: "/assets/images/zero-pulse/intuitive-interface.webp"
  },

  "Multi-Platform Compatibility": {
    title: "Multi-Platform Compatibility",
    description: `
      Access the platform via desktop, mobile, and tablet devices with a consistent, smooth, and synchronised user experience.
      
      Key Features:
      **Cross-Device Access:** Use Zero-Pulse on desktop, mobile, and tablet.
      **Sync Across Devices:** Automatically sync preferences and activity.
      **Smooth Transitions:** Seamless user experience across platforms.
    `,
    image: "/assets/images/zero-pulse/multi-platform-compatibility.webp"
  },

  "Data Visualisation Tools": {
    title: "Data Visualisation Tools",
    description: `
      Use visual aids such as charts, graphs, and trendlines to display market insights and investment data with clarity and precision.
      
      Key Features:
      **Interactive Charts:** Real-time charts and graphs for market data.
      **Custom Graphs:** Create custom data visualisations for reports.
      **Trendlines:** Access technical trendline analysis.
    `,
    image: "/assets/images/zero-pulse/data-visualisation-tools.webp"
  },

  "User-Centric Onboarding": {
    title: "User-Centric Onboarding",
    description: `
      Simplified onboarding with guided steps and interactive prompts to ensure a smooth setup process for new users.
      
      Key Features:
      **Onboarding Wizard:** Interactive, step-by-step onboarding process.
      **Interactive Prompts:** Guided tooltips to familiarise users.
      **Progress Tracking:** Track onboarding progress to encourage completion.
    `,
    image: "/assets/images/zero-pulse/user-onboarding.webp"
  },

  "Theme Customization": {
    title: "Theme Customization",
    description: `
      Users can select from multiple themes and adjust layouts for a personalized platform experience.
      
      Key Features:
      **Custom Themes:** Choose from light, dark, and custom themes.
      **Layout Adjustments:** Customise widget positions and layout.
      **User Preferences:** Preferences are saved for seamless cross-device experience.
    `,
    image: "/assets/images/zero-pulse/theme-customization.webp"
  },

  "Default": {
    title: "Default Title",
    description: "Content not available.",
    image: "/assets/images/default-image.webp"
  }
};

export const RiskManagementToolsFeaturesDetails = {
  "Stop-Loss & Trailing Stops": {
    title: "Stop-Loss & Trailing Stops",
    description: `
      Minimise potential losses with custom stop-loss and trailing stop orders, giving users control over risk exposure.
      
      Key Features:
      **Stop-Loss Orders:** Automatically exit positions at a predefined price.
      **Trailing Stops:** Capture gains as the market rises.
      **Automated Risk Control:** Hands-free risk management.
    `,
    image: "/assets/images/zero-pulse/stop-loss-tools.webp"
  },

  "Position Sizing": {
    title: "Position Sizing",
    description: `
      Use dynamic position sizing to reduce risk exposure and diversify investments for improved portfolio performance.
      
      Key Features:
      **Dynamic Position Sizing:** Automatically adjust investment size.
      **Diversification Insights:** Optimise allocation across assets.
      **Risk Adjusted Sizing:** Risk-based trade sizing for better results.
    `,
    image: "/assets/images/zero-pulse/position-sizing.webp"
  },

  "Risk Analysis Reports": {
    title: "Risk Analysis Reports",
    description: `
      Generate risk analysis reports with in-depth coverage of exposure levels, diversification, and portfolio vulnerabilities.
      
      Key Features:
      **Exposure Reports:** View total exposure per asset class.
      **Diversification Check:** Identify areas of overexposure.
      **Portfolio Vulnerability:** Highlight areas of potential loss.
    `,
    image: "/assets/images/zero-pulse/risk-analysis-reports.webp"
  },

  "Volatility Tracking": {
    title: "Volatility Tracking",
    description: `
      Track market volatility to identify potential price swings and manage exposure in turbulent market conditions.
      
      Key Features:
      **Real-Time Volatility Alerts:** Immediate notifications of market shifts.
      **Market Swing Analysis:** View past and present volatility trends.
      **Exposure Adjustment:** Proactive exposure management during turbulence.
    `,
    image: "/assets/images/zero-pulse/volatility-tracking.webp"
  },

  "Exposure Management": {
    title: "Exposure Management",
    description: `
      Monitor exposure across multiple assets to ensure a balanced and diversified portfolio.
      
      Key Features:
      **Asset Exposure View:** Real-time tracking of exposure by asset class.
      **Balance & Diversification:** Ensure optimal asset distribution.
      **Risk Mitigation:** Minimise concentration risk through diversification.
    `,
    image: "/assets/images/zero-pulse/exposure-management.webp"
  },

  "Customizable Risk Alerts": {
    title: "Customizable Risk Alerts",
    description: `
      Set custom alerts for risk events, margin calls, and extreme market shifts, enabling proactive management.
      
      Key Features:
      **Custom Alerts:** Tailor alerts for market shifts, margin calls, and liquidity events.
      **Real-Time Notifications:** Receive alerts via mobile, email, or desktop.
      **Proactive Risk Management:** Respond to risk events before they escalate.
    `,
    image: "/assets/images/zero-pulse/risk-alerts.webp"
  },

  "Default": {
    title: "Default Title",
    description: "Content not available.",
    image: "/assets/images/default-image.webp"
  }
};



export const TechnicalCapabilitiesFeaturesDetails = {
  "Microservices Architecture": {
    title: "Microservices Architecture",
    description: `
      Decoupled services manage analytics, data feeds, and notifications independently, allowing for seamless scalability and resilience.
      
      Key Features:
      **Decoupled Services:** Isolate individual components for better system performance.
      **Seamless Scalability:** Easily scale services up or down based on system demand.
      **Fault Resilience:** Minimise disruptions with independent services.
    `,
    image: "/assets/images/zero-pulse/microservices-architecture.webp"
  },

  "Cloud Infrastructure": {
    title: "Cloud Infrastructure",
    description: `
      Hosted on cloud platforms like AWS and Azure, enabling high availability, fault tolerance, and global redundancy.
      
      Key Features:
      **High Availability:** Ensure uninterrupted access to the platform.
      **Global Redundancy:** Distribute servers globally for failover protection.
      **Disaster Recovery:** Enable fast system restoration in case of failures.
    `,
    image: "/assets/images/zero-pulse/cloud-infrastructure.webp"
  },

  "Data Pipeline": {
    title: "Data Pipeline",
    description: `
      A real-time data pipeline ensures live feeds for market prices, analytics, and updates, supporting split-second decision-making.
      
      Key Features:
      **Real-Time Feeds:** Get instant access to live data for accurate decision-making.
      **Data Processing:** Ingest, process, and push data in real time.
      **Low Latency:** Streamline data flow with near-zero latency.
    `,
    image: "/assets/images/zero-pulse/data-pipeline.webp"
  },

  "Message Queues": {
    title: "Message Queues",
    description: `
      Employs RabbitMQ for real-time notifications and alerts on price changes, news updates, and market movements.
      
      Key Features:
      **Real-Time Notifications:** Receive real-time updates on asset changes.
      **Asynchronous Communication:** Decouple services to improve system responsiveness.
      **High Throughput:** Efficiently process a high volume of messages.
    `,
    image: "/assets/images/zero-pulse/message-queues.webp"
  },

  "Containerization": {
    title: "Containerization",
    description: `
      Deploys containerized services using Docker to ensure fast updates, version control, and simplified deployment.
      
      Key Features:
      **Docker Containers:** Deploy applications consistently across environments.
      **Fast Updates:** Deploy and roll back versions quickly.
      **Version Control:** Maintain versioned releases for safer deployments.
    `,
    image: "/assets/images/zero-pulse/containerization.webp"
  },

  "API Integration": {
    title: "API Integration",
    description: `
      Connects to external data providers (Bloomberg, Reuters) for continuous live market data and news feeds.
      
      Key Features:
      **External Data Feeds:** Access Bloomberg, Reuters, and other financial APIs.
      **Live Market Data:** Stream live financial data into your dashboard.
      **News Integration:** Receive real-time news updates to support investment decisions.
    `,
    image: "/assets/images/zero-pulse/api-integration.webp"
  },

  "Default": {
    title: "Default Title",
    description: "Content not available.",
    image: "/assets/images/default-image.webp"
  }
};

export const SecurityAndComplianceFeaturesDetails = {
  "Bank-Grade Encryption": {
    title: "Bank-Grade Encryption",
    description: `
      Applies AES-256 encryption to protect user data, passwords, and sensitive financial information both in transit and at rest.
      
      Key Features:
      **AES-256 Encryption:** Ensure top-tier encryption for sensitive data.
      **Data Protection:** Protect sensitive information from cyber threats.
      **In-Transit & At-Rest Security:** Secure data at all stages of transmission.
    `,
    image: "/assets/images/zero-pulse/bank-grade-encryption.webp"
  },

  "Multi-Factor Authentication (MFA)": {
    title: "Multi-Factor Authentication (MFA)",
    description: `
      Provides an added layer of security using 2FA to prevent unauthorised access to user accounts and financial data.
      
      Key Features:
      **Two-Factor Authentication:** Require multiple verifications for login.
      **Account Security:** Prevent unauthorised access to user accounts.
      **Biometric Integration:** Support fingerprint, face ID, and mobile app MFA.
    `,
    image: "/assets/images/zero-pulse/mfa-security.webp"
  },

  "GDPR Compliance": {
    title: "GDPR Compliance",
    description: `
      Ensures data privacy and compliance with GDPR regulations to protect the privacy of European users.
      
      Key Features:
      **Data Privacy:** Safeguard user data as per GDPR guidelines.
      **User Consent:** Ensure consent is obtained before collecting data.
      **Compliance Reports:** Generate compliance reports for audits.
    `,
    image: "/assets/images/zero-pulse/gdpr-compliance.webp"
  },

  "Default": {
    title: "Default Title",
    description: "Content not available.",
    image: "/assets/images/default-image.webp"
  }
};


export const UseCasesFeaturesDetails = {
  "Portfolio Tracking": {
    title: "Portfolio Tracking",
    description: `
      Enable retail investors to track their investments in stocks, commodities, and crypto, all in one dashboard.
      
      Key Features:
      **Multi-Asset Tracking:** View performance across stocks, crypto, and commodities.
      **Customisable Views:** Customise how portfolios are displayed.
      **Comprehensive Metrics:** Access profit/loss, ROI, and risk metrics.
    `,
    image: "/assets/images/zero-pulse/portfolio-tracking.webp"
  },

  "Financial Advisory": {
    title: "Financial Advisory",
    description: `
      Support financial analysts in generating client reports and offering actionable insights for wealth management strategies.
      
      Key Features:
      **Client Reports:** Generate customised reports for client portfolios.
      **Wealth Management Insights:** Identify strategies for wealth growth.
      **Market Insights:** Provide analysts with data-driven insights.
    `,
    image: "/assets/images/zero-pulse/financial-advisory.webp"
  },

  "Algorithmic Trading": {
    title: "Algorithmic Trading",
    description: `
      Empower hedge funds to automate trading with data-driven decisions and predictive analytics for better trade execution.
      
      Key Features:
      **Algo-Based Trading:** Automate trades based on market data and strategy rules.
      **Predictive Models:** Use predictive analytics to execute smarter trades.
      **Backtesting:** Simulate trades before committing real capital.
    `,
    image: "/assets/images/zero-pulse/algorithmic-trading.webp"
  },

  "Retail Investors": {
    title: "Retail Investors",
    description: `
      Provide retail investors with the tools to research, track, and analyze personal portfolios with ease.
      
      Key Features:
      **Portfolio Insights:** Provide insights for better investment decisions.
      **Research Tools:** Access investment research on potential opportunities.
      **Educational Support:** Offer tutorials and guides for retail investors.
    `,
    image: "/assets/images/zero-pulse/retail-investors.webp"
  },

  "Institutional Trading Desks": {
    title: "Institutional Trading Desks",
    description: `
      Support institutional traders with advanced analytics, technical analysis, and execution management for large portfolios.
      
      Key Features:
      **Trade Management:** Handle large trades across multiple markets.
      **Advanced Analytics:** Access robust technical indicators and insights.
      **Execution Tracking:** Track order execution and settlement times.
    `,
    image: "/assets/images/zero-pulse/institutional-trading.webp"
  },

  "Market Research": {
    title: "Market Research",
    description: `
      Research market trends, conduct fundamental analysis, and study technical indicators with interactive dashboards.
      
      Key Features:
      **Trend Analysis:** Identify market shifts using trend analysis.
      **Fundamental Analysis:** Access data for fundamental analysis of assets.
      **Interactive Dashboards:** Use visual dashboards for better research.
    `,
    image: "/assets/images/zero-pulse/market-research.webp"
  },

  "Default": {
    title: "Default Title",
    description: "Content not available.",
    image: "/assets/images/default-image.webp"
  }
};


