import React, { useEffect, useRef } from "react";
import { TiLocationArrow } from "react-icons/ti";
import { gsap } from "gsap";

const LearnMoreButton = ({ onClick }) => {
  const buttonRef = useRef(null);

  useEffect(() => {
    if (buttonRef.current) {
      gsap.fromTo(
        buttonRef.current,
        { opacity: 0, y: 20 },
        { opacity: 1, y: 0, duration: 1, ease: "power3.out" }
      );
    }
  }, []);

  const handleMouseEnter = () => {
    gsap.to(buttonRef.current, {
      boxShadow: "0 0 15px rgba(0, 255, 255, 1)",
      scale: 1.05,
      duration: 0.3,
      ease: "power3.out",
    });
  };

  const handleMouseLeave = () => {
    gsap.to(buttonRef.current, {
      boxShadow: "0 0 0 rgba(0, 255, 255, 0)",
      scale: 1,
      duration: 0.3,
      ease: "power3.out",
    });
  };

  return (
    <button
      ref={buttonRef}
      className="px-4 py-2 border border-gray-200 bg-transparent hover:bg-teal-500 hover:text-gray-800 text-gray-100 z-30 flex items-center rounded-md transition-all duration-300 ease-in-out"
      style={{ transform: "scale(0.6)" }} // Reduce size to 60%
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={onClick} // Trigger the parent function
    >
      <span className="flex items-center">
        Learn More
        <TiLocationArrow className="ml-2 text-xl" />
      </span>
    </button>
  );
};

export default LearnMoreButton;
