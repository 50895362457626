import React, { useEffect, useState, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Footer from "./Footer";
import AnimatedTitle from "./AnimatedTitle";
import { TiArrowDown, TiArrowUp } from "react-icons/ti";

gsap.registerPlugin(ScrollTrigger);

const Services = () => {
  const [scrollDownClicked, setScrollDownClicked] = useState(false);
  const [isBottom, setIsBottom] = useState(false);

  const sectionRefs = useRef([]);
  const scrollDownRef = useRef(null);
  const scrollTopRef = useRef(null);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    // GSAP Animations for sections
    sectionRefs.current.forEach((section, index) => {
      if (section) {
        gsap.fromTo(
          section,
          { opacity: 0, y: 50 },
          {
            opacity: 1,
            y: 0,
            duration: 1,
            delay: index * 0.2,
            scrollTrigger: {
              trigger: section,
              start: "top 80%",
            },
          }
        );
      }
    });

    // GSAP Animation for Scroll Down Button
    if (scrollDownRef.current) {
      gsap.fromTo(
        scrollDownRef.current,
        { opacity: 0, y: -20 },
        { opacity: 1, y: 0, duration: 1 }
      );
    }
  }, []);

  const checkIfBottom = () => {
    if (
      window.innerHeight + window.scrollY >=
      document.body.offsetHeight - 200
    ) {
      setIsBottom(true);
    } else {
      setIsBottom(false);
    }
  };

  const scrollToContent = () => {
    const content = document.getElementById("content");
    if (content) {
      const contentOffsetTop = content.offsetTop;
      window.scrollTo({
        top: contentOffsetTop - 100,
        behavior: "smooth",
      });
    }
    setScrollDownClicked(true);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setScrollDownClicked(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", checkIfBottom);
    return () => {
      window.removeEventListener("scroll", checkIfBottom);
    };
  }, []);

  const services = [
    {
      title: "Digital Wallets",
      description:
        "Securely manage and store your digital assets with cutting-edge encryption technology.",
      icon: "/images/services/digital-wallets.png",
    },
    {
      title: "Asset tokenisation",
      description:
        "Convert physical and digital assets into blockchain tokens for seamless trading.",
      icon: "/images/services/asset-tokenisation.png",
    },
    {
      title: "Smart Contracts",
      description:
        "Automate processes and ensure compliance with transparent and secure smart contracts.",
      icon: "/images/services/smart-contracts.png",
    },
    {
      title: "decentralised Lending",
      description:
        "Access lending platforms with competitive rates and no intermediaries.",
      icon: "/images/services/defi-lending.png",
    },
    {
      title: "Compliance Solutions",
      description:
        "Ensure regulatory adherence with integrated AML/KYC compliance tools.",
      icon: "/images/services/compliance.png",
    },
    {
      title: "Blockchain Consulting",
      description:
        "Get expert advice on implementing blockchain technology into your business.",
      icon: "/images/services/consulting.png",
    },
  ];

  return (
    <div className="w-screen min-h-screen bg-black text-blue-50">
      {/* Hero Section */}
      <div className="relative w-screen h-screen overflow-hidden">
        <video
          src="/videos/services-hero.mp4"
          autoPlay
          loop
          muted
          className="absolute left-0 top-0 size-full object-cover"
        />
        <div className="absolute inset-0 flex flex-col items-center justify-center bg-black/60">
          <AnimatedTitle
            title="Our Services"
            containerClass="text-center text-blue-50 font-zentry"
          />
        </div>
      </div>

      {/* Scroll Down Button */}
      {!scrollDownClicked && (
        <div
          ref={scrollDownRef}
          className="fixed bottom-10 left-1/2 transform -translate-x-1/2 flex items-center gap-2 p-2 bg-transparent border border-gray-400 rounded-full shadow-lg cursor-pointer hover:bg-gray-800 transition-all duration-300"
          onClick={scrollToContent}
        >
          <TiArrowDown size={24} className="text-white/40 hover:text-white" />
          <span className="text-white/40 hover:text-white">SCROLL DOWN</span>
        </div>
      )}

      {/* Services Section */}
      <div id="content" className="px-10 py-20 mx-auto max-w-7xl">
        <h2 className="text-3xl font-bold text-center mb-10">
          What We Offer
        </h2>
        <p className="text-center mb-16">
          Explore the range of services designed to empower your blockchain journey.
        </p>
        <div className="grid gap-10 md:grid-cols-2 lg:grid-cols-3">
          {services.map((service, index) => (
            <div
              key={index}
              className="dashboard-section p-6 bg-black/70 rounded-lg shadow-lg text-center"
              ref={(el) => (sectionRefs.current[index] = el)}
            >
              <img
                src={service.icon}
                alt={service.title}
                className="w-16 h-16 mx-auto mb-4"
              />
              <h3 className="text-xl font-bold">{service.title}</h3>
              <p className="mt-4">{service.description}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Back to Top Button */}
      {isBottom && (
        <div
          ref={scrollTopRef}
          className="fixed bottom-10 left-5 flex items-center gap-2 p-2 bg-transparent border border-gray-400 rounded-full shadow-lg cursor-pointer hover:bg-gray-800 transition-all duration-300"
          onClick={scrollToTop}
        >
          <TiArrowUp
            size={24}
            className="text-white/40 hover:text-white transition-transform duration-300"
          />
          <span className="text-white/40 hover:text-white">BACK TO TOP</span>
        </div>
      )}

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default Services;
