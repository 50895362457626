import React, { useEffect, useState, useRef } from "react";
import AnimatedTitle from "./AnimatedTitle"; // Adjust the import path if necessary
import { TiArrowDown, TiArrowUp } from "react-icons/ti";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Footer from './Footer';

gsap.registerPlugin(ScrollTrigger);

const Blockchain = () => {
  const [scrollDownClicked, setScrollDownClicked] = useState(false);
  const [isBottom, setIsBottom] = useState(false);

  const sectionRefs = useRef([]);
  const scrollDownRef = useRef(null);
  const scrollTopRef = useRef(null);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    // GSAP Animations for sections
    sectionRefs.current.forEach((section, index) => {
      if (section) {
        gsap.fromTo(
          section,
          { opacity: 0, y: 50 },
          {
            opacity: 1,
            y: 0,
            duration: 1,
            delay: index * 0.2,
            scrollTrigger: {
              trigger: section,
              start: "top 80%",
            },
          }
        );
      }
    });

    // GSAP Animation for Scroll Down Button
    if (scrollDownRef.current) {
      gsap.fromTo(
        scrollDownRef.current,
        { opacity: 0, y: -20 },
        { opacity: 1, y: 0, duration: 1 }
      );
    }

    // GSAP Animation for Back to Top Button
    if (scrollTopRef.current) {
      gsap.fromTo(
        scrollTopRef.current,
        { opacity: 0, y: 20 },
        {
          opacity: 1,
          y: 0,
          duration: 0.5,
          scrollTrigger: {
            trigger: scrollTopRef.current,
            start: "top bottom",
          },
        }
      );
    }
  }, []);

  // Check if the user is near the bottom of the page
  const checkIfBottom = () => {
    if (
      window.innerHeight + window.scrollY >=
      document.body.offsetHeight - 200
    ) {
      setIsBottom(true); // Show Back to Top button
    } else {
      setIsBottom(false); // Hide Back to Top button
    }
  };

  // Scroll to the content section
  const scrollToContent = () => {
    const content = document.getElementById("content");
    if (content) {
      const contentOffsetTop = content.offsetTop;
      window.scrollTo({
        top: contentOffsetTop - 100,
        behavior: "smooth",
      });
    }
    setScrollDownClicked(true); // Hide Scroll Down button
  };

  // Scroll to the top of the page
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setScrollDownClicked(false); // Show Scroll Down button
  };

  // Add a scroll listener to monitor page position
  useEffect(() => {
    window.addEventListener("scroll", checkIfBottom);
    return () => {
      window.removeEventListener("scroll", checkIfBottom);
    };
  }, []);

  // Blockchain solutions data
  const blockchainSolutions = [
    {
      title: "Zero-Coin Wallets",
      description:
        "Manage your Zero-Coin and digital assets with unparalleled security and user-friendly design.",
      image: "/images/zero-blockchain/wallet.jpg",
    },
    {
      title: "Global Payments Revolution",
      description:
        "Experience real-time, cost-efficient cross-border transactions with Zero Blockchain.",
      image: "/images/zero-blockchain/global-payments.jpg",
    },
    {
      title: "Blockchain Lending Platforms",
      description:
        "Access transparent, competitive lending solutions enabled by decentralised finance.",
      image: "/images/zero-blockchain/defi-lending.jpg",
    },
    {
      title: "Tokenised Investments",
      description:
        "Transform traditional assets like real estate into liquid, blockchain-based tokens.",
      image: "/images/zero-blockchain/tokenisation.jpg",
    },
    {
      title: "Built-in Compliance Tools",
      description:
        "Ensure adherence to global regulations with automated KYC/AML mechanisms.",
      image: "/images/zero-blockchain/compliance.jpg",
    },
  ];

  return (
    <div className="w-screen min-h-screen bg-black text-blue-50">
      {/* Hero Section */}
      <div className="relative w-screen h-screen overflow-hidden">
        <video
          src="/videos/blockchain-hero.mp4"
          autoPlay
          loop
          muted
          className="absolute left-0 top-0 size-full object-cover"
        />
        <div className="absolute inset-0 flex flex-col items-center justify-center bg-black/60">
          <AnimatedTitle
            title="The Future of Financial Services: Powered by Blockchain"
            containerClass="text-center text-blue-50 font-zentry"
          />
        </div>
      </div>

      {/* Scroll Down Button */}
      {!scrollDownClicked && (
        <div
          ref={scrollDownRef}
          className="fixed bottom-10 left-1/2 transform -translate-x-1/2 flex items-center gap-2 p-2 bg-transparent border border-gray-400 rounded-full shadow-lg cursor-pointer hover:bg-gray-800 transition-all duration-300"
          onClick={scrollToContent}
        >
          <TiArrowDown
            className="text-white/40 transition-transform duration-300 hover:scale-110 hover:text-white"
            size={24}
          />
          <span className="relative z-20 text-sm uppercase text-white/40 transition-colors duration-300 hover:text-white">
            SCROLL DOWN
          </span>
        </div>
      )}

      {/* Content Section */}
        <div id="content" className="px-10 py-20 mx-auto max-w-7xl">
        {/* Introduction */}
        <div
        className="mb-10 text-center"
        ref={(el) => (sectionRefs.current[0] = el)}
        >
        <h2 className="text-3xl font-bold text-white">
            Revolutionising Financial Services
        </h2>
        <p className="mt-3 text-lg text-white-900">
            Unlock new possibilities in finance with Zero Blockchain. By addressing inefficiencies and enabling secure, scalable, and transparent solutions, 
            we are building the future of decentralised financial services.
        </p>
        </div>

        {/* Feature Highlights */}
        <div className="mb-20 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        <div className="flex flex-col items-center text-center">
            <img
            src="/images/zero-blockchain/icon-security.png"
            alt="Advanced Security"
            className="w-20 h-20 mb-4"
            />
            <h3 className="text-xl font-bold text-white">Advanced Security</h3>
            <p className="mt-2 text-sm text-white-900">
            Powered by zk-SNARKs and hybrid consensus mechanisms, Zero Blockchain ensures the highest levels of security.
            </p>
        </div>
        <div className="flex flex-col items-center text-center">
            <img
            src="/images/zero-blockchain/icon-interoperability.png"
            alt="Seamless Interoperability"
            className="w-20 h-20 mb-4"
            />
            <h3 className="text-xl font-bold text-white">Seamless Interoperability</h3>
            <p className="mt-2 text-sm text-white-900">
            APIs and SDKs enable smooth integration with legacy systems, creating a unified ecosystem for financial services.
            </p>
        </div>
        <div className="flex flex-col items-center text-center">
            <img
            src="/images/zero-blockchain/icon-efficiency.png"
            alt="Energy Efficiency"
            className="w-20 h-20 mb-4"
            />
            <h3 className="text-xl font-bold text-white">Energy Efficiency</h3>
            <p className="mt-2 text-sm text-white-900">
            Designed with sustainability in mind, Zero Blockchain reduces energy usage through its innovative architecture.
            </p>
        </div>
        </div>

        {/* Blockchain Solutions Section */}
        <div
        className="grid gap-10 md:grid-cols-2 lg:grid-cols-3"
        ref={(el) => (sectionRefs.current[1] = el)}
        >
        {blockchainSolutions.map((solution, index) => (
            <div
            key={index}
            className="relative p-6 bg-black/70 rounded-lg shadow-lg border border-gray-400 overflow-hidden group hover:scale-105 transition-transform"
            >
            {/* Background Image */}
            <div
                className="absolute inset-0 z-0 bg-cover bg-center opacity-20 group-hover:opacity-30 transition-opacity duration-300"
                style={{ backgroundImage: `url(${solution.image})` }}
            ></div>
            {/* Content */}
            <div className="relative z-10">
                <h3 className="text-xl font-bold text-white">{solution.title}</h3>
                <p className="mt-4 text-sm text-white-900">{solution.description}</p>
            </div>
            {/* Decorative Overlay */}
            <div className="absolute inset-0 z-5 bg-gradient-to-b from-transparent to-black opacity-70"></div>
            </div>
        ))}
        </div>

        {/* Executive Summary */}
<div className="mt-20">
<h2 className="text-3xl font-bold text-center text-white">
  Executive Summary
</h2>
<p className="mt-3 text-center text-lg text-white-900">
  Zero Blockchain revolutionises the financial services industry by addressing inefficiencies and unlocking innovation. 
  Its cutting-edge architecture and advanced features position it as a leader in blockchain-powered solutions.
</p>
<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mt-10">
  <div className="p-6 bg-black/70 rounded-lg shadow-lg">
    <h3 className="text-xl font-bold text-white">Transparency</h3>
    <p className="mt-2 text-sm text-white-900">
      Zero Blockchain enhances trust with transparent systems, enabling stakeholders to verify transactions effortlessly.
    </p>
  </div>
  <div className="p-6 bg-black/70 rounded-lg shadow-lg">
    <h3 className="text-xl font-bold text-white">Security</h3>
    <p className="mt-2 text-sm text-white-900">
      Robust cryptographic techniques protect data and transactions from unauthorized access and fraud.
    </p>
  </div>
  <div className="p-6 bg-black/70 rounded-lg shadow-lg">
    <h3 className="text-xl font-bold text-white">Efficiency</h3>
    <p className="mt-2 text-sm text-white-900">
      Instant cross-border payments and seamless integrations drastically reduce operational costs and delays.
    </p>
  </div>
  <div className="p-6 bg-black/70 rounded-lg shadow-lg">
    <h3 className="text-xl font-bold text-white">Regulatory Compliance</h3>
    <p className="mt-2 text-sm text-white-900">
      Automated compliance tools ensure adherence to global financial regulations with minimal manual intervention.
    </p>
  </div>
</div>
</div>

{/* Payment Integration Solutions */}
<div className="mt-20">
  <h2 className="text-3xl font-bold text-center text-white">
    Payment Integration Solutions
  </h2>
  <p className="mt-3 text-center text-lg text-white-900">
    Zero Blockchain redefines payment systems with scalable and secure integration capabilities, empowering global financial connectivity.
  </p>
  <div className="flex flex-col md:flex-row gap-10 mt-10">
    <div className="w-full md:w-1/2">
      <img
        src="/images/zero-blockchain/payment-solutions.jpg"
        alt="Payment Integration"
        className="rounded-lg shadow-lg"
      />
    </div>
    <div className="w-full md:w-1/2">
      <h3 className="text-2xl font-bold text-white">Key Features</h3>
      <ul className="mt-4 list-disc list-inside text-sm text-white-700">
        <li>Real-time cross-border payment processing.</li>
        <li>Integration-ready APIs and SDKs for seamless adoption.</li>
        <li>High transaction throughput with minimal latency.</li>
        <li>Built-in compliance tools for regulatory adherence.</li>
        <li>Support for fiat and cryptocurrency payments.</li>
      </ul>
      <p className="mt-4 text-sm text-white-900">
        Zero Blockchain ensures payment systems are not only secure and efficient but also future-ready, enabling financial institutions to scale globally.
      </p>
    </div>
  </div>
</div>


{/* Market Analysis and Competitive Landscape */}
<div className="mt-20">
  <h2 className="text-3xl font-bold text-center text-white">
    Market Analysis and Competitive Landscape
  </h2>
  <p className="mt-3 text-center text-lg text-white-900">
    Zero Blockchain positions itself as a leader in the fast-evolving financial blockchain market by addressing key inefficiencies with tailored solutions.
  </p>
  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 mt-10">
    <div className="p-6 bg-black/70 rounded-lg shadow-lg">
      <h3 className="text-xl font-bold text-white">Traditional Systems</h3>
      <p className="mt-2 text-sm text-white-900">
        Legacy systems are slow, expensive, and opaque, failing to meet modern financial needs.
      </p>
    </div>
    <div className="p-6 bg-black/70 rounded-lg shadow-lg">
      <h3 className="text-xl font-bold text-white">Competing Blockchains</h3>
      <p className="mt-2 text-sm text-white-900">
        Other blockchain platforms often lack scalability and interoperability essential for financial applications.
      </p>
    </div>
    <div className="p-6 bg-black/70 rounded-lg shadow-lg">
      <h3 className="text-xl font-bold text-white">Zero Blockchain</h3>
      <p className="mt-2 text-sm text-white-900">
        Designed specifically for financial services, offering unparalleled integration, security, and scalability.
      </p>
    </div>
  </div>
  <p className="mt-6 text-center text-sm text-white-900">
    By addressing inefficiencies and leveraging advanced technology, Zero Blockchain positions itself as a trusted partner for financial innovation.
  </p>
</div>


{/* Strategic Partnerships and Collaborations */}
<div className="mt-20">
  <h2 className="text-3xl font-bold text-center text-white">
    Strategic Partnerships and Collaborations
  </h2>
  <p className="mt-3 text-center text-lg text-white-900">
    Partnerships are at the core of Zero Blockchain’s growth strategy, enabling innovation and amplifying its ecosystem.
  </p>
  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mt-10">
    <div className="p-6 bg-black/70 rounded-lg shadow-lg">
      <h3 className="text-xl font-bold text-white">Financial Institutions</h3>
      <p className="mt-2 text-sm text-white-900">
        Collaborating with banks, investment firms, and payment processors to transform traditional systems.
      </p>
    </div>
    <div className="p-6 bg-black/70 rounded-lg shadow-lg">
      <h3 className="text-xl font-bold text-white">Regulators</h3>
      <p className="mt-2 text-sm text-white-900">
        Aligning with global regulators to ensure compliance and foster trust in blockchain solutions.
      </p>
    </div>
    <div className="p-6 bg-black/70 rounded-lg shadow-lg">
      <h3 className="text-xl font-bold text-white">Technology Providers</h3>
      <p className="mt-2 text-sm text-white-900">
        Partnering with cloud services, cybersecurity firms, and blockchain infrastructure providers.
      </p>
    </div>
    <div className="p-6 bg-black/70 rounded-lg shadow-lg">
      <h3 className="text-xl font-bold text-white">Academic Institutions</h3>
      <p className="mt-2 text-sm text-white-900">
        Collaborating with universities and research institutions to foster talent and innovation.
      </p>
    </div>
  </div>
  <p className="mt-6 text-center text-sm text-white-900">
    With a focus on shared vision and operational synergies, Zero Blockchain’s partnerships drive adoption and foster innovation across sectors.
  </p>
</div>


{/* Roadmap Section */}
<div className="mt-20">
  <h2 className="text-3xl font-bold text-center text-white">
    Roadmap to Blockchain Innovation
  </h2>
  <p className="mt-3 text-center text-lg text-white-900">
    Zero Blockchain's roadmap lays out a clear path for delivering scalable, secure, and transformative blockchain solutions globally.
  </p>

  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 mt-10">
    {/* Phase 1 */}
    <div className="p-6 bg-black/70 rounded-lg shadow-lg text-center">
      <h3 className="text-xl font-bold text-white">Phase 1: Conceptualisation</h3>
      <p className="mt-2 text-sm text-white-900">
        Conducting research, market analysis, and forming partnerships to identify key challenges and opportunities in the financial services sector.
      </p>
      <ul className="mt-4 list-disc list-inside text-sm text-white-700 text-left">
        <li>Industry pain points identified.</li>
        <li>Key partnerships initiated.</li>
        <li>Zero Blockchain strategy defined.</li>
      </ul>
    </div>

    {/* Phase 2 */}
    <div className="p-6 bg-black/70 rounded-lg shadow-lg text-center">
      <h3 className="text-xl font-bold text-white">Phase 2: Prototype Development</h3>
      <p className="mt-2 text-sm text-white-900">
        Building the foundational elements, including the Zero-Coin framework, smart contracts, and payment integrations.
      </p>
      <ul className="mt-4 list-disc list-inside text-sm text-white-700 text-left">
        <li>Smart contracts ecosystem built.</li>
        <li>Prototype for Zero-Coin tested.</li>
        <li>Initial compliance tools integrated.</li>
      </ul>
    </div>

    {/* Phase 3 */}
    <div className="p-6 bg-black/70 rounded-lg shadow-lg text-center">
      <h3 className="text-xl font-bold text-white">Phase 3: Pilot Testing</h3>
      <p className="mt-2 text-sm text-white-900">
        Conducting pilot programs with selected financial institutions to validate Zero Blockchain’s capabilities and refine features.
      </p>
      <ul className="mt-4 list-disc list-inside text-sm text-white-700 text-left">
        <li>Integration with legacy systems.</li>
        <li>Feedback-driven feature enhancements.</li>
        <li>Security and scalability validated.</li>
      </ul>
    </div>

    {/* Phase 4 */}
    <div className="p-6 bg-black/70 rounded-lg shadow-lg text-center">
      <h3 className="text-xl font-bold text-white">Phase 4: Global Deployment</h3>
      <p className="mt-2 text-sm text-white-900">
        Launching Zero Blockchain on a global scale with strategic marketing campaigns and adoption programs.
      </p>
      <ul className="mt-4 list-disc list-inside text-sm text-white-700 text-left">
        <li>Zero-Coin widely adopted.</li>
        <li>Cross-border payment networks live.</li>
        <li>Global regulatory compliance achieved.</li>
      </ul>
    </div>

    {/* Phase 5 */}
    <div className="p-6 bg-black/70 rounded-lg shadow-lg text-center">
      <h3 className="text-xl font-bold text-white">Phase 5: Continuous Innovation</h3>
      <p className="mt-2 text-sm text-white-900">
        Incorporating emerging technologies like AI and Layer 2 solutions to stay ahead in the evolving blockchain landscape.
      </p>
      <ul className="mt-4 list-disc list-inside text-sm text-white-700 text-left">
        <li>AI-enabled fraud detection integrated.</li>
        <li>Enhanced interoperability with other blockchains.</li>
        <li>Continuous updates for user-centric features.</li>
      </ul>
    </div>
  </div>

  {/* Call to Action */}
  <div className="mt-16 flex justify-center gap-6">
    <a
      href="/roadmap"
      className="px-6 py-3 bg-blue-600 text-white rounded-lg shadow-lg hover:bg-blue-800 transition"
    >
      Explore Full Roadmap
    </a>
    <a
      href="/pilot-programs"
      className="px-6 py-3 bg-green-600 text-white rounded-lg shadow-lg hover:bg-green-800 transition"
    >
      Join a Pilot Program
    </a>
  </div>
</div>





        {/* Visionary Statements */}
        <div className="mt-20 text-center">
        <h3 className="text-2xl font-bold text-white">
            Empowering a New Era of Finance
        </h3>
        <p className="mt-4 text-lg text-white-900">
            From tokenising real-world assets to enabling instant global payments, Zero Blockchain is at the forefront of revolutionising financial services.
            Join us in transforming how the world transacts and invests.
        </p>
        </div>




        {/* Detailed Features */}
<div
className="mt-20 space-y-16"
ref={(el) => (sectionRefs.current[2] = el)}
>
{/* Section 1 */}
<div className="flex flex-col-reverse items-center gap-10 md:flex-row">
  <div className="w-full md:w-1/2">
    <img
      src="/images/zero-blockchain/security.jpg"
      alt="Advanced Security"
      className="rounded-lg shadow-lg"
    />
  </div>
  <div className="w-full md:w-1/2">
    <h3 className="text-2xl font-bold text-white">
      Advanced Security
    </h3>
    <p className="mt-4 text-sm text-white-900">
      Zero Blockchain leverages zk-SNARKs, hybrid consensus mechanisms, and real-time auditing to ensure unparalleled security. 
      Built-in cryptographic protocols safeguard data and transactions against malicious threats and breaches.
    </p>
    <ul className="mt-4 list-disc list-inside text-sm text-white-700">
      <li>End-to-End Encryption for data transmission.</li>
      <li>Distributed architecture reduces single points of failure.</li>
      <li>Continuous anomaly detection with real-time alerts.</li>
    </ul>
  </div>
</div>

{/* Section 2 */}
<div className="flex flex-col items-center gap-10 md:flex-row">
  <div className="w-full md:w-1/2">
    <h3 className="text-2xl font-bold text-white">
      Seamless Interoperability
    </h3>
    <p className="mt-4 text-sm text-white-900">
      APIs and SDKs enable Zero Blockchain to integrate effortlessly with traditional financial systems and other blockchain networks. 
      Custom-built connectors ensure compatibility across diverse platforms.
    </p>
    <ul className="mt-4 list-disc list-inside text-sm text-white-700">
      <li>RESTful and GraphQL APIs for flexible integrations.</li>
      <li>Developer SDKs in Python, JavaScript, and more.</li>
      <li>Middleware tools for seamless legacy system upgrades.</li>
    </ul>
  </div>
  <div className="w-full md:w-1/2">
    <img
      src="/images/zero-blockchain/interoperability.jpg"
      alt="Seamless Integration"
      className="rounded-lg shadow-lg"
    />
  </div>
</div>

{/* Section 3 */}
<div className="flex flex-col-reverse items-center gap-10 md:flex-row">
  <div className="w-full md:w-1/2">
    <img
      src="/images/zero-blockchain/efficiency.jpg"
      alt="Energy Efficiency"
      className="rounded-lg shadow-lg"
    />
  </div>
  <div className="w-full md:w-1/2">
    <h3 className="text-2xl font-bold text-white">
      Energy Efficiency
    </h3>
    <p className="mt-4 text-sm text-white-900">
      Zero Blockchain's low-energy consensus mechanisms and modular architecture align with global sustainability goals. Advanced sharding further optimizes resource usage.
    </p>
    <ul className="mt-4 list-disc list-inside text-sm text-white-700">
      <li>Hybrid consensus reduces energy consumption by 60%.</li>
      <li>Sharding enables high scalability without compromising security.</li>
      <li>Built for green blockchain initiatives.</li>
    </ul>
  </div>
</div>

{/* Section 4: Real-Time Settlement */}
<div className="flex flex-col items-center gap-10 md:flex-row">
  <div className="w-full md:w-1/2">
    <h3 className="text-2xl font-bold text-white">
      Real-Time Settlements
    </h3>
    <p className="mt-4 text-sm text-white-900">
      Zero Blockchain supports instant cross-border payments, reducing operational costs and enhancing customer satisfaction. 
      The platform processes transactions in under five seconds with minimal fees.
    </p>
    <ul className="mt-4 list-disc list-inside text-sm text-white-700">
      <li>Instantaneous global transactions with low latency.</li>
      <li>Reduces settlement times from days to seconds.</li>
      <li>Transparent fee structures and enhanced user experience.</li>
    </ul>
  </div>
  <div className="w-full md:w-1/2">
    <img
      src="/images/zero-blockchain/real-time.jpg"
      alt="Real-Time Settlement"
      className="rounded-lg shadow-lg"
    />
  </div>
</div>

{/* Section 5: Tokenised Asset Management */}
<div className="flex flex-col-reverse items-center gap-10 md:flex-row">
  <div className="w-full md:w-1/2">
    <img
      src="/images/zero-blockchain/tokenisation.jpg"
      alt="Tokenised Assets"
      className="rounded-lg shadow-lg"
    />
  </div>
  <div className="w-full md:w-1/2">
    <h3 className="text-2xl font-bold text-white">
      Tokenised Asset Management
    </h3>
    <p className="mt-4 text-sm text-white-900">
      Transform traditional investments into tokenised assets, enabling fractional ownership and increased liquidity. 
      Zero Blockchain's framework facilitates secure and transparent trading of tokenised real-world assets.
    </p>
    <ul className="mt-4 list-disc list-inside text-sm text-white-700">
      <li>Fractional ownership for greater investment access.</li>
      <li>Instant liquidity for traditionally illiquid assets.</li>
      <li>Enhanced transparency through blockchain records.</li>
    </ul>
  </div>
</div>

{/* Section 6: Compliance Automation */}
<div className="flex flex-col items-center gap-10 md:flex-row">
  <div className="w-full md:w-1/2">
    <h3 className="text-2xl font-bold text-white">
      Automated Compliance
    </h3>
    <p className="mt-4 text-sm text-white-900">
      Built-in AML and KYC tools streamline regulatory compliance, minimizing manual intervention. 
      Real-time checks ensure adherence to global standards.
    </p>
    <ul className="mt-4 list-disc list-inside text-sm text-white-700">
      <li>Automatic AML/KYC checks for fraud prevention.</li>
      <li>Real-time transaction monitoring for suspicious activities.</li>
      <li>Comprehensive compliance with global regulations.</li>
    </ul>
  </div>
  <div className="w-full md:w-1/2">
    <img
      src="/images/zero-blockchain/compliance.jpg"
      alt="Compliance Automation"
      className="rounded-lg shadow-lg"
    />
  </div>
</div>

{/* Call to Action */}
<div className="mt-16 flex flex-col md:flex-row justify-center items-center gap-6">
  <a
    href="/whitepaper"
    className="px-6 py-3 bg-blue-600 text-white rounded-lg shadow-lg hover:bg-blue-800 transition"
  >
    Download White Paper
  </a>
  <a
    href="/demo"
    className="px-6 py-3 bg-green-600 text-white rounded-lg shadow-lg hover:bg-green-800 transition"
  >
    Watch Demo
  </a>
</div>
</div>

</div>
      {/* Back to Top Button */}
      {isBottom && (
        <div
          ref={scrollTopRef}
          className="fixed bottom-10 left-5 flex items-center gap-2 p-2 bg-transparent border border-gray-400 rounded-full shadow-lg cursor-pointer hover:bg-gray-800 transition-all duration-300"
          onClick={scrollToTop}
        >
          <TiArrowUp
            className="text-white/40 transition-transform duration-300 hover:scale-110 hover:text-white"
            size={24}
          />
          <span className="relative z-20 text-sm uppercase text-white/40 transition-colors duration-300 hover:text-white">
            BACK TO TOP
          </span>
        </div>
      )}

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default Blockchain;
