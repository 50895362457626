import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import { Link } from "react-router-dom"; // Import React Router Link
import { rightImg, watchImg } from "../utils";

import VideoCarousel from "./VideoCarousel";

const Highlights = () => {
  useGSAP(() => {
    gsap.to("#title", { opacity: 1, y: 0 });
    gsap.to(".link", { opacity: 1, y: 0, duration: 1, stagger: 0.25 });
  }, []);

  return (
    <section
      id="highlights"
      className="w-screen overflow-hidden h-full common-padding bg-zinc"
    >
      <div className="screen-max-width">
        <div className="mb-12 w-full md:flex items-end justify-between">
          <h1 id="title" className="section-heading">
            Get What You Like.
          </h1>

          <div className="flex flex-wrap items-end gap-5">
            {/* Link to Products Component */}
            <Link to="/products" className="link flex items-center">
              View The Products
              <img src={rightImg} alt="right" className="ml-2" />
            </Link>

            {/* Link to ZeroCoin Component */}
            <Link to="/zero-coin" className="link flex items-center">
              Get The Coin
              <img src={rightImg} alt="right" className="ml-2" />
            </Link>

            {/* Link to CryptoStore Component */}
            <Link to="/crypto-store" className="link flex items-center">
              Get The Tokens
              <img src={rightImg} alt="right" className="ml-2" />
            </Link>

            {/* External Link to Open Account */}
            <a
              href="https://zero-bank.xyz"
              target="_blank"
              rel="noopener noreferrer"
              className="link flex items-center"
            >
              Open Account
              <img src={rightImg} alt="right" className="ml-2" />
            </a>
          </div>
        </div>

        <VideoCarousel />
      </div>
    </section>
  );
};

export default Highlights;
