// src/components/Products.jsx

import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from './Footer'; // Ensure correct import
import AnimatedTitle from './AnimatedTitle';
import { TiArrowUp } from "react-icons/ti";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import HeroButton from './HeroButton';
import ScrollDownButton from './ScrollDownButton';
import products from '../data/productsData'; // Import the products data

// Register GSAP plugins
gsap.registerPlugin(ScrollTrigger);

const Products = () => {
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedSector, setSelectedSector] = useState('All');
    const [scrollDownClicked, setScrollDownClicked] = useState(false);
    const [isBottom, setIsBottom] = useState(false);

    const sectionRefs = useRef([]);
    const scrollDownRef = useRef(null);
    const scrollTopRef = useRef(null);
    const productsRef = useRef(null);

    const handleMoreDetailsClick = (id) => {
        navigate(`/products/${id}`);
    };

    const handlePurchaseClick = (name) => {
        alert(`Purchasing ${name}`);
    };

    const handleViewOnChainClick = (link) => {
        window.open(link, '_blank');
    };

    // GSAP Animations for sections and products
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });

        // Animate Hero Section
        if (sectionRefs.current[0]) {
            gsap.fromTo(
                sectionRefs.current[0],
                { opacity: 0, y: -50 },
                {
                    opacity: 1,
                    y: 0,
                    duration: 1,
                    ease: "power3.out",
                    scrollTrigger: {
                        trigger: sectionRefs.current[0],
                        start: "top 80%",
                    },
                }
            );
        }

        // Animate Products Section
        if (productsRef.current) {
            gsap.fromTo(
                productsRef.current,
                { opacity: 0, y: 50 },
                {
                    opacity: 1,
                    y: 0,
                    duration: 1,
                    ease: "power3.out",
                    scrollTrigger: {
                        trigger: productsRef.current,
                        start: "top 80%",
                    },
                }
            );
        }

        // GSAP Animation for Scroll Down Button
        if (scrollDownRef.current) {
            gsap.fromTo(
                scrollDownRef.current,
                { opacity: 0, y: -20 },
                { opacity: 1, y: 0, duration: 1, ease: "power3.out" }
            );
        }

        // GSAP Animation for Back to Top Button
        if (scrollTopRef.current) {
            gsap.fromTo(
                scrollTopRef.current,
                { opacity: 0, y: 20 },
                {
                    opacity: 1,
                    y: 0,
                    duration: 0.5,
                    ease: "power3.out",
                    scrollTrigger: {
                        trigger: scrollTopRef.current,
                        start: "top bottom",
                    },
                }
            );
        }
    }, []);

    // Check if the user is near the bottom of the page
    const checkIfBottom = () => {
        if (
            window.innerHeight + window.scrollY >=
            document.body.offsetHeight - 200
        ) {
            setIsBottom(true); // Show Back to Top button
        } else {
            setIsBottom(false); // Hide Back to Top button
        }
    };

    // Scroll to the products section
    const scrollToContent = () => {
        const content = productsRef.current;
        if (content) {
            const contentOffsetTop = content.offsetTop;
            window.scrollTo({
                top: contentOffsetTop - 80, // Adjust offset as needed
                behavior: "smooth",
            });
        }
        setScrollDownClicked(true); // Hide Scroll Down button
    };

    // Scroll to the top of the page
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
        setScrollDownClicked(false); // Show Scroll Down button
    };

    // Add a scroll listener to monitor page position
    useEffect(() => {
        window.addEventListener("scroll", checkIfBottom);
        return () => {
            window.removeEventListener("scroll", checkIfBottom);
        };
    }, []);

    // Handle search and filter
    const handleSearchChange = (e) => setSearchTerm(e.target.value);
    const handleSectorChange = (e) => setSelectedSector(e.target.value);

    const filteredProducts = products.filter(product => {
        return (
            product.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
            (selectedSector === 'All' || product.sector === selectedSector)
        );
    });

    return (
        <div className="w-screen min-h-screen bg-black text-blue-50 flex flex-col items-center p-5">
            {/* Hero Section */}
            <div
                className="relative w-full h-64 md:h-96 overflow-hidden flex flex-col items-center justify-center mb-10"
                ref={(el) => (sectionRefs.current[0] = el)}
            >
                <video
                    src="/videos/cryptostore-hero.mp4" // Ensure this video exists in your public/videos directory
                    autoPlay
                    loop
                    muted
                    className="absolute left-0 top-0 w-full h-full object-cover"
                />
                <div className="absolute inset-0 flex flex-col items-center justify-center bg-black/60">
                    {/* AnimatedTitle with Updated Main Title */}
                    <AnimatedTitle
                        title="Our Fabulous Products"
                        containerClass="text-center text-blue-50 font-zentry text-5xl md:text-6xl"
                    />
                    {/* Subline */}
                    <h3 className="mt-4 text-lg md:text-xl white-900">
                        Explore the Innovation Behind Zero-Blockchain
                    </h3>
                    {/* Descriptive Text */}
                    <p className="mt-2 text-sm md:text-base text-white-900 max-w-2xl text-center">
                        Discover our suite of products designed to revolutionise the blockchain and fintech industries, driving innovation and efficiency.
                    </p>
                </div>
                {/* Scroll Down Button */}
                {!scrollDownClicked && (
                    <ScrollDownButton
                        targetId="products-section"
                        onClick={scrollToContent}
                        ref={scrollDownRef}
                    />
                )}
            </div>

            {/* Search and Filter Section */}
            <div className="w-full max-w-6xl mb-5" id="products-section" ref={productsRef}>
                <div className="flex flex-col md:flex-row md:items-center md:justify-between mb-5">
                    <input
                        type="text"
                        placeholder="Search products..."
                        value={searchTerm}
                        onChange={handleSearchChange}
                        className="w-full md:w-1/2 p-3 rounded-md bg-gray-800 text-white placeholder-gray-500 mb-3 md:mb-0"
                        aria-label="Search products"
                    />
                    <select
                        value={selectedSector}
                        onChange={handleSectorChange}
                        className="w-full md:w-1/4 p-3 rounded-md bg-gray-800 text-white"
                        aria-label="Filter by sector"
                    >
                        <option value="All">All Sectors</option>
                        <option value="Blockchain">Blockchain</option>
                        <option value="Fin-Tech">Fin-Tech</option>
                        <option value="AI">AI</option>
                    </select>
                </div>
                <p className="text-white-900 mb-5">Total Products: {filteredProducts.length}</p>
                {/* Products Grid */}
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    {filteredProducts.map(product => (
                        <div key={product.id} className="bg-gray-800 shadow-md rounded-lg overflow-hidden flex flex-col">
                            <div className="h-48 bg-gray-600 flex items-center justify-center">
                                <img
                                    src={product.image}
                                    alt={product.name}
                                    className="h-full w-full object-cover"
                                />
                            </div>
                            <div className="p-5 flex-grow flex flex-col">
                                <h2 className="text-2xl font-bold mb-3 text-white">{product.name}</h2>
                                <p className="mb-3 text-white-900">{product.sector}</p>
                                <p className="mb-3 text-white-900">{product.description}</p>
                                <div className="mt-auto flex justify-between items-center">
                                <HeroButton
                                onClick={() => handleMoreDetailsClick(product.id)}
                                ariaLabel={`More details about ${product.name}`}
                                additionalClasses="m-2"
                                style={{ fontSize: '12px' }} // Inline style to ensure smaller text
                              >
                                More Details
                              </HeroButton>
                              <HeroButton
                                onClick={() => handleViewOnChainClick(product.link)}
                                ariaLabel={`Go to ${product.name}`}
                                additionalClasses="m-2 bg-green-500 hover:bg-green-600"
                                style={{ fontSize: '12px' }} // Inline style to ensure smaller text
                              >
                                Go to Product
                              </HeroButton>
                              
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {/* Back to Top Button */}
            {isBottom && (
                <div
                    ref={scrollTopRef}
                    className="fixed bottom-10 left-5 flex items-center gap-2 p-2 bg-transparent border border-gray-400 rounded-full shadow-lg cursor-pointer hover:bg-gray-800 transition-all duration-300"
                    onClick={scrollToTop}
                    aria-label="Back to Top"
                    role="button"
                    tabIndex={0}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter' || e.key === ' ') {
                            scrollToTop();
                        }
                    }}
                >
                    <TiArrowUp
                        className="text-white-900 hover:text-white transition-transform duration-300 hover:scale-110"
                        size={24}
                    />
                    <span className="relative z-20 text-sm uppercase text-white-900 transition-colors duration-300 hover:text-white">
                        BACK TO TOP
                    </span>
                </div>
            )}

            {/* Footer */}
            <Footer />
        </div>
    );

};

export default Products;
