/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useEffect, useRef } from 'react';
import { useGLTF } from '@react-three/drei';

function Model(props) {
  const { nodes, materials } = useGLTF('/models/27-KAKAPO-ERC20-ZERO-TOKEN.glb');
  const isUVFlipped = useRef(true);

  // Adjust UV mapping for the mesh to flip the texture horizontally
  useEffect(() => {
    if (!isUVFlipped.current && nodes.mesh_2.geometry.attributes.uv) {
      const uv = nodes.mesh_2.geometry.attributes.uv.array;
      for (let i = 0; i < uv.length; i += 2) {
        uv[i] = 1 - uv[i]; // Flip horizontally
      }
      nodes.mesh_2.geometry.attributes.uv.needsUpdate = true;
      isUVFlipped.current = true; // Mark UV as flipped
    }
  }, [nodes]);

  return (
    <group {...props} dispose={null}>
      <group rotation={[-1.50, 179.06, 0]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.mesh_0.geometry}
          material={nodes.mesh_0.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.mesh_1.geometry}
          material={nodes.mesh_1.material}
          position={[0, -0.127, 0]}
          rotation={[Math.PI / 2, 0, Math.PI]} // Added 180-degree rotation on the z-axis
        />

        <mesh
          castShadow
          receiveShadow
          geometry={nodes.mesh_2.geometry}
          material={nodes.mesh_2.material}
          position={[0, 0.127, 0]}
          rotation={[-Math.PI / 2, 0, 0]} // Rotate mesh 2 by 180 degrees
          
        />
      </group>
    </group>
  );
}

useGLTF.preload('/models/26-KAKAPO-ERC20-ZERO-TOKEN.glb');

export default Model;
