import React from 'react';
import { Link } from 'react-router-dom';
import { footerLinks } from '../constants';

const Footer = () => {
  return (
    <footer className="py-5 sm:px-10 px-5 pt-0">
      <div className="screen-max-width">
        <div>
          <p className="font-semibold text-gray text-xs">
            More ways to Bank: {' '}
            <Link to="/products" className="underline text-blue">
              Find A Zero Product
            </Link>{' '}
            Or{' '}
            <span className="underline text-blue">
              Place To Purchase Our Crypto Assets{' '}
            </span>
            &nbsp;around this space.
          </p>
          <p className="font-semibold text-gray text-xs">
            Or Contact zero-blockchain.xyz
          </p>
        </div>

        <div className="bg-neutral-700 my-5 h-[1px] w-full" />

        <div className="flex md:flex-row flex-col md:items-center justify-between">
          <p className="font-semibold text-gray text-xs">
            Copyright @ 2024 Zero-Bank.XYZ All rights reserved.
          </p>
          <div className="flex">
            {footerLinks.map((link, i) => {
              // Map link text to correct paths
              const routesMap = {
                "Privacy Policy": "/privacy-policy",
                "Terms of Use": "/terms-and-conditions",
                "Sales Policy": "/sales-policy",
                "Legal": "/legal",
                "Site Map": "/site-map",
              };
              const linkPath = routesMap[link] || "#"; // Fallback to "#" if no route exists
              return (
                <React.Fragment key={link}>
                  <Link
                    to={linkPath}
                    className="font-semibold text-blue text-xs underline"
                  >
                    {link}
                  </Link>
                  {i !== footerLinks.length - 1 && (
                    <span className="mx-2 text-gray"> | </span>
                  )}
                </React.Fragment>
              );
            })}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
