// src/data/productsData.js

import zeroBlockchainImg from '../assets/Zero-Blockchain.png';
import zeroFlowImg from '../assets/Zero-Flow.png';
import zeroCryptoWalletImg from '../assets/Zero-Crypto-Wallet.png';
import zeroPulseImg from '../assets/Zero-Pulse.png';
import zeroWaveImg from '../assets/Zero-Wave.png';
import zeroPayImg from '../assets/Zero-Pay.png';
import zeroComplyImg from '../assets/Zero-Comply.png';
import zeroSuperviseImg from '../assets/Zero-Supervise.png';
import zeroLendImg from '../assets/Zero-Lend.png';
import zeroCoreImg from '../assets/Zero-Core.png';
import zeroInsureImg from '../assets/Zero-Insure.png';
import zeroWealthImg from '../assets/Zero-Wealth.png';
import zeroGreenImg from '../assets/Zero-Green.png';
import zeroInsightImg from '../assets/Zero-Insight.png';
import zeroTradeImg from '../assets/Zero-Trade.png';
import zeroPredictImg from '../assets/Zero-Predict.png';
import zeroStocksImg from '../assets/Zero-Stocks.png';
import zeroTokensierImg from '../assets/Zero-Tokeniser.png';
import zeroBankImg from '../assets/Zero-Bank.png';

const products = [
    {
        id: 1,
        name: 'Zero-Blockchain.xyz',
        sector: 'Blockchain',
        description: 'XYZ - Report - Diagrams and Graphs. Zero-Blockchain.xyz offers comprehensive reports and visual data representations to enhance your understanding of blockchain technology.',
        link: 'https://example.com/zero-blockchain',
        image: zeroBlockchainImg
    },
    {
        id: 2,
        name: 'Zero-Flow',
        sector: 'Fin-Tech/Bank-Tech',
        description: 'Web 2.0 - Traditional Based Payment Integration System. Zero-Flow seamlessly integrates traditional payment methods with modern web technologies for efficient financial transactions.',
        link: 'https://example.com/zero-flow',
        image: zeroFlowImg
    },
    {
        id: 3,
        name: 'Zero-Crypto-Wallet',
        sector: 'Fin-Tech/DEFI/Bank-Tech',
        description: 'Web 3.0 - Blockchain Based Crypto Wallet. Zero-Crypto-Wallet offers a secure and user-friendly platform for managing your digital assets with advanced blockchain integration.',
        link: 'https://example.com/zero-crypto-wallet',
        image: zeroCryptoWalletImg
    },
    {
        id: 4,
        name: 'Zero-Pulse',
        sector: 'Fin-Tech/Markets/Bank-Tech',
        description: 'Market Analysis - Portfolio - Financial Assets Application. Zero-Pulse provides real-time market analysis and portfolio management tools for financial assets.',
        link: 'https://example.com/zero-pulse',
        image: zeroPulseImg
    },
    {
        id: 5,
        name: 'Zero-Wave',
        sector: 'Fin-Tech/DEFI/Bank-Tech',
        description: 'Mobile Banking Application - Mobile Wallet Model. Zero-Wave revolutionises mobile banking with a comprehensive mobile wallet solution, ensuring seamless financial transactions on the go.',
        link: 'https://example.com/zero-wave',
        image: zeroWaveImg
    },
    {
        id: 6,
        name: 'Zero-Pay',
        sector: 'Fin-Tech',
        description: 'PayTech - Global Payments Solution. Zero-Pay delivers a robust global payment system designed to handle cross-border transactions efficiently and securely.',
        link: 'https://example.com/zero-pay',
        image: zeroPayImg
    },
    {
        id: 7,
        name: 'Zero-Comply',
        sector: 'Fin-Tech',
        description: 'RegTech - Regulatory Compliance Automation. Zero-Comply automates regulatory compliance processes, reducing the burden of compliance management for financial institutions.',
        link: 'https://example.com/zero-comply',
        image: zeroComplyImg
    },
    {
        id: 8,
        name: 'Zero-Supervise',
        sector: 'Fin-Tech',
        description: 'SupTech - Financial Market Monitoring. Zero-Supervise offers advanced tools for monitoring financial markets, helping regulators maintain market integrity.',
        link: 'https://example.com/zero-supervise',
        image: zeroSuperviseImg
    },
    {
        id: 9,
        name: 'Zero-Lend',
        sector: 'Fin-Tech',
        description: 'LendTech - Blockchain Peer-to-Peer Lending. Zero-Lend provides a secure platform for peer-to-peer lending using blockchain technology, ensuring transparency and trust.',
        link: 'https://example.com/zero-lend',
        image: zeroLendImg
    },
    {
        id: 10,
        name: 'Zero-Core',
        sector: 'Fin-Tech',
        description: 'BankTech - Blockchain Banking Solutions. Zero-Core delivers innovative blockchain-based solutions for modern banking needs, enhancing security and efficiency.',
        link: 'https://example.com/zero-core',
        image: zeroCoreImg
    },
    {
        id: 11,
        name: 'Zero-Insure',
        sector: 'Fin-Tech',
        description: 'InsurTech - Real-Time Data-Driven Insurance. Zero-Insure utilises real-time data to provide personalized and efficient insurance solutions.',
        link: 'https://example.com/zero-insure',
        image: zeroInsureImg
    },
    {
        id: 12,
        name: 'Zero-Wealth',
        sector: 'Fin-Tech',
        description: 'WealthTech - Hybrid Investment Advisory. Zero-Wealth combines traditional investment advisory with cutting-edge technology to optimize your wealth management strategies.',
        link: 'https://example.com/zero-wealth',
        image: zeroWealthImg
    },
    {
        id: 13,
        name: 'Zero-Green',
        sector: 'Fin-Tech',
        description: 'GreenTech - Eco-Friendly Crowdfunding. Zero-Green supports eco-friendly projects through a dedicated crowdfunding platform, promoting sustainable development.',
        link: 'https://example.com/zero-green',
        image: zeroGreenImg
    },
    {
        id: 14,
        name: 'Zero-Insight',
        sector: 'AI',
        description: 'AI-Powered Personalized Financial Advice. Zero-Insight offers personalized financial advice powered by advanced AI algorithms, helping you make informed decisions.',
        link: 'https://example.com/zero-insight',
        image: zeroInsightImg
    },
    {
        id: 15,
        name: 'Zero-Trade',
        sector: 'AI',
        description: 'AI-Powered Trading. Zero-Trade leverages artificial intelligence to provide advanced trading solutions, enhancing your trading strategies with data-driven insights.',
        link: 'https://example.com/zero-trade',
        image: zeroTradeImg
    },
    {
        id: 16,
        name: 'Zero-Predict',
        sector: 'AI',
        description: 'Fraud Detection for Web 3.0 Blockchain. Zero-Predict utilises AI to detect and prevent fraud in blockchain transactions, ensuring the security of your digital assets.',
        link: 'https://example.com/zero-predict',
        image: zeroPredictImg
    },    
    {
        id: 17,
        name: 'Zero-Stocks',
        sector: 'Fin-Tech/Stock Trading',
        description: 'AI-Powered Mobile Stock Market Application. Zero-Stocks offers a cutting-edge mobile application that leverages AI technology to provide real-time stock trading insights and personalized investment strategies, ensuring a seamless and efficient trading experience.',
        link: 'https://example.com/zero-bank',
        image: zeroStocksImg
    },
    {
        id: 18,
        name: 'Zero-Tokeniser',
        sector: 'Fin-Tech/Blockchain/Asset Management',
        description: 'Smart Contract-Powered Asset Tokenisation Platform. Zero-Tokeniser revolutionises asset management by enabling seamless tokenisation of real-world assets such as real estate, equities, and commodities. Powered by blockchain technology, it supports the creation, deployment, and management of diverse smart contracts, ensuring transparency, compliance, and efficiency in every transaction.',
        link: 'https://zero-tokeniser.xyz',
        image: zeroTokensierImg
    },    
    {
        id: 19,
        name: 'Zero-Bank',
        sector: 'Fin-Tech/Bank-Tech',
        description: 'Desktop Banking Application. Zero-Bank offers a comprehensive desktop banking application, providing a secure and efficient platform for managing your banking needs from the comfort of your home.',
        link: 'https://example.com/zero-bank',
        image: zeroBankImg
    }
];

export default products;
