import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from './Footer';
import AnimatedTitle from './AnimatedTitle';
import { TiArrowUp } from 'react-icons/ti';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import HeroButton from './HeroButton';
import ScrollDownButtonZeroCoin from './ScrollDownButtonZeroCoin';

gsap.registerPlugin(ScrollTrigger);

const ZeroCoin = () => {
  const navigate = useNavigate();
  const [scrollDownClicked, setScrollDownClicked] = useState(false);
  const [isBottom, setIsBottom] = useState(false);

  const sectionRefs = useRef([]);
  const scrollTopRef = useRef(null);

  const handleMoreDetailsClick = () => {
    navigate(`/token/0`);
  };

  const handlePurchaseClick = () => {
    navigate(`/zero-coin/purchase`);
  };

  const handleViewOnChainClick = () => {
    window.open(
      "https://etherscan.io/token/0xa47d4023561527a2ec51b216ab06cc5be34f38bd",
      "_blank"
    );
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    sectionRefs.current.forEach((section, index) => {
      if (section) {
        gsap.fromTo(
          section,
          { opacity: 0, y: 50 },
          {
            opacity: 1,
            y: 0,
            duration: 1,
            delay: index * 0.2,
            scrollTrigger: {
              trigger: section,
              start: "top 80%",
            },
          }
        );
      }
    });

    if (scrollTopRef.current) {
      gsap.fromTo(
        scrollTopRef.current,
        { opacity: 0, y: 20 },
        {
          opacity: 1,
          y: 0,
          duration: 0.5,
          scrollTrigger: {
            trigger: scrollTopRef.current,
            start: "top bottom",
          },
        }
      );
    }
  }, []);

  const checkIfBottom = () => {
    if (
      window.innerHeight + window.scrollY >=
      document.body.offsetHeight - 200
    ) {
      setIsBottom(true);
    } else {
      setIsBottom(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setScrollDownClicked(false); // Reset state to show "Scroll Down" button
  };

  useEffect(() => {
    window.addEventListener("scroll", checkIfBottom);
    return () => {
      window.removeEventListener("scroll", checkIfBottom);
    };
  }, []);

  return (
    <div className="w-screen min-h-screen bg-black text-blue-50 flex flex-col items-center p-5">
      {/* Hero Section */}
      <div className="relative w-full h-64 md:h-96 overflow-hidden mb-10">
        <video
          src="/videos/zero-coin-hero.mp4"
          autoPlay
          loop
          muted
          className="absolute left-0 top-0 w-full h-full object-cover"
        />
        <div className="absolute inset-0 flex flex-col items-center justify-center bg-black/60">
          <AnimatedTitle
            title="Zero-Coin: Empowering Zero-Blockchain"
            containerClass="text-center text-blue-50 font-zentry"
          />
        </div>
      </div>

      {/* Scroll Down Button */}
      {!scrollDownClicked && (
        <ScrollDownButtonZeroCoin
          targetId="content"
          additionalClasses="z-50" // Ensure it's above other elements
          onClick={() => setScrollDownClicked(true)}
        />
      )}

      {/* Content Section */}
      <div id="content" className="px-10 py-20 mx-auto max-w-7xl">
        {/* Introduction */}
        <div
          className="mb-10 text-center"
          ref={(el) => (sectionRefs.current[0] = el)}
        >
          <h2 className="text-3xl font-bold text-white">
            Explore our native Coin on the Zero-Blockchain
          </h2>
          <p className="mt-3 text-sm text-white-900">
            Discover our Coin, creating our Zero-Blockchain, ecosystem and banking products, driving blockchain forward.
          </p>
        </div>

        {/* Zero-Coin Details Section */}
        <div
          id="zero-coin-details"
          className="w-full max-w-6xl bg-gray-900 border-2 border-gray-300 rounded-lg shadow-lg p-10 mb-10 relative"
          ref={(el) => (sectionRefs.current[1] = el)}
        >
          <div className="text-center">
            <h2 className="text-3xl font-bold mb-5 font-zentry">About Zero-Coin</h2>
            <p className="text-white-300 mb-5">
              Zero-Coin is the native cryptocurrency of Zero-Blockchain.xyz, developed by Zero Bank. It serves as the foundation for all transactions, staking, and governance within our ecosystem.
            </p>
            <img
              src="/assets/images/zero-coin-feature.gif"
              alt="Zero-Coin"
              className="mx-auto w-96 h-96 object-contain p-5 border-2 border-gray-100 rounded-lg"
              draggable="false"
            />
            <div className="mt-5 text-left">
              <p className="text-gray-50">
                <strong>Token Standard:</strong> ZRC-20 Smart Contract
              </p>
              <p className="text-gray-50">
                <strong>Deployment:</strong> Zero-Blockchain Mainnet
              </p>
              <p className="text-gray-50">
                <strong>Contract Address:</strong>{" "}
                <a
                  href="https://etherscan.io/token/0xa47d4023561527a2ec51b216ab06cc5be34f38bd"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-teal-500 underline"
                >
                  0xa47d4023561527a2ec51b216ab06cc5be34f38bd
                </a>
              </p>
              <p className="text-gray-50">
                <strong>Description:</strong> Zero-Coin powers all aspects of Zero-Blockchain.xyz, enabling seamless transactions, staking rewards, and decentralised governance.
              </p>
              <p className="text-gray-50">
                <strong>Price:</strong> £1,000.00
              </p>
            </div>
            {/* Action Buttons */}
              <div className="flex justify-center mt-5 gap-x-4">
              <HeroButton
                onClick={handleMoreDetailsClick}
                ariaLabel="More Details about Zero-Coin"
                additionalClasses="px-4 py-2 bg-blue-500 hover:bg-blue-700 z-30 text-white-50"
              >
                More Details
              </HeroButton>
              <HeroButton
                onClick={handlePurchaseClick}
                ariaLabel="Purchase Zero-Coin"
                additionalClasses="px-4 py-2 bg-green-500 hover:bg-green-700 z-30 text-white-50"
              >
                Purchase
              </HeroButton>
              <HeroButton
                onClick={handleViewOnChainClick}
                ariaLabel="View Zero-Coin on Etherscan"
                additionalClasses="px-4 py-2 bg-teal-500 hover:bg-teal-700 z-30 text-white-50"
              >
                View On Chain
              </HeroButton>             

            </div>
          </div>
        </div>
      </div>

      {/* Back to Top Button */}
      {isBottom && (
        <div
          ref={scrollTopRef}
          className="fixed bottom-10 left-5 flex items-center gap-2 p-2 bg-transparent border border-gray-400 rounded-full shadow-lg cursor-pointer hover:bg-gray-800 transition-all duration-300"
          onClick={scrollToTop}
          aria-label="Back to Top"
        >
          <TiArrowUp
            className="text-gray-400 hover:text-white transition-transform duration-300 hover:scale-110"
            size={24}
          />
          <span className="relative z-20 text-sm uppercase text-gray-400 transition-colors duration-300 hover:text-white">
            BACK TO TOP
          </span>
        </div>
      )}

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default ZeroCoin;
