import React, { useEffect, useState } from "react";
import { TiArrowUp } from "react-icons/ti";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Footer from "./Footer";

gsap.registerPlugin(ScrollTrigger);

const Dashboard = () => {
  const [isBottom, setIsBottom] = useState(false);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    // GSAP Animations for sections
    const sections = document.querySelectorAll(".dashboard-section");
    sections.forEach((section, index) => {
      gsap.fromTo(
        section,
        { opacity: 0, y: 50 },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          delay: index * 0.2,
          scrollTrigger: {
            trigger: section,
            start: "top 80%",
          },
        }
      );
    });
  }, []);

  const checkIfBottom = () => {
    if (
      window.innerHeight + window.scrollY >=
      document.body.offsetHeight - 200
    ) {
      setIsBottom(true);
    } else {
      setIsBottom(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    window.addEventListener("scroll", checkIfBottom);
    return () => {
      window.removeEventListener("scroll", checkIfBottom);
    };
  }, []);

  return (
    <div className="w-screen min-h-screen bg-black text-blue-50">
      {/* Hero Section */}
      <div className="relative w-screen h-screen bg-gradient-to-r from-blue-800 via-black to-blue-900 flex items-center justify-center">
        <h1 className="text-4xl font-bold text-center">
          Welcome to Your Dashboard
        </h1>
      </div>

      {/* Dashboard Content */}
      <div className="px-10 py-20 mx-auto max-w-7xl">
        <div className="grid gap-10 md:grid-cols-2 lg:grid-cols-3">
          {/* Section 1: Portfolio Overview */}
          <div className="dashboard-section p-6 bg-black/70 rounded-lg shadow-lg">
            <h3 className="text-xl font-bold">Portfolio Overview</h3>
            <p className="mt-4">
              View the performance of your token holdings, including real-time
              data and insights.
            </p>
            <button className="mt-4 px-4 py-2 bg-blue-500 text-white rounded">
              Go to Portfolio
            </button>
          </div>

          {/* Section 2: Market Data */}
          <div className="dashboard-section p-6 bg-black/70 rounded-lg shadow-lg">
            <h3 className="text-xl font-bold">Market Data</h3>
            <p className="mt-4">
              Get live updates on the market trends, token prices, and trading
              volumes.
            </p>
            <button className="mt-4 px-4 py-2 bg-blue-500 text-white rounded">
              Explore Market
            </button>
          </div>

          {/* Section 3: Wallet Management */}
          <div className="dashboard-section p-6 bg-black/70 rounded-lg shadow-lg">
            <h3 className="text-xl font-bold">Wallet Management</h3>
            <p className="mt-4">
              Manage your wallets securely and track your transaction history.
            </p>
            <button className="mt-4 px-4 py-2 bg-blue-500 text-white rounded">
              Manage Wallet
            </button>
          </div>

          {/* Section 4: Investments */}
          <div className="dashboard-section p-6 bg-black/70 rounded-lg shadow-lg">
            <h3 className="text-xl font-bold">Investments</h3>
            <p className="mt-4">
              Discover new investment opportunities and track your earnings.
            </p>
            <button className="mt-4 px-4 py-2 bg-blue-500 text-white rounded">
              View Investments
            </button>
          </div>

          {/* Section 5: Compliance */}
          <div className="dashboard-section p-6 bg-black/70 rounded-lg shadow-lg">
            <h3 className="text-xl font-bold">Compliance</h3>
            <p className="mt-4">
              Access your compliance status and ensure your account meets
              regulations.
            </p>
            <button className="mt-4 px-4 py-2 bg-blue-500 text-white rounded">
              View Compliance
            </button>
          </div>

          {/* Section 6: NFT Marketplace */}
          <div className="dashboard-section p-6 bg-black/70 rounded-lg shadow-lg">
            <h3 className="text-xl font-bold">NFT Marketplace</h3>
            <p className="mt-4">
              Explore, buy, and sell NFTs directly from the ZeroARTNFT platform.
            </p>
            <button className="mt-4 px-4 py-2 bg-blue-500 text-white rounded">
              Go to Marketplace
            </button>
          </div>
        </div>
      </div>

      {/* Back to Top Button */}
      {isBottom && (
        <div
          className="fixed bottom-10 left-5 flex items-center gap-2 p-2 bg-transparent border border-gray-400 rounded-full shadow-lg cursor-pointer hover:bg-gray-800 transition-all duration-300"
          onClick={scrollToTop}
        >
          <TiArrowUp
            size={24}
            className="text-white/40 hover:text-white transition-transform duration-300"
          />
          <span className="text-white/40 hover:text-white">BACK TO TOP</span>
        </div>
      )}

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default Dashboard;
