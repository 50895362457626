import React from "react";
import Hero from "./Hero";
import Highlights from "./Highlights";
import Model from "./Model";
import Features from "./Features";
import HowItWorks from "./HowItWorks";
import Footer from "./Footer";
import { hightlightsSlides } from "../constants";

const Home = () => {
  return (
    <>
      <Hero />
      <Highlights slides={hightlightsSlides} />
      <Model />
      <Features />
      <HowItWorks />
      <Footer />
    </>
  );
};

export default Home;
