export const CoreFeatures = [
    {
      title: "Seamless Payment Integration",
      description:
        "Integrates with global payment gateways like PayPal, Stripe, and local payment processors to support payments across multiple channels, including e-commerce, POS, and mobile payments.",
      image: "/assets/images/zero-flow/seamless-integration.webp",
    },
    {
      title: "Multi-Currency & Cross-Border Payments",
      description:
        "Enables real-time currency conversion and cross-border payments, offering businesses the ability to operate globally while reducing fees and settlement times.",
      image: "/assets/images/zero-flow/cross-border-payments.webp",
    },
    {
      title: "Automation & Smart Payment Routing",
      description:
        "Automatically routes payments via the most cost-effective and efficient channels, enabling recurring payments and subscription-based billing models.",
      image: "/assets/images/zero-flow/automation-smart-routing.webp",
    },
    {
      title: "Compliance & Security",
      description:
        "Ensures compliance with KYC/AML regulations, encrypts payment data, and adheres to PCI-DSS standards to protect customer information and payment security.",
      image: "/assets/images/zero-flow/security-compliance.webp",
    },
    {
      title: "Real-Time Transaction Insights",
      description:
        "Access real-time dashboards to track transaction performance, identify trends, and gain key financial insights that inform decision-making and strategy development.",
      image: "/assets/images/zero-flow/realtime-insights.webp",
    },
    {
      title: "Developer-Friendly API & SDKs",
      description:
        "Offers a robust API and SDKs with comprehensive documentation, allowing developers to easily integrate payment features into websites, mobile apps, and platforms.",
      image: "/assets/images/zero-flow/developer-friendly-api.webp",
    },
  ];
  
  export const PaymentCapabilities = [
    {
      title: "Multi-Payment Gateway Support",
      description:
        "Integrate with multiple global payment gateways, enabling customers to pay with their preferred payment method.",
      image: "/assets/images/zero-flow/payment-gateway-support.webp",
    },
    {
      title: "Recurring Payments",
      description:
        "Facilitates automated subscription billing for SaaS, memberships, and subscription-based business models.",
      image: "/assets/images/zero-flow/recurring-payments.webp",
    },
    {
      title: "Real-Time Currency Conversion",
      description:
        "Convert currencies in real time, allowing businesses to accept payments in different currencies and minimize exchange fees.",
      image: "/assets/images/zero-flow/currency-conversion.webp",
    },
    {
      title: "Cross-Border Payments",
      description:
        "Handle cross-border payments without the high fees and delays associated with SWIFT transfers, enabling frictionless international transactions.",
      image: "/assets/images/zero-flow/cross-border-transactions.webp",
    },
    {
      title: "Payment Tokenisation",
      description:
        "Securely store customer payment information using tokenisation, enhancing security and simplifying repeat transactions.",
      image: "/assets/images/zero-flow/payment-tokenisation.webp",
    },
    {
      title: "One-Click Payments",
      description:
        "Allow customers to make purchases quickly with saved payment details, improving user experience and increasing conversion rates.",
      image: "/assets/images/zero-flow/one-click-payments.webp",
    },
  ];
  
export const TechnicalCapabilities = [
    {
      title: "Microservices Architecture",
      description:
        "Leverages a microservices architecture, enabling scalability, modular development, and continuous updates with minimal downtime.",
      image: "/assets/images/zero-flow/microservices-architecture.webp",
    },
    {
      title: "Cloud-Native Deployment",
      description:
        "Cloud-native design ensures fast, resilient deployments on platforms like AWS, Azure, and Google Cloud for high availability and disaster recovery.",
      image: "/assets/images/zero-flow/cloud-native-design.webp",
    },
    {
      title: "Containerisation",
      description:
        "Uses containerisation technology (like Docker) to streamline deployments, ensure consistency, and reduce environment-specific errors.",
      image: "/assets/images/zero-flow/containerisation.webp",
    },
    {
      title: "Message Queueing",
      description:
        "Utilises message queues like RabbitMQ to handle real-time payment notifications, ensuring fast and asynchronous communication.",
      image: "/assets/images/zero-flow/message-queueing.webp",
    },
    {
      title: "Scalable Database Solutions",
      description:
        "Implements scalable databases such as PostgreSQL and MongoDB to manage large volumes of transaction data efficiently.",
      image: "/assets/images/zero-flow/scalable-databases.webp",
    },
    {
      title: "API Rate Limiting & Throttling",
      description:
        "Ensures optimal performance and security by managing API request rates and preventing abuse through rate limiting and throttling mechanisms.",
      image: "/assets/images/zero-flow/api-rate-limiting.webp",
    },
  ];
  
export const SecurityAndCompliance = [
    {
      title: "PCI-DSS Certification",
      description:
        "Complies with PCI-DSS standards, ensuring that cardholder data is stored, processed, and transmitted securely.",
      image: "/assets/images/zero-flow/pci-dss.webp",
    },
    {
      title: "Data Encryption",
      description:
        "Utilises advanced encryption protocols to secure payment data both in transit and at rest, mitigating the risk of data breaches.",
      image: "/assets/images/zero-flow/data-encryption.webp",
    },
    {
      title: "KYC/AML Compliance",
      description:
        "Supports Know Your Customer (KYC) and Anti-Money Laundering (AML) protocols, ensuring user verification and transaction monitoring.",
      image: "/assets/images/zero-flow/kyc-aml-compliance.webp",
    },
    {
      title: "Fraud Detection & Prevention",
      description:
        "Implements real-time fraud detection, using AI and data-driven algorithms to block suspicious payment activities.",
      image: "/assets/images/zero-flow/fraud-detection.webp",
    },
    {
      title: "Secure Data Storage",
      description:
        "Ensures all sensitive data is stored in secure environments with regular security audits and compliance checks.",
      image: "/assets/images/zero-flow/secure-data-storage.webp",
    },
    {
      title: "Access Control & Monitoring",
      description:
        "Implements strict access controls and continuous monitoring to prevent unauthorized access and ensure system integrity.",
      image: "/assets/images/zero-flow/access-control.webp",
    },
  ];
  
  export const AnalyticsAndInsights = [
    {
      title: "Real-Time Dashboard",
      description:
        "Provides a live dashboard that tracks payment activity, revenue, refund rates, and customer payment behaviour in real time.",
      image: "/assets/images/zero-flow/realtime-dashboard.webp",
    },
    {
      title: "Customizable Reports",
      description:
        "Generate detailed reports for transaction history, settlement reconciliation, and chargeback analysis.",
      image: "/assets/images/zero-flow/custom-reports.webp",
    },
    {
      title: "Revenue Insights",
      description:
        "Gain visibility into business performance, with insights into revenue streams, growth patterns, and payment success rates.",
      image: "/assets/images/zero-flow/revenue-insights.webp",
    },
    {
      title: "Refund & Chargeback Monitoring",
      description:
        "Monitor refund requests and chargeback activity to identify patterns, reduce risk, and improve dispute resolution strategies.",
      image: "/assets/images/zero-flow/chargeback-monitoring.webp",
    },
    {
      title: "Customer Segmentation",
      description:
        "Analyze customer data to segment users based on payment behavior, preferences, and transaction history for targeted marketing.",
      image: "/assets/images/zero-flow/customer-segmentation.webp",
    },
    {
      title: "Predictive Analytics",
      description:
        "Utilise machine learning to forecast trends, identify potential issues before they arise, and make data-driven strategic decisions.",
      image: "/assets/images/zero-flow/predictive-analytics.webp",
    },
  ];
  
export const UseCases = [
    {
      title: "E-commerce Payments",
      description:
        "Enable e-commerce stores to accept online payments, offering a seamless, secure, and fast checkout experience.",
      image: "/assets/images/zero-flow/ecommerce-payments.webp",
    },
    {
      title: "Subscription Billing",
      description:
        "Support subscription-based business models by automating billing cycles and managing recurring payments for customers.",
      image: "/assets/images/zero-flow/subscription-billing.webp",
    },
    {
      title: "International Money Transfers",
      description:
        "Provide international freelancers and contractors with faster, more affordable cross-border payment solutions.",
      image: "/assets/images/zero-flow/international-payments.webp",
    },
    {
      title: "Marketplace Payments",
      description:
        "Enable payouts for marketplace vendors with split payments, escrow, and automated settlements.",
      image: "/assets/images/zero-flow/marketplace-payments.webp",
    },
    {
      title: "Mobile Payments",
      description:
        "Facilitate payments through mobile devices, offering a convenient and secure option for on-the-go transactions.",
      image: "/assets/images/zero-flow/mobile-payments.webp",
    },
    {
      title: "Point of Sale (POS) Systems",
      description:
        "Integrate with POS systems to streamline in-store transactions, inventory management, and sales reporting.",
      image: "/assets/images/zero-flow/pos-systems.webp",
    },
  ];
  
export const DeveloperTools = [
    {
      title: "API-First Approach",
      description:
        "Zero-Flow's API-first design allows developers to build and customize payment workflows, integrate payment processing, and more.",
      image: "/assets/images/zero-flow/api-first-approach.webp",
    },
    {
      title: "Webhook Support",
      description:
        "Developers can use webhooks to receive updates on payment events like settlements, payment status, and chargeback notifications.",
      image: "/assets/images/zero-flow/webhook-support.webp",
    },
    {
      title: "Custom Checkout",
      description:
        "Developers can create customized checkout pages with full control over branding, payment flow, and user experience.",
      image: "/assets/images/zero-flow/custom-checkout.webp",
    },
    {
      title: "Extensive API Documentation",
      description:
        "Access detailed API documentation, including integration guides and best practices for connecting to the Zero-Flow payment platform.",
      image: "/assets/images/zero-flow/api-docs.webp",
    },
    {
      title: "SDK Libraries",
      description:
        "Utilise SDKs available for multiple programming languages to accelerate development and ensure seamless integration.",
      image: "/assets/images/zero-flow/sdk-libraries.webp",
    },
    {
      title: "Sandbox Environment",
      description:
        "Test integrations in a safe sandbox environment before going live, ensuring functionality and reliability without impacting real transactions.",
      image: "/assets/images/zero-flow/sandbox-environment.webp",
    },
  ];