// src/components/Layout.jsx

import React from 'react';
import Navbar from './Navbar';

const Layout = ({ children }) => {
  return (
    <>
      <Navbar />
      <main className="bg-black pt-20"> {/* 7rem padding-top */}
        {children}
      </main>
    </>
  );
};

export default Layout;
