import { Link } from "react-router-dom";
import { TiLocationArrow } from "react-icons/ti";
import { useEffect, useRef } from "react";
import { gsap } from "gsap";

const BackToProductsButton = () => {
  const buttonRef = useRef(null);

  useEffect(() => {
    if (buttonRef.current) {
      // Initial entrance animation
      gsap.fromTo(
        buttonRef.current,
        { opacity: 0, y: 20 },
        { opacity: 1, y: 0, duration: 1, ease: "power3.out" }
      );

      // Glow effect on hover
      gsap.to(buttonRef.current, {
        boxShadow: "0px 0px 20px rgba(0, 255, 255, 0.8)",
        repeat: -1,
        yoyo: true,
        paused: true,
        duration: 1,
        ease: "power3.inOut",
        scrollTrigger: {
          trigger: buttonRef.current,
        },
      });
    }
  }, []);

  const handleMouseEnter = () => {
    gsap.to(buttonRef.current, {
      boxShadow: "0 0 15px rgba(0, 255, 255, 1)",
      scale: 1.05,
      duration: 0.3,
      ease: "power3.out",
    });
  };

  const handleMouseLeave = () => {
    gsap.to(buttonRef.current, {
      boxShadow: "0 0 0 rgba(0, 255, 255, 0)",
      scale: 1,
      duration: 0.3,
      ease: "power3.out",
    });
  };

  return (
    <Link
      ref={buttonRef}
      to="/products"
      aria-label="Go back to products section"
      className="mt-8 px-6 py-3 border border-gray-200 bg-transparent hover:bg-teal-500 hover:text-gray-800 text-gray-100 z-30 flex items-center rounded-md transition-all duration-300 ease-in-out"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <span className="flex items-center">
        Back to Products
        <TiLocationArrow className="ml-2 text-xl" />
      </span>
    </Link>
  );
};

export default BackToProductsButton;
