// src/components/ScrollDownButtonZeroCoin.jsx

import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { TiArrowDown } from "react-icons/ti";

const ScrollDownButtonZeroCoin = forwardRef(({ targetId, onClick, additionalClasses }, ref) => {
  const handleClick = () => {
    console.log("ScrollDownButtonZeroCoin clicked"); // Debugging statement

    const targetElement = document.getElementById(targetId);
    if (targetElement) {
      console.log("Found target element:", targetElement); // Debugging statement
      targetElement.scrollIntoView({ behavior: "smooth", block: "start" });
    } else {
      console.log(`Element with id "${targetId}" not found`); // Debugging statement
    }

    if (onClick) {
      onClick();
    }
  };

  return (
    <div
      ref={ref}
      className={`fixed bottom-10 left-1/2 transform -translate-x-1/2 flex items-center gap-2 p-2 bg-transparent border border-gray-400 rounded-full shadow-lg cursor-pointer hover:bg-gray-800 transition-all duration-300 z-50 ${additionalClasses}`}
      onClick={handleClick}
      role="button"
      aria-label="Scroll down to content"
      tabIndex={0}
      onKeyPress={(e) => {
        if (e.key === "Enter" || e.key === " ") {
          handleClick();
        }
      }}
    >
      <TiArrowDown
        className="text-white/40 transition-transform duration-300 hover:scale-110 hover:text-white"
        size={24}
      />
      <span className="relative z-20 text-sm uppercase text-white/40 transition-colors duration-300 hover:text-white">
        SCROLL DOWN
      </span>
    </div>
  );
});

ScrollDownButtonZeroCoin.propTypes = {
  targetId: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  additionalClasses: PropTypes.string,
};

ScrollDownButtonZeroCoin.defaultProps = {
  onClick: () => {},
  additionalClasses: "",
};

export default ScrollDownButtonZeroCoin;
