import React, { useState, useRef, useEffect } from 'react';
import ModelView from './ModelView';
import { Canvas } from "@react-three/fiber";
import { View } from "@react-three/drei";
import { models, sizes } from "../constants";
import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import * as THREE from 'three';
import { animateWithGsapTimeline } from "../utils/animations";

const tokenNames = [
  'GECKO', 'TIGER', 'RHINO', 'STEGO', 'CHEETO', 'BISON', 'DINO', 'MOA', 'WOOLY', 'SABRE',
  'QUAGGA', 'PANDA', 'PTERAN', 'THYLAC', 'AUROCH', 'IVORY', 'PANG', 'CONDOR', 'BAIJI', 'GLYPTO',
  'MEGAL', 'DIRE', 'QUETZAL', 'LYNX', 'SAURO', 'OKAPI', 'KAKAPO', 'VAQUITA', 'GORILLA', 'RED WOLF',
  'TORTOISE', 'OWL', 'MANATEE', 'GIBBON', 'RIVER', 'AMUR', 'ADDAX', 'TUATARA', 'IBIS', 'ELEPHANT',
  'LEMUR', 'DODO', 'HARPY', 'WOLF', 'SUN BEAR', 'ORCA', 'PIKA', 'SHARK', 'MYNAH', 'CAMEL'
];

const Model = () => {
  const [size, setSize] = useState('size1'); // Default size
  const [model, setModel] = useState({    
  });

  const [activeIndex, setActiveIndex] = useState(0); // Default to Zero-Coin

  // camera control for the model view
  const cameraControlSmall = useRef();
  const cameraControlLarge = useRef();

  // model
  const small = useRef(new THREE.Group());
  const large = useRef(new THREE.Group());

  // rotation
  const [smallRotation, setSmallRotation] = useState(0);
  const [largeRotation, setLargeRotation] = useState(0);

  const tl = gsap.timeline();

  useEffect(() => {
    if (size.startsWith('size')) {
      animateWithGsapTimeline(tl, large, largeRotation, '#view1', '#view2', '#view3', {
        transform: 'translateX(-100%)',
        duration: 2
      });
    } else {
      animateWithGsapTimeline(tl, small, smallRotation, '#view3', '#view2', '#view1', {
        transform: 'translateX(0)',
        duration: 2
      });
    }
  }, [size]);

  useGSAP(() => {
    gsap.to('#heading', { y: 0, opacity: 1 });
  }, []);

  const handleTokenClick = (index) => {
    setActiveIndex(index);
  };

  return (
    <section id="modelViewSection" className="common-padding">
      <div className="screen-max-width flex">
        {/* Title moved to the left */}
        <h1 id="heading" className="section-heading" style={{ transform: 'translateX(-275px)' }}>
          <p>Explore Our Coins</p>
          <p>& Tokens</p>
        </h1>
        
        <div className="flex w-full">
          <div className="w-3/5">
            <div className="w-full h-[65vh] md:h-[80vh] overflow-hidden relative mb-2">
              <ModelView
                activeIndex={activeIndex}
                groupRef={large}
                gsapType="view"
                controlRef={cameraControlLarge}
                setRotationState={setSmallRotation}
                item={model}
                size={size}
              />
              <Canvas
                className="w-full h-full"
                style={{
                  position: 'fixed',
                  top: -25,
                  bottom: -25,
                  left: 0,
                  right: 0,
                  overflow: 'hidden'
                }}
                eventSource={document.getElementById('root')}
              >
                <View.Port />
              </Canvas>
            </div>

            <div className="mx-auto w-full">
              <p
                className="text-lg font-bold text-center mb-2 -mt-20"
                style={{ marginLeft: '-195px' }}
              >
                native to Zero-blockchain.xyz
              </p>
              <div className="flex justify-center mt-5 mb-5">
                <span
                  className="token-btn"
                  style={{
                    backgroundColor: activeIndex === 0 ? 'white' : 'transparent',
                    color: activeIndex === 0 ? 'black' : 'white',
                    fontSize: '1.2rem',
                    padding: '10px 20px',
                    marginBottom: '10px',
                    marginLeft: '-192px',
                  }}
                  onClick={() => handleTokenClick(0)}
                >
                  Zero-Coin
                </span>
              </div>
              <p className="text-sm font-light text-center mb-5 mt-0">{model.title}</p>
            </div>
          </div>
          
          {/* Adjusted token buttons to a 5x10 grid */}
            <div className="w-full md:w-1/4 flex flex-col items-center">
              <h2 className="text-m font-bold text-center mb-20" style={{ marginRight: '-400px' }}>Zero-bank ERC-20 Tokens</h2> {/* Adjusted title */}
              <div className="token-btn-container grid grid-cols-5 gap-5 p-1"style={{ marginRight: '-250px' }}>
                {tokenNames.map((label, index) => (
                  <span
                    key={index}
                    className="token-btn"
                    style={{
                      backgroundColor: activeIndex === index + 1 ? 'white' : 'transparent',
                      color: activeIndex === index + 1 ? 'black' : 'white',
                      marginBottom: '-10px', // Add some spacing between buttons                      
                    }}
                    onClick={() => handleTokenClick(index + 1)}
                  >
                    {label}
                  </span>
                ))}
              </div>
            </div>
          </div>
        </div>      
    </section>
  );
};

export default Model;
