import React, { useEffect, useState, useRef } from "react";
import AnimatedTitle from "../components/AnimatedTitle";
import { TiArrowDown, TiArrowUp } from "react-icons/ti";
import BackToProductsButton from "../components/BackToProductsButton";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Footer from "../components/Footer";
import LearnMoreButton from "../components/LearnMoreButton";
import ZeroWaveLearnMoreCustomModal from "../products-pages/5-ZeroWaveLearnMoreCustomModal";

import { 
  CoreFeatureDetails,
  UserExperienceEnhancementsFeaturesDetails,
  TechnicalCapabilitiesFeaturesDetails,  
  SecurityAndComplianceFeaturesDetails,
  UserJourneyFeaturesDetails
} from "../data/5-ZeroWave/5-ZeroWaveDeepFeaturesData";

import {
  CoreFeatures,
  UserExperienceEnhancements,
  TechnicalCapabilities,  
  SecurityAndCompliance,
  UserJourney
} from "../data/5-ZeroWave/5-ZeroWaveFeaturesData"; 

gsap.registerPlugin(ScrollTrigger);

const ZeroWave = () => {
  const [scrollDownClicked, setScrollDownClicked] = useState(false);
  const [isBottom, setIsBottom] = useState(false);

  const coreFeaturesRefs = useRef([]);
  const scrollDownRef = useRef(null);
  const scrollTopRef = useRef(null);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    
    const batchSize = 3;
    for (let i = 0; i < coreFeaturesRefs.current.length; i += batchSize) {
      const batch = coreFeaturesRefs.current.slice(i, i + batchSize).filter(Boolean);
      if (batch.length > 0) {
        gsap.fromTo(
          batch,
          { opacity: 0, y: 50 },
          {
            opacity: 1,
            y: 0,
            duration: 1,
            stagger: 0.15,
            delay: (i / batchSize) * 1.5,
            ease: "power3.out",
            scrollTrigger: {
              trigger: batch[0],
              start: "top 90%",
              toggleActions: "play none none none",
            },
          }
        );
      }
    }

    if (scrollDownRef.current) {
      gsap.fromTo(
        scrollDownRef.current,
        { opacity: 0, y: -20 },
        { opacity: 1, y: 0, duration: 1 }
      );
    }

    if (scrollTopRef.current) {
      gsap.fromTo(
        scrollTopRef.current,
        { opacity: 0, y: 20 },
        {
          opacity: 1,
          y: 0,
          duration: 0.5,
          scrollTrigger: {
            trigger: scrollTopRef.current,
            start: "top bottom",
          },
        }
      );
    }
  }, []);

  const checkIfBottom = () => {
    if (
      window.innerHeight + window.scrollY >=
      document.body.offsetHeight - 200
    ) {
      setIsBottom(true);
    } else {
      setIsBottom(false);
    }
  };

  const scrollToContent = () => {
    const content = document.getElementById("content");
    if (content) {
      const contentOffsetTop = content.offsetTop;
      window.scrollTo({
        top: contentOffsetTop - 100,
        behavior: "smooth",
      });
    }
    setScrollDownClicked(true);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setScrollDownClicked(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", checkIfBottom);
    return () => {
      window.removeEventListener("scroll", checkIfBottom);
    };
  }, []);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalDescription, setModalDescription] = useState('');
  const [modalImage, setModalImage] = useState('');

  const openModal = (title) => {
    const featureDetails = 
      CoreFeatureDetails[title] || 
      TechnicalCapabilitiesFeaturesDetails[title] || 
      SecurityAndComplianceFeaturesDetails[title] || 
      UserJourneyFeaturesDetails[title] || 
      UserExperienceEnhancementsFeaturesDetails[title] || 
      CoreFeatureDetails["Default"];
    
    setModalTitle(featureDetails.title);
    setModalDescription(featureDetails.description);
    setModalImage(featureDetails.image);
    setIsModalOpen(true);
  };
  
  const closeModal = () => {
    setIsModalOpen(false);
    setModalTitle('');
    setModalDescription('');
    setModalImage('');
  };

  return (
    <div className="w-screen min-h-screen bg-black text-blue-50">
      {/* Hero Section */}
      <div className="relative w-screen h-screen overflow-hidden">
        <video
          src="/videos/zero-wave-hero.mp4"
          autoPlay
          loop
          muted
          className="absolute left-0 top-0 w-full h-full object-cover"
        />
        <div className="absolute inset-0 flex flex-col items-center justify-center bg-black/60">
          <AnimatedTitle
            title="Zero-Wave"
            containerClass="text-center text-blue-50 font-zentry"
          />
        </div>
      </div>

      {/* Scroll Down Button */}
      {!scrollDownClicked && (
        <div
          ref={scrollDownRef}
          className="fixed bottom-10 left-1/2 transform -translate-x-1/2 flex items-center gap-2 p-2 bg-transparent border border-gray-400 rounded-full shadow-lg cursor-pointer hover:bg-gray-800 transition-all duration-300"
          onClick={scrollToContent}
        >
          <TiArrowDown size={24} className="text-white/40 hover:text-white" />
          <span className="text-white/40 hover:text-white">SCROLL DOWN</span>
        </div>
      )}

      {/* Content Section */}
      <div id="content" className="px-10 py-20 mx-auto max-w-7xl">
        {/* Introduction */}
        <div className="mb-10 text-center">
          <h2 className="text-4xl font-extrabold text-teal-600">Payment, Wallet, and Financial Asset Management</h2>
          <p className="mt-4 text-lg text-gray-300">
            Experience the next generation of payment solutions with Zero-Wave.
          </p>
        </div>


        {/* Core Features */}
        <div id="content" className="px-10 py-20 mx-auto max-w-7xl">
        <section className="px-4 py-8 bg-black">
          <div className="mb-8 text-center">
            <h2 className="text-3xl md:text-4xl font-extrabold text-white">Core Features</h2>
            <p className="mt-2 text-lg md:text-xl text-gray-400">
              Discover the key functionalities that make Zero-Wave your ultimate payment, wallet, and financial asset management solution.
            </p>
            <div className="w-16 h-1 bg-gray-500 mx-auto mt-4 rounded-full"></div>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
            {CoreFeatures.map((item, index) => (
              <div
                key={index}
                className="relative flex flex-col p-6 bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 rounded-xl shadow-xl border border-gray-700 overflow-hidden group"
              >
                <div
                  className="absolute inset-0 z-0 bg-cover bg-center opacity-20"
                  style={{ backgroundImage: `url(${item.image})` }}
                ></div>
                <div className="relative z-10 flex flex-col flex-grow">
                  <h3 className="text-xl font-extrabold text-white mb-2">{item.title}</h3>
                  <p className="mt-2 text-sm text-gray-300 flex-grow mb-6">{item.description}</p>
                  <div className="mt-auto self-start">
                  <LearnMoreButton 
                  productId={index} 
                  onClick={() => openModal(item.title)} 
                />
                
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>
      </div>

        {/* User Experience Enhancements Section */}
        <section className="px-4 py-8 bg-black">
          <div className="mb-8 text-center">
            <h2 className="text-3xl md:text-4xl font-extrabold text-white">
            User Experience Enhancements
            </h2>
            <p className="mt-2 text-lg md:text-xl text-gray-400">
            Streamlined Payments, Integrated Wallets, and Complete Financial Mastery in Your Hands
            </p>
            <div className="w-16 h-1 bg-gray-500 mx-auto mt-4 rounded-full"></div>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
            {UserExperienceEnhancements.map((item, index) => (
              <div
                key={index}
                className="relative flex flex-col p-6 bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 rounded-xl shadow-xl border border-gray-700 overflow-hidden group"
              >
                <div
                  className="absolute inset-0 z-0 bg-cover bg-center opacity-20"
                  style={{ backgroundImage: `url(${item.image})` }}
                ></div>

                <div className="relative z-10 flex flex-col flex-grow">
                  <h3 className="text-xl font-extrabold text-white mb-2">{item.title}</h3>

                  <p className="mt-2 text-sm text-gray-300 flex-grow mb-6">{item.description}</p>

                  <div className="mt-auto self-start">
                    <LearnMoreButton
                      productId={index}
                      onClick={() => openModal(item.title, item.description)}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>

        <br />

        {/* Highlights Section */}
        <section className="mt-20 space-y-16">
        {/* First Highlight */}
        <div className="flex flex-col-reverse items-center gap-6 md:gap-10 md:flex-row">
          <div className="w-full md:w-1/2">
            <img
              src="/assets/images/zero-wave/instant-payments.webp"
              alt="Instant Payments"
              className="rounded-lg shadow-lg transform transition-transform duration-300 hover:scale-105"
            />
          </div>
          <div className="w-full md:w-1/2">
            <h3 className="text-3xl md:text-4xl font-extrabold text-white">Instant Payments</h3>
            <p className="mt-4">
              Send and receive payments instantly, anytime, anywhere, with Zero-Wave's ultra-fast cross-border processing.
            </p>
          </div>
        </div>

        {/* Second Highlight */}
        <div className="flex flex-col-reverse items-center gap-6 md:gap-10 md:flex-row-reverse">
          <div className="w-full md:w-1/2">
            <img
              src="/assets/images/zero-wave/secure-wallet.webp"
              alt="Secure Wallet"
              className="rounded-lg shadow-lg transform transition-transform duration-300 hover:scale-105"
            />
          </div>
          <div className="w-full md:w-1/2">
            <h3 className="text-3xl md:text-4xl font-extrabold text-white">Secure Wallet</h3>
            <p className="mt-4">
              Protect your assets with state-of-the-art security, including biometric authentication, end-to-end encryption, and fraud detection.
            </p>
          </div>
        </div>
        </section>

        <br /><br /><br />   
        
        {/* Security & Compliance */}
        <section className="px-4 py-8 bg-black">
        {/* Title Container */}
        <div className="mb-8 text-center">
          <h2 className="text-3xl md:text-4xl font-extrabold text-white">
            Security & Compliance
          </h2>
          <p className="mt-2 text-lg md:text-xl text-gray-400">
            Protect your financial transactions with top-tier security measures and full regulatory compliance, ensuring your assets remain safe and secure.
          </p>
        {/* Optional Divider */}
        <div className="w-16 h-1 bg-gray-500 mx-auto mt-4 rounded-full"></div>
        </div>

        {/* Features Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
          {SecurityAndCompliance.map((item, index) => (
            <div
              key={index}
              className="relative flex flex-col p-6 bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 rounded-xl shadow-xl border border-gray-700 overflow-hidden group"
            >
              {/* Background Image */}
              <div
                className="absolute inset-0 z-0 bg-cover bg-center opacity-20"
                style={{ backgroundImage: `url(${item.image})` }}
              ></div>

              {/* Content Container */}
              <div className="relative z-10 flex flex-col flex-grow">
                <h3 className="text-xl font-extrabold text-white mb-2">{item.title}</h3>

                {/* Description with consistent space below */}
                <p className="mt-2 text-sm text-gray-300 flex-grow mb-6">{item.description}</p>

                {/* Learn More Button Positioned at Bottom Left */}
                <div className="mt-auto self-start">
                  <LearnMoreButton 
                  productId={index} 
                  onClick={() => openModal(item.title)} 
                  />              
                </div>
              </div>
            </div>
          ))}
        </div>
        </section>
        <br />

        {/* Technical Capabilities */}
        <section className="px-4 py-8 bg-black">
        {/* Title Container */}
        <div className="mb-8 text-center">
          <h2 className="text-3xl md:text-4xl font-extrabold text-white">
            Technical Capabilities
          </h2>
          <p className="mt-2 text-lg md:text-xl text-gray-400">
            Explore the advanced technical infrastructure powering Zero-Wave's payment, wallet, and multi-currency management platform. Built for speed, security, and seamless integration.
          </p>
          {/* Optional Divider */}
          <div className="w-16 h-1 bg-gray-500 mx-auto mt-4 rounded-full"></div>
        </div>

        {/* Features Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
          {TechnicalCapabilities.map((item, index) => (
            <div
              key={index}
              className="relative flex flex-col p-6 bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 rounded-xl shadow-xl border border-gray-700 overflow-hidden group"
            >
              {/* Background Image */}
              <div
                className="absolute inset-0 z-0 bg-cover bg-center opacity-20"
                style={{ backgroundImage: `url(${item.image})` }}
              ></div>

              {/* Content Container */}
              <div className="relative z-10 flex flex-col flex-grow">
                <h3 className="text-xl font-extrabold text-white mb-2">{item.title}</h3>
                
                {/* Description with consistent space below */}
                <p className="mt-2 text-sm text-gray-300 flex-grow mb-6">{item.description}</p>

                {/* Learn More Button Positioned at Bottom Left */}
                <div className="mt-auto self-start">
                  <LearnMoreButton
                    productId={index}
                    onClick={() => openModal(item.title, item.description)}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
        </section>


        <br />

        {/* User Journey */}
        <section className="px-4 py-8 bg-black">
        {/* Title Container */}
        <div className="mb-8 text-center">
          <h2 className="text-3xl md:text-4xl font-extrabold text-white">
            User Journey
          </h2>
          <p className="mt-2 text-lg md:text-xl text-gray-400">
            Seamless onboarding, wallet integration, and user-centric design guide you through a smooth and efficient Zero-Wave experience.
          </p>
        {/* Optional Divider */}
        <div className="w-16 h-1 bg-gray-500 mx-auto mt-4 rounded-full"></div>
        </div>

        {/* Features Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
          {UserJourney.map((item, index) => (
            <div
              key={index}
              className="relative flex flex-col p-6 bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900 rounded-xl shadow-xl border border-gray-700 overflow-hidden group"
            >
              {/* Background Image */}
              <div
                className="absolute inset-0 z-0 bg-cover bg-center opacity-20"
                style={{ backgroundImage: `url(${item.image})` }}
              ></div>

              {/* Content Container */}
              <div className="relative z-10 flex flex-col flex-grow">
                <h3 className="text-xl font-extrabold text-white mb-2">{item.title}</h3>

                {/* Description with consistent space below */}
                <p className="mt-2 text-sm text-gray-300 flex-grow mb-6">{item.description}</p>

                {/* Learn More Button Positioned at Bottom Left */}
                <div className="mt-auto self-start">
                  <LearnMoreButton 
                  productId={index} 
                  onClick={() => openModal(item.title)} 
                  />              
                </div>
              </div>
            </div>
          ))}
        </div>
        </section>
        <br />
        <br /><br />

        {/* Third Highlight */}
        <div className="flex flex-col-reverse items-center gap-6 md:gap-10 md:flex-row">
        <div className="w-full md:w-1/2">
          <img
            src="/assets/images/zero-wave/multi-currency-support.webp"
            alt="Multi-Currency Support"
            className="rounded-lg shadow-lg transform transition-transform duration-300 hover:scale-105"
          />
        </div>
        <div className="w-full md:w-1/2">
          <h3 className="text-3xl md:text-4xl font-extrabold text-white">Multi-Currency Support</h3>
          <p className="mt-4">
            Manage fiat and crypto in a unified wallet, with seamless currency conversion and support for global transactions.
          </p>
        </div>
      </div>

      {/* Fourth Highlight */}
      <div className="flex flex-col-reverse items-center gap-6 md:gap-10 md:flex-row-reverse">
        <div className="w-full md:w-1/2">
          <img
            src="/assets/images/zero-wave/advanced-analytics.webp"
            alt="Advanced Analytics"
            className="rounded-lg shadow-lg transform transition-transform duration-300 hover:scale-105"
          />
        </div>
        <div className="w-full md:w-1/2">
          <h3 className="text-3xl md:text-4xl font-extrabold text-white">Advanced Analytics</h3>
          <p className="mt-4">
            Gain deep insights into your financial activity with advanced analytics, portfolio tracking, and performance monitoring.
          </p>
        </div>
      </div>

      <br /><br /><br />      

        {/* Back to Products Button */}
        <div className="flex justify-center mt-10">
          <BackToProductsButton />
        </div>
      </div>

      {/* Back to Top Button */}
      {isBottom && (
        <div
          ref={scrollTopRef}
          className="fixed bottom-10 left-5 flex items-center gap-2 p-2 bg-transparent border border-gray-400 rounded-full shadow-lg cursor-pointer hover:bg-gray-800 transition-all duration-300"
          onClick={scrollToTop}
        >
          <TiArrowUp
            size={24}
            className="text-white/40 hover:text-white transition-transform duration-300"
          />
          <span className="text-white/40 hover:text-white">BACK TO TOP</span>
        </div>
      )}

      {/* Footer */}
      <Footer />

      {/* ZeroWaveLearnMoreCustomModal */}
      <ZeroWaveLearnMoreCustomModal 
        isOpen={isModalOpen} 
        onClose={closeModal} 
        title={modalTitle} 
        description={modalDescription} 
        image={modalImage} 
      />
    </div>
  );
};

export default ZeroWave;
