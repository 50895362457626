// src/components/ScrollDownButton.jsx

import React from 'react';
import PropTypes from 'prop-types';
import { TiArrowDown } from 'react-icons/ti';

const ScrollDownButton = React.forwardRef(({ additionalClasses, onClick }, ref) => {

  return (
    
    <button
    ref={ref}
      onClick={onClick}
      className={`fixed bottom-10 left-1/2 transform -translate-x-1/2 flex items-center gap-2 p-2 bg-transparent border border-gray-400 rounded-full shadow-lg cursor-pointer hover:bg-gray-800 transition-all duration-300 ${additionalClasses}`}
      aria-label="Scroll Down"
    >
      <TiArrowDown
        className="text-gray-400 hover:text-white transition-transform duration-300 hover:scale-110"
        size={24}
      />
      <span className="relative z-20 text-sm uppercase text-gray-400 transition-colors duration-300 hover:text-white">
        SCROLL DOWN
      </span>
      </button>
    
  );
});

ScrollDownButton.propTypes = {
  targetId: PropTypes.string.isRequired,
  additionalClasses: PropTypes.string,
};

ScrollDownButton.defaultProps = {
  additionalClasses: '',
};

export default ScrollDownButton;
