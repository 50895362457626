export const CoreFeatures = [
    {
      title: "Mobile Payments",
      description: 
        "Send, receive, and manage payments using fiat or crypto for both online and in-store purchases.",
      image: "/assets/images/zero-wave/mobile-payments.webp",
    },
    {
      title: "Crypto Wallet",
      description: 
        "Store, trade, and manage major cryptocurrencies, ensuring safe and simple access to the world of digital currencies.",
      image: "/assets/images/zero-wave/crypto-wallet.webp",
    },
    {
      title: "Investment Tools",
      description: 
        "Track market data, monitor portfolio performance, and make smarter investment decisions.",
      image: "/assets/images/zero-wave/investment-tools.webp",
    },
    {
      title: "Real-Time Payments",
      description: 
        "Send money instantly to any location worldwide with ultra-fast cross-border payment processing.",
      image: "/assets/images/zero-wave/real-time-payments.webp",
    },
    {
      title: "Multi-Currency Support",
      description: 
        "Manage multiple fiat currencies and cryptocurrencies in one wallet, simplifying financial transactions across the globe.",
      image: "/assets/images/zero-wave/multi-currency-support.webp",
    },
    {
      title: "Advanced Analytics",
      description: 
        "Monitor transaction history, wallet activity, and investment performance with real-time insights.",
      image: "/assets/images/zero-wave/advanced-analytics.webp",
    }
  ];

export const UserExperienceEnhancements = [
    {
      title: "User-Friendly UX",
      description: 
        "An intuitive design that simplifies financial navigation, enhancing user satisfaction and retention.",
      image: "/assets/images/zero-wave/user-friendly-ux.webp",
    },
    {
      title: "Educational Tools",
      description: 
        "Interactive guides and tutorials to support crypto beginners and provide financial literacy resources.",
      image: "/assets/images/zero-wave/educational-tools.webp",
    },
    {
      title: "Custom Alerts & Notifications",
      description: 
        "Receive notifications for price changes, transaction updates, and market trends to stay informed.",
      image: "/assets/images/zero-wave/alerts-notifications.webp",
    },
    {
      title: "Multi-Platform Access",
      description: 
        "Access Zero-Wave from mobile, desktop, and web platforms with a consistent experience across all devices.",
      image: "/assets/images/zero-wave/multi-platform-access.webp",
    },
    {
      title: "Customisable Dashboard",
      description: 
        "Users can personalise their dashboard layout to prioritise key financial data and investments.",
      image: "/assets/images/zero-wave/customisable-dashboard.webp",
    },
    {
      title: "Dark & Light Modes",
      description: 
        "Users can switch between dark and light themes for a more personalised and visually comfortable experience.",
      image: "/assets/images/zero-wave/dark-light-modes.webp",
    }
  ];
    
  export const SecurityAndCompliance = [
    {
      title: "Biometric Authentication",
      description: 
        "Use biometric data such as FaceID or fingerprint scanning to ensure secure login and account access.",
      image: "/assets/images/zero-wave/biometric-authentication.webp",
    },
    {
      title: "Multi-Factor Authentication (MFA)",
      description: 
        "Reinforce user security with two-factor authentication (2FA) for an added layer of protection.",
      image: "/assets/images/zero-wave/mfa-security.webp",
    },
    {
      title: "KYC/AML Compliance",
      description: 
        "Adheres to Know Your Customer (KYC) and Anti-Money Laundering (AML) regulations to protect users and comply with financial standards.",
      image: "/assets/images/zero-wave/kyc-aml-compliance.webp",
    },
    {
      title: "Data Privacy (GDPR Compliance)",
      description: 
        "Ensures user privacy and data protection in line with GDPR regulations, safeguarding sensitive information.",
      image: "/assets/images/zero-wave/gdpr-compliance.webp",
    },
    {
      title: "End-to-End Encryption",
      description: 
        "Encrypts user data at rest and in transit, providing industry-standard protection for financial transactions.",
      image: "/assets/images/zero-wave/data-encryption.webp",
    },
    {
      title: "Fraud Detection System",
      description: 
        "AI-driven fraud detection system to prevent suspicious activities and ensure user protection.",
      image: "/assets/images/zero-wave/fraud-detection.webp",
    }
  ];

  
export const TechnicalCapabilities = [
    {
      title: "Cross-Platform Compatibility",
      description: 
        "Developed with React Native for a unified codebase, allowing seamless access across iOS, Android, and desktop.",
      image: "/assets/images/zero-wave/cross-platform-compatibility.webp",
    },
    {
      title: "Modular Microservices",
      description: 
        "Microservice architecture ensures easy updates, fast deployments, and high system availability.",
      image: "/assets/images/zero-wave/microservices.webp",
    },
    {
      title: "Zero-Blockchain Integration",
      description: 
        "Direct integration with Zero-Blockchain allows for secure, transparent, and traceable on-chain transactions.",
      image: "/assets/images/zero-wave/blockchain-integration.webp",
    },
    {
      title: "Secure API Gateway",
      description: 
        "Leverages a secure API gateway to facilitate communication between financial data providers and the Zero-Wave app.",
      image: "/assets/images/zero-wave/api-gateway.webp",
    },
    {
      title: "Continuous Deployment (CI/CD)",
      description: 
        "Implements CI/CD pipelines to automate deployment, reduce downtime, and enable continuous improvement.",
      image: "/assets/images/zero-wave/continuous-deployment.webp",
    },
    {
      title: "State Management",
      description: 
        "Utilises lightweight state management with Zustand to ensure smooth state transitions and high performance.",
      image: "/assets/images/zero-wave/state-management.webp",
    }
  ];

  

  export const UserJourney = [
    {
      title: "Onboarding",
      description: 
        "Users sign up using their email, social login, or phone number, and complete the KYC process using biometric verification.",
      image: "/assets/images/zero-wave/onboarding.webp",
    },
    {
      title: "Wallet Setup",
      description: 
        "Link bank accounts, payment cards, and crypto wallets to manage multiple financial assets from a single dashboard.",
      image: "/assets/images/zero-wave/wallet-setup.webp",
    },
    {
      title: "Payments & Transfers",
      description: 
        "Send money to family, friends, or businesses using fiat or cryptocurrency with low fees and instant transfers.",
      image: "/assets/images/zero-wave/payments-transfers.webp",
    },
    {
      title: "Crypto Trading",
      description: 
        "Users can trade major cryptocurrencies directly within the app, with support for real-time trading and tracking.",
      image: "/assets/images/zero-wave/crypto-trading.webp",
    },
    {
      title: "Investment Tracking",
      description: 
        "Track stock, crypto, and investment portfolios with real-time performance data and in-depth analytics.",
      image: "/assets/images/zero-wave/investment-tracking.webp",
    },
    {
      title: "Support & Assistance",
      description: 
        "Access customer support through live chat, in-app support tickets, and self-service help guides.",
      image: "/assets/images/zero-wave/support-assistance.webp",
    }
  ];

  