// src/components/Banking.jsx

import React, { useEffect, useState, useRef } from "react";
import AnimatedTitle from "./AnimatedTitle"; // Adjust the import path if necessary
import { TiArrowDown, TiArrowUp } from "react-icons/ti";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Footer from './Footer';

gsap.registerPlugin(ScrollTrigger);

const Banking = () => {
  const [scrollDownClicked, setScrollDownClicked] = useState(false);
  const [isBottom, setIsBottom] = useState(false);

  const sectionRefs = useRef([]);
  const scrollDownRef = useRef(null);
  const scrollTopRef = useRef(null);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    // GSAP Animations for sections
    sectionRefs.current.forEach((section, index) => {
      if (section) {
        gsap.fromTo(
          section,
          { opacity: 0, y: 50 },
          {
            opacity: 1,
            y: 0,
            duration: 1,
            delay: index * 0.2,
            scrollTrigger: {
              trigger: section,
              start: "top 80%",
            },
          }
        );
      }
    });

    // GSAP Animation for Scroll Down Button
    if (scrollDownRef.current) {
      gsap.fromTo(
        scrollDownRef.current,
        { opacity: 0, y: -20 },
        { opacity: 1, y: 0, duration: 1 }
      );
    }

    // GSAP Animation for Back to Top Button
    if (scrollTopRef.current) {
      gsap.fromTo(
        scrollTopRef.current,
        { opacity: 0, y: 20 },
        {
          opacity: 1,
          y: 0,
          duration: 0.5,
          scrollTrigger: {
            trigger: scrollTopRef.current,
            start: "top bottom",
          },
        }
      );
    }
  }, []);

  // Check if the user is near the bottom of the page
  const checkIfBottom = () => {
    if (
      window.innerHeight + window.scrollY >=
      document.body.offsetHeight - 200
    ) {
      setIsBottom(true); // Show Back to Top button
    } else {
      setIsBottom(false); // Hide Back to Top button
    }
  };

  // Scroll to the content section
  const scrollToContent = () => {
    const content = document.getElementById("content");
    if (content) {
      const contentOffsetTop = content.offsetTop;
      window.scrollTo({
        top: contentOffsetTop - 100,
        behavior: "smooth",
      });
    }
    setScrollDownClicked(true); // Hide Scroll Down button
  };

  // Scroll to the top of the page
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setScrollDownClicked(false); // Show Scroll Down button
  };

  // Add a scroll listener to monitor page position
  useEffect(() => {
    window.addEventListener("scroll", checkIfBottom);
    return () => {
      window.removeEventListener("scroll", checkIfBottom);
    };
  }, []);

  const bankingServices = [
    {
      title: "Digital Wallets",
      description:
        "Discover Zero-Crypto-Wallet, our Web 3.0 blockchain-based crypto wallet. Manage your digital assets securely with advanced blockchain integration, multi-layered security, and an intuitive interface. Zero-Crypto-Wallet offers a seamless and user-friendly platform for transactions, staking, and asset management. Enjoy full control of your cryptocurrencies anytime and anywhere, with unmatched security and functionality tailored for the future of Web 3.0.",
      image: "/assets/images/banking/digital-wallets.webp",
      link: "/wallet", // Link to the Wallet page
    },
    {
      title: "Global Payments",
      description:
        "Experience instant, low-cost cross-border transactions with Zero-Bank's global payment solutions. Our platform ensures rapid settlement times and minimal fees, eliminating traditional banking barriers and empowering you to send and receive money worldwide effortlessly.",
      image: "/assets/images/banking/global-payments.webp",
    },
    {
      title: "Decentralized Lending",
      description:
        "Access decentralized lending platforms for competitive rates and transparent processes. Zero-Bank connects you to DeFi protocols, enabling you to borrow or lend assets directly, without intermediaries, and enjoy the flexibility and financial empowerment of decentralized finance.",
      image: "/assets/images/banking/defi-lending.webp",
    },
    {
      title: "Asset tokenisation",
      description:
        "Transform traditional assets into digital tokens with Zero-Bank's tokenisation services. Invest in tokenized real estate, equities, and other valuable assets while benefiting from enhanced liquidity, fractional ownership, and cutting-edge blockchain transparency.",
      image: "/assets/images/banking/asset-tokenisation.webp",
    },
    {
      title: "Financial Compliance",
      description:
        "Stay compliant with global financial regulations using our automated compliance tools. Zero-Bank integrates advanced KYC/AML solutions and monitoring systems to ensure secure and legitimate transactions, giving you peace of mind in every interaction.",
      image: "/assets/images/banking/compliance.webp",
    },
    {
      title: "Cryptocurrency Exchange",
      description:
        "Trade a variety of cryptocurrencies on our secure and user-friendly exchange platform. Zero-Bank offers competitive fees, advanced trading tools, and a seamless experience for beginners and professionals alike. Start trading confidently in a secure environment.",
      image: "/assets/images/banking/crypto-exchange.webp",
    },
    {
      title: "Savings Accounts",
      description:
        "Earn interest on your digital assets with Zero-Bank's high-yield savings accounts. Maximize your returns through our blockchain-enabled savings platform, designed to provide transparent, secure, and competitive interest rates for your cryptocurrencies.",
      image: "/assets/images/banking/savings-accounts.webp",
    },
    {
      title: "Merchant Services",
      description:
        "Accept cryptocurrency payments seamlessly with Zero-Bank's merchant solutions. Empower your business to accept digital currencies, expand your global reach, and enjoy low transaction fees with our easy-to-use merchant tools.",
      image: "/assets/images/banking/merchant-services.webp",
    },
    {
      title: "Apply for an Account",
      description:
        "Open an account with Zero-Bank to start your journey towards seamless banking solutions. Join the future of finance with blockchain-driven services that cater to your personal and business needs. Apply today to experience innovation and convenience.",
      image: "/assets/images/banking/open-account.webp",
      link: "https://zero-bank.xyz", // Link to Zero-Bank website
    },
  ];
  
  
  

  return (
    <div className="w-screen min-h-screen bg-black text-blue-50">
      {/* Hero Section */}
      <div className="relative w-screen h-screen overflow-hidden">
        <video
          src="/videos/banking-hero.mp4"
          autoPlay
          loop
          muted
          className="absolute left-0 top-0 size-full object-cover"
        />
        <div className="absolute inset-0 flex flex-col items-center justify-center bg-black/60">
          <AnimatedTitle
            title="Banking Reimagined"
            containerClass="text-center text-blue-50 font-zentry"
          />
        </div>
      </div>

      {/* Scroll Down Button */}
      {!scrollDownClicked && (
        <div
          ref={scrollDownRef}
          className="fixed bottom-10 left-1/2 transform -translate-x-1/2 flex items-center gap-2 p-2 bg-transparent border border-gray-400 rounded-full shadow-lg cursor-pointer hover:bg-gray-800 transition-all duration-300"
          onClick={scrollToContent}
        >
          <TiArrowDown
            className="text-white/40 transition-transform duration-300 hover:scale-110 hover:text-white"
            size={24}
          />
          <span className="relative z-20 text-sm uppercase text-white/40 transition-colors duration-300 hover:text-white">
            SCROLL DOWN
          </span>
        </div>
      )}

      {/* Content Section */}
      <div id="content" className="px-10 py-20 mx-auto max-w-7xl">
        {/* Introduction */}
        <div
          className="mb-10 text-center"
          ref={(el) => (sectionRefs.current[0] = el)}
        >
          <h2 className="text-3xl font-bold text-white">Our Banking Solutions</h2>
          <p className="mt-3 text-sm text-white-900">
            Empowering your financial journey with innovative blockchain banking services.
          </p>
        </div>

        {/* Banking Services Section */}
<div
className="grid gap-10 md:grid-cols-2 lg:grid-cols-3"
ref={(el) => (sectionRefs.current[1] = el)}
>
{bankingServices.map((service, index) => (
  <div
    key={index}
    className="relative p-6 bg-black/70 rounded-lg shadow-lg border border-gray-400 overflow-hidden group hover:scale-105 transition-transform"
  >
    {/* Background Image */}
    <div
      className="absolute inset-0 z-0 bg-cover bg-center opacity-20 group-hover:opacity-30 transition-opacity duration-300"
      style={{ backgroundImage: `url(${service.image})` }}
    ></div>
    
    {/* Content */}
    <div className="relative z-10">
      <h3 className="text-xl font-bold text-white">{service.title}</h3>
      <p className="mt-4 text-sm text-white-900">{service.description}</p>

      {/* Render a Button for sections with a link */}
      {service.link && (
        <div className="mt-4">
          <a
            href={service.link}
            target={service.link.startsWith("http") ? "_blank" : "_self"}
            rel="noopener noreferrer"
            className="inline-flex items-center px-4 py-2 text-gray-100 bg-teal-500 hover:bg-teal-700 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-400 transition-all"
          >
            {service.title === "Digital Wallets" ? "Get Zero-CryptoWallet" : "Open Account"}
          </a>
        </div>
      )}
    </div>

    {/* Decorative Overlay */}
    <div className="absolute inset-0 z-5 bg-gradient-to-b from-transparent to-black opacity-70"></div>
  </div>
))}
</div>


        <div
  className="grid gap-10 md:grid-cols-2 lg:grid-cols-3"
  ref={(el) => (sectionRefs.current[1] = el)}
>
  {bankingServices.map((service, index) => (
    <div
      key={index}
      className="relative p-6 bg-black/70 rounded-lg shadow-lg border border-gray-400 overflow-hidden group hover:scale-105 transition-transform"
    >
      {/* Background Image */}
      <div
        className="absolute inset-0 z-0 bg-cover bg-center opacity-20 group-hover:opacity-30 transition-opacity duration-300"
        style={{ backgroundImage: `url(${service.image})` }}
      ></div>

      {/* Content */}
      <div className="relative z-10">
        <h3 className="text-xl font-bold text-white">{service.title}</h3>
        <p className="mt-4 text-sm text-white-900">{service.description}</p>

        {/* Render a Button for sections with a link */}
        {service.link && (
          <div className="mt-4">
            <a
              href={service.link}
              target={service.link.startsWith("http") ? "_blank" : "_self"}
              rel="noopener noreferrer"
              className="inline-flex items-center px-4 py-2 text-gray-100 bg-teal-500 hover:bg-teal-700 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-400 transition-all"
            >
              {service.title === "Digital Wallets" ? "Explore Wallets" : "Open Account"}
            </a>
          </div>
        )}
      </div>

      {/* Decorative Overlay */}
      <div className="absolute inset-0 z-5 bg-gradient-to-b from-transparent to-black opacity-70"></div>
    </div>
  ))}
</div>

        {/* Detailed Sections */}
        <div
          className="mt-20 space-y-16"
          ref={(el) => (sectionRefs.current[2] = el)}
        >
          {/* Section 1 */}
          <div className="flex flex-col-reverse items-center gap-10 md:flex-row">
            <div className="w-full md:w-1/2">
              <img
                src="/assets/images/banking/security.webp"
                alt="Advanced Security"
                className="rounded-lg shadow-lg"
              />
            </div>
            <div className="w-full md:w-1/2">
              <h3 className="text-2xl font-bold text-white">
                Unparalleled Security Measures
              </h3>
              <p className="mt-4 text-sm text-white-900">
                We prioritise the safety of your assets with cutting-edge security protocols and encryption technologies, ensuring your peace of mind.
              </p>
            </div>
          </div>
          {/* Section 2 */}
          <div className="flex flex-col items-center gap-10 md:flex-row">
            <div className="w-full md:w-1/2">
              <h3 className="text-2xl font-bold text-white">
                Innovative Banking Technology
              </h3>
              <p className="mt-4 text-sm text-white-900">
                Our platform leverages the latest in blockchain technology to provide you with seamless and efficient banking services, redefining the way you manage your finances.
              </p>
            </div>
            <div className="w-full md:w-1/2">
              <img
                src="/assets/images/banking/innovation.webp"
                alt="Innovative Technology"
                className="rounded-lg shadow-lg"
              />
            </div>
          </div>
          {/* Section 3 */}
          <div className="flex flex-col-reverse items-center gap-10 md:flex-row">
            <div className="w-full md:w-1/2">
              <img
                src="/assets/images/banking/global-access.webp"
                alt="Global Access"
                className="rounded-lg shadow-lg"
              />
            </div>
            <div className="w-full md:w-1/2">
              <h3 className="text-2xl font-bold text-white">
                Global Financial Access
              </h3>
              <p className="mt-4 text-sm text-white-900">
                Enjoy banking services that are accessible anytime, anywhere, breaking down geographical barriers and bringing the world closer.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Back to Top Button */}
      {isBottom && (
        <div
          ref={scrollTopRef}
          className="fixed bottom-10 left-5 flex items-center gap-2 p-2 bg-transparent border border-gray-400 rounded-full shadow-lg cursor-pointer hover:bg-gray-800 transition-all duration-300"
          onClick={scrollToTop}
        >
          <TiArrowUp
            className="text-white/40 transition-transform duration-300 hover:scale-110 hover:text-white"
            size={24}
          />
          <span className="relative z-20 text-sm uppercase text-white/40 transition-colors duration-300 hover:text-white">
            BACK TO TOP
          </span>
        </div>
      )}
    
    {/* Footer */}  
    <Footer />
    </div>
  );
};

export default Banking;
