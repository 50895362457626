import React, { useEffect, useState, useRef } from "react";
import AnimatedTitle from "./AnimatedTitle"; // Adjust the import path if necessary
import { TiArrowDown, TiArrowUp } from "react-icons/ti";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Footer from "./Footer";

gsap.registerPlugin(ScrollTrigger);

const Legal = () => {
  const [scrollDownClicked, setScrollDownClicked] = useState(false);
  const [isBottom, setIsBottom] = useState(false);

  const sectionRefs = useRef([]);
  const scrollDownRef = useRef(null);
  const scrollTopRef = useRef(null);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    sectionRefs.current.forEach((section, index) => {
      if (section) {
        gsap.fromTo(
          section,
          { opacity: 0, y: 50 },
          {
            opacity: 1,
            y: 0,
            duration: 1,
            delay: index * 0.2,
            scrollTrigger: {
              trigger: section,
              start: "top 80%",
            },
          }
        );
      }
    });

    if (scrollDownRef.current) {
      gsap.fromTo(
        scrollDownRef.current,
        { opacity: 0, y: -20 },
        { opacity: 1, y: 0, duration: 1 }
      );
    }

    if (scrollTopRef.current) {
      gsap.fromTo(
        scrollTopRef.current,
        { opacity: 0, y: 20 },
        {
          opacity: 1,
          y: 0,
          duration: 0.5,
          scrollTrigger: {
            trigger: scrollTopRef.current,
            start: "top bottom",
          },
        }
      );
    }
  }, []);

  const checkIfBottom = () => {
    if (
      window.innerHeight + window.scrollY >=
      document.body.offsetHeight - 200
    ) {
      setIsBottom(true);
    } else {
      setIsBottom(false);
    }
  };

  const scrollToContent = () => {
    const content = document.getElementById("content");
    if (content) {
      const contentOffsetTop = content.offsetTop;
      window.scrollTo({
        top: contentOffsetTop - 100,
        behavior: "smooth",
      });
    }
    setScrollDownClicked(true);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setScrollDownClicked(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", checkIfBottom);
    return () => {
      window.removeEventListener("scroll", checkIfBottom);
    };
  }, []);

  const legalSections = [
    {
      title: "Terms of Service",
      description:
        "By accessing and using our platform, you agree to abide by the terms and conditions outlined here. This includes restrictions on unauthorised use and liability disclaimers.",
    },
    {
      title: "Privacy Policy",
      description:
        "Your privacy is our priority. Our Privacy Policy explains how we collect, use, and protect your personal information.",
    },
    {
      title: "Cookie Policy",
      description:
        "We use cookies to enhance your browsing experience. By continuing to use our site, you consent to our Cookie Policy.",
    },
    {
      title: "Intellectual Property",
      description:
        "All content on this platform, including trademarks, logos, and text, is protected by intellectual property laws.",
    },
    {
      title: "Legal Compliance",
      description:
        "We comply with all applicable laws and regulations, including GDPR and CCPA. Learn more about our compliance measures.",
    },
    {
      title: "Contact Information",
      description:
        "If you have any legal inquiries or concerns, contact us at legal@zeroblockchain.com.",
    },
  ];

  return (
    <div className="w-screen min-h-screen bg-black text-blue-50">
      {/* Hero Section */}
      <div className="relative w-screen h-screen overflow-hidden">
        <video
          src="/videos/legal-hero.mp4"
          autoPlay
          loop
          muted
          className="absolute left-0 top-0 size-full object-cover"
        />
        <div className="absolute inset-0 flex flex-col items-center justify-center bg-black/60">
          <AnimatedTitle
            title="Legal Information"
            containerClass="text-center text-blue-50 font-zentry"
          />
        </div>
      </div>

      {/* Scroll Down Button */}
      {!scrollDownClicked && (
        <div
          ref={scrollDownRef}
          className="fixed bottom-10 left-1/2 transform -translate-x-1/2 flex items-center gap-2 p-2 bg-transparent border border-gray-400 rounded-full shadow-lg cursor-pointer hover:bg-gray-800 transition-all duration-300"
          onClick={scrollToContent}
        >
          <TiArrowDown size={24} className="text-white/40 hover:text-white" />
          <span className="text-white/40 hover:text-white">SCROLL DOWN</span>
        </div>
      )}

      {/* Content Section */}
      <div id="content" className="px-10 py-20 mx-auto max-w-7xl">
        {/* Introduction */}
        <div className="mb-10 text-center">
          <h2 className="text-3xl font-bold">Legal Information</h2>
          <p className="mt-3">
            Explore our terms, policies, and compliance information to stay
            informed.
          </p>
        </div>

        {/* Legal Sections */}
        <div className="grid gap-10 md:grid-cols-2 lg:grid-cols-3">
          {legalSections.map((section, index) => (
            <div
              key={index}
              className="relative p-6 bg-black/70 rounded-lg shadow-lg border border-gray-400 overflow-hidden group hover:scale-105 transition-transform"
              ref={(el) => (sectionRefs.current[index] = el)}
            >
              <div className="relative z-10">
                <h3 className="text-xl font-bold">{section.title}</h3>
                <p className="mt-4">{section.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Back to Top Button */}
      {isBottom && (
        <div
          ref={scrollTopRef}
          className="fixed bottom-10 left-5 flex items-center gap-2 p-2 bg-transparent border border-gray-400 rounded-full shadow-lg cursor-pointer hover:bg-gray-800 transition-all duration-300"
          onClick={scrollToTop}
        >
          <TiArrowUp
            size={24}
            className="text-white/40 hover:text-white transition-transform duration-300"
          />
          <span className="text-white/40 hover:text-white">BACK TO TOP</span>
        </div>
      )}

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default Legal;
