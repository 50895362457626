// src/components/TokenCard.jsx

import React from 'react';
import PropTypes from 'prop-types';
import HeroButton from './HeroButton';

const TokenCard = React.forwardRef(({ token, onMoreDetails, onPurchase, onViewOnChain }, ref) => {
    return (
        <div
            ref={ref}
            className="bg-gray-800 border-2 border-gray-300 rounded-lg shadow-lg p-5 mb-5 relative z-10 hover:shadow-xl transition-shadow duration-300"
        >
            <img
                src={`/images/${token.image}`}
                alt={token.name}
                className="w-full h-56 md:h-64 object-cover rounded-t-lg border-2 border-gray-100"
                loading="lazy" // Enhances performance by lazy loading images
            />
            <div className="mt-4 relative z-20">
                <h3 className="text-2xl font-bold text-white">{token.name}</h3>
                <p className="text-gray-100 mt-2">{token.description}</p>
                <p className="mb-3 text-xl text-yellow-500">Price: {token.price}</p>
                <p className="mb-3 text-red-200">Token Standard: {token.tokenStandard}</p>
                <p className="mb-3 text-blue-200">Deployment: {token.deployment}</p>
                <div className="flex justify-between mt-4 relative z-30">
                    <HeroButton
                        onClick={() => onMoreDetails(token.id)}
                        ariaLabel={`More details about ${token.name}`}
                        additionalClasses="px-4 py-2 bg-blue-500 hover:bg-blue-700 z-30"
                    >
                        More Details
                    </HeroButton>
                    <HeroButton
                        onClick={() => onPurchase(token.name)}
                        ariaLabel={`Purchase ${token.name}`}
                        additionalClasses="px-4 py-2 bg-green-500 hover:bg-green-700 z-30"
                    >
                        Purchase
                    </HeroButton>
                    <HeroButton
                        onClick={() => onViewOnChain(token.contractAddress)}
                        ariaLabel={`View ${token.name} on Etherscan`}
                        additionalClasses="px-4 py-2 bg-teal-500 hover:bg-teal-700 z-30"
                    >
                        View On Chain
                    </HeroButton>
                </div>
            </div>
        </div>
    );
});

TokenCard.propTypes = {
    token: PropTypes.object.isRequired,
    onMoreDetails: PropTypes.func.isRequired,
    onPurchase: PropTypes.func.isRequired,
    onViewOnChain: PropTypes.func.isRequired,
};

export default TokenCard;
