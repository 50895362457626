import { useGSAP } from '@gsap/react'
import React, { useRef } from 'react'
import { animateWithGsap } from '../utils/animations';
import { exploreVideo1, exploreVideo2, exploreVideo } from '../utils';
import gsap from 'gsap';

const Features = () => {
  const videoRef = useRef();

  useGSAP(() => {
    gsap.to('#exploreVideo', {
      scrollTrigger: {
        trigger: '#exploreVideo',
        toggleActions: 'play pause reverse restart',
        start: '-10% bottom',
      },
      onComplete: () => {
        videoRef.current.play();
      }
    })

    animateWithGsap('#features_title', { y:0, opacity:1})
    animateWithGsap(
      '.g_grow',
      { scale: 1, opacity: 1, ease: 'power1' },
      { scrub: 5.5 }
    );
    animateWithGsap(
      '.g_text',
      {y:0, opacity: 1,ease: 'power2.inOut',duration: 1}
    )
  }, []);

  return (
    <section className="h-full common-padding bg-zinc relative overflow-hidden">
      <div className="screen-max-wdith">
        <div className="mb-12 w-full">
          <h1 id="features_title" className="section-heading">Explore the full drop.</h1>
        </div>
        
        <div className="flex flex-col justify-center items-center overflow-hidden">
          <div className="mt-32 mb-24 pl-24">
            <h2 className="text-5xl lg:text-7xl font-semibold">Zero-Coin.</h2>
            <h2 className="text-5xl lg:text-7xl font-semibold">Encrypted in Gold & Silver.</h2>
          </div>

          <div className="flex-center flex-col sm:px-10">
            <div className="relative h-[50vh] w-full flex items-center">
              <video playsInline id="exploreVideo" className="w-full h-full object-cover object-center" preload="none" muted autoPlay ref={videoRef}>
                <source src={exploreVideo} type="video/mp4" />
              </video>
            </div>

            <div className="flex flex-col w-full relative">
              <div className="feature-video-container">
                <div className="overflow-hidden flex-1 h-[50vh] mt-5">
                <video playsInline id="exploreVideo1" className="w-full h-full object-cover object-center" preload="none" muted autoPlay ref={videoRef}>
                  <source src={exploreVideo2} type="video/mp4" />
                </video>
                </div>
                <div className="overflow-hidden flex-1 h-[50vh] mt-5">
                <video playsInline id="exploreVideo2" className="w-full h-full object-cover object-center" preload="none" muted autoPlay ref={videoRef}>
                  <source src={exploreVideo1} type="video/mp4" />
                </video>
                </div>
              </div>

              <div className="feature-text-container">
                <div className="flex-1 flex-center">
                  <p className="feature-text g_text">
                    Zero-Coin is {' '}
                    <span className="text-white">
                      ushering in a new era of digital finance&nbsp;
                    </span>
                    through a groundbreaking airdrop with Zero-Bank on its very own Zero-Blockchain. Be part of the revolution that leverages&nbsp;  
                    <span className="text-white">
                      state-of-the-art blockchain technology&nbsp;
                    </span> 
                    used in the world's leading financial platforms. This is your chance to be early in the world of tomorrow's cryptocurrency innovations—directly through Zero-Bank's exclusive airdrop. Secure your spot in the future of finance today with the Zero-Coin native to Zero-Blockchain!
                  </p>
                </div>
                
                <div className="flex-1 flex-center"><br />
                <p className="feature-text g_text"><br />
                  Zero-Tokens bring rarity to life with a series of {' '}
                  <span className="text-white">
                    fifty unique digital collectibles
                  </span>,
                  each representing an extinct animal or soon to be, crafted with precision on the blockchain. Discover the uniqueness of each token and own a piece of history as rare as it gets. Engage with a community of collectors who value the narrative each token holds. Each Zero-Token is not just a collectible, but a symbol of the balance of nature. By owning a Zero-Token, you are preserving the memory of these creatures and supporting innovative conservation efforts.
                </p>
              </div>
              </div>      
              </div>
            </div>
          </div>
        </div>      
    </section>
  )
}

export default Features