// src/components/TokenDetails.jsx

import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Footer from './Footer'; // Import the Footer component
import ModelView2 from './ModelView2'; // Import the ModelView2 component
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import HeroButton from './HeroButton'; // Adjust the path based on your project structure

const tokenData = {
    0: {
        name: 'Zero-Coin',
        descriptionPart1: 'Zero-Coin is the native cryptocurrency of the Zero-Blockchain, developed by Zero Bank to facilitate seamless and secure transactions within the ecosystem.',
        descriptionPart2: 'Zero-Coin leverages advanced blockchain technology to provide fast, low-cost transactions while maintaining high security standards. It is designed to support a wide range of financial services and applications, making it a versatile asset within the Zero-Blockchain network.',
        descriptionPart3: 'As a native coin, Zero-Coin plays a crucial role in the operation and maintenance of the Zero-Blockchain, incentivizing validators and ensuring the network\'s stability and security. It also supports smart contracts, enabling the development of decentralised applications (DApps) on the platform.',
        tokenStandard: 'Native Coin',
        deployment: 'Zero-Blockchain',
        contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
        price: '1000.00 GBP'
    },
    1: {
        name: 'GECKO',
        descriptionPart1: 'The Psychedelic Rock Gecko is a critically endangered species of lizard known for its strikingly vibrant and colorful appearance. Native to a limited range in Vietnam, this gecko inhabits rocky outcrops in tropical forests.',
        descriptionPart2: 'Its name is derived from its unique, psychedelic coloration that features a mix of bright blues, greens, and yellows, making it a subject of fascination for both researchers and reptile enthusiasts. Appearing around 50 million years ago, the Psychedelic Rock Gecko has adapted to a specific ecological niche within its environment.',
        descriptionPart3: 'These geckos are nocturnal, spending their nights hunting for insects and their days hiding in crevices to avoid predators. Their adhesive toe pads allow them to climb vertical surfaces, an essential adaptation for their rocky habitat. Unfortunately, the Psychedelic Rock Gecko is critically endangered due to habitat loss and illegal pet trade.',
        tokenStandard: 'ERC-20 Smart Contract',
        deployment: 'Ethereum Mainnet',
        contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
        price: '10.00 GBP'
    },
    2: {
        name: 'TIGER',
        descriptionPart1: 'Tigers are among the most iconic and recognizable big cats, known for their distinctive orange coats with black stripes. These majestic predators once roamed across much of Asia but are now confined to isolated pockets due to extensive habitat loss and poaching.',
        descriptionPart2: 'Tigers are solitary animals, with each individual maintaining a large territory to ensure access to sufficient prey. Tigers appeared around 2 million years ago, evolving into several subspecies adapted to different environments, from the cold forests of Siberia to the dense jungles of Southeast Asia.',
        descriptionPart3: 'They are apex predators, primarily preying on large mammals such as deer and wild boar. Despite their fearsome reputation, tigers are critically endangered. Habitat destruction, human-wildlife conflict, and illegal hunting for their skins and body parts have led to severe population declines.',
        tokenStandard: 'ERC-20 Smart Contract',
        deployment: 'Ethereum Mainnet',
        contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
        price: '20.00 GBP'
    },
    3: {
        name: 'RHINO',
        descriptionPart1: 'Rhinos are large herbivores characterized by their thick skin and one or two horns on their snouts. There are five species of rhinos, each adapted to different environments ranging from African savannas to Asian forests.',
        descriptionPart2: 'These gentle giants play a crucial role in their ecosystems, helping to shape the landscape by grazing on vegetation and maintaining open areas for other species. Rhinos have a long evolutionary history, dating back to the Eocene epoch around 50 million years ago.',
        descriptionPart3: 'Several rhino species are critically endangered due to poaching and habitat loss. The demand for rhino horn in traditional medicine and as a status symbol has driven illegal hunting to devastating levels. Conservation efforts are vital to protect these iconic animals from extinction.',
        tokenStandard: 'ERC-20 Smart Contract',
        deployment: 'Ethereum Mainnet',
        contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
        price: '30.00 GBP'
    },
    4: {
        name: 'STEGO',
        descriptionPart1: 'Stegosaurus, commonly known as Stego, was a herbivorous dinosaur famous for its distinctive row of large, bony plates along its back and the spikes on its tail. Living during the Late Jurassic period, approximately 150 million years ago, Stegosaurus roamed the lush floodplains of what is now North America.',
        descriptionPart2: 'These dinosaurs grew up to 30 feet long and weighed up to 5 tons. The plates and spikes of Stegosaurus have intrigued scientists for years. Theories suggest that the plates may have been used for display, thermoregulation, or defense against predators.',
        descriptionPart3: 'Despite their formidable appearance, Stegosaurus primarily fed on low-lying plants, using its beak-like mouth to strip foliage. Stegosaurus became extinct around 150 million years ago, but its fossils have provided valuable insights into the diversity and complexity of dinosaur life during the Jurassic period.',
        tokenStandard: 'ERC-20 Smart Contract',
        deployment: 'Ethereum Mainnet',
        contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
        price: '40.00 GBP'
    },
    5: {
        name: 'CHEETO',
        descriptionPart1: 'Cheetahs are renowned as the fastest land animals, capable of reaching speeds up to 70 miles per hour in short bursts covering distances up to 500 meters. These sleek, spotted cats are built for speed, with a lightweight frame, long legs, and a flexible spine that allows for an extended stride.',
        descriptionPart2: 'Cheetahs are primarily found in sub-Saharan Africa, with a small population in Iran. The evolutionary history of cheetahs dates back around 4 million years. They have adapted to various habitats, including savannas, grasslands, and arid regions. Cheetahs rely on their exceptional speed to hunt prey, primarily small to medium-sized ungulates such as gazelles.',
        descriptionPart3: 'Unlike other big cats, cheetahs hunt during the day, using their keen eyesight to spot prey from a distance. Cheetahs are endangered due to habitat loss, human-wildlife conflict, and a decline in prey availability. Their genetic diversity is also low, making them more vulnerable to disease and reproductive issues.',
        tokenStandard: 'ERC-20 Smart Contract',
        deployment: 'Ethereum Mainnet',
        contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
        price: '50.00 GBP'
    },
    6: {
        name: 'BISON',
        descriptionPart1: 'Bison are large, majestic bovines native to North America and Europe, known for their hulking presence and distinctive hump over their shoulders. These animals played a vital role in the ecosystems of the Great Plains, grazing on grasses and creating habitats for other species.',
        descriptionPart2: 'The American bison, in particular, is a symbol of the American West and has a significant cultural and historical impact on the indigenous peoples of North America. The evolutionary lineage of bison dates back around 2 million years. They thrived in various environments, from the grasslands of North America to the forests and steppes of Europe.',
        descriptionPart3: 'Despite their resilience, American bison faced near extinction in the 19th century due to overhunting and habitat destruction. However, conservation efforts have been successful in recovering their populations. Today, bison are found in protected areas and ranches, with ongoing initiatives to restore them to more of their historic range.',
        tokenStandard: 'ERC-20 Smart Contract',
        deployment: 'Ethereum Mainnet',
        contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
        price: '60.00 GBP'
    },
    7: {
        name: 'DINO',
        descriptionPart1: 'The term "dinosaur" encompasses a vast array of prehistoric reptiles that roamed the Earth during the Mesozoic era, which spans approximately 180 million years. Dinosaurs varied widely in size, shape, and behavior, from the massive, long-necked sauropods to the fierce, carnivorous theropods.',
        descriptionPart2: 'They dominated terrestrial ecosystems and evolved into some of the most extraordinary creatures ever to exist. Dinosaurs first appeared in the Triassic period, around 230 million years ago, and flourished until the end of the Cretaceous period, about 65 million years ago. They adapted to a wide range of environments and developed various survival strategies, including herding, hunting, and complex social behaviors.',
        descriptionPart3: 'The extinction of most dinosaur species around 65 million years ago is widely believed to have been caused by a catastrophic event, such as an asteroid impact, which drastically changed the planet\'s climate. This mass extinction paved the way for mammals to rise and eventually led to the evolution of humans.',
        tokenStandard: 'ERC-20 Smart Contract',
        deployment: 'Ethereum Mainnet',
        contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
        price: '70.00 GBP'
    },
    8: {
        name: 'MOA',
        descriptionPart1: 'Moas were large, flightless birds native to New Zealand, known for their impressive size and unique evolutionary path. These birds, some species of which stood over 12 feet tall, were among the largest avian species ever to exist. They played a significant role in New Zealand\'s ecosystems, feeding on various plant materials and helping to shape the vegetation structure.',
        descriptionPart2: 'Moas appeared in the Miocene epoch, approximately 23 million years ago. They evolved in isolation, free from mammalian predators, which allowed them to grow large and fill ecological niches typically occupied by herbivorous mammals in other parts of the world.',
        descriptionPart3: 'The arrival of Polynesian settlers in New Zealand around 1300 AD led to the rapid decline and eventual extinction of moas by 1400 AD due to overhunting and habitat destruction. Their extinction had a profound impact on the local environment, illustrating the delicate balance of ecosystems and the significant influence humans can have on wildlife.',
        tokenStandard: 'ERC-20 Smart Contract',
        deployment: 'Ethereum Mainnet',
        contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
        price: '80.00 GBP'
    },
    9: {
        name: 'WOOLY',
        descriptionPart1: 'The Woolly Mammoth was a magnificent creature of the Ice Age, known for its long, curved tusks and shaggy fur that helped it survive in the cold tundra environments of the Northern Hemisphere. These prehistoric elephants were closely related to modern elephants and roamed vast areas of Eurasia and North America, feeding on grasses and other vegetation.',
        descriptionPart2: 'Woolly mammoths first appeared around 400,000 years ago during the Pleistocene epoch. They were well-adapted to the harsh, cold climates of the Ice Age, with their thick fur, subcutaneous fat, and large size providing insulation against the frigid temperatures. Mammoths traveled in herds and had complex social structures, much like today\'s elephants.',
        descriptionPart3: 'The extinction of woolly mammoths around 4,000 years ago is attributed to a combination of climate change and human hunting. As the Ice Age ended and temperatures rose, their habitat shrank, and overhunting by early humans further contributed to their decline. However, woolly mammoths continue to captivate scientists and the public alike, with ongoing research into their biology and the potential for de-extinction through advances in genetic technology.',
        tokenStandard: 'ERC-20 Smart Contract',
        deployment: 'Ethereum Mainnet',
        contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
        price: '90.00 GBP'
    },
    10: {
        name: 'SABRE',
        descriptionPart1: 'Sabre-toothed cats, including the famous Smilodon, were formidable predators known for their long, curved canine teeth that could grow up to 7 inches in length. These prehistoric cats roamed the Americas during the Pleistocene epoch, preying on large herbivores such as bison and mammoths.',
        descriptionPart2: 'Their powerful build and distinctive sabre teeth made them one of the most recognizable and fearsome predators of their time. The evolutionary history of sabre-toothed cats dates back to around 2.5 million years ago. They were highly adapted for ambush hunting, relying on their muscular forelimbs to grapple prey and deliver fatal bites with their elongated canines.',
        descriptionPart3: 'Despite their intimidating appearance, sabre-toothed cats likely faced competition from other large predators and environmental challenges that shaped their evolutionary path. Sabre-toothed cats became extinct around 10,000 years ago, likely due to a combination of climate change and the decline of their large prey animals at the end of the last Ice Age.',
        tokenStandard: 'ERC-20 Smart Contract',
        deployment: 'Ethereum Mainnet',
        contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
        price: '100.00 GBP'
    },
    11: {
        name: 'QUAGGA',
        descriptionPart1: 'The Quagga was a unique subspecies of plains zebra that once roamed the grasslands of South Africa. Unlike other zebras, the Quagga had distinct markings: its front half was striped like a zebra, while its back half was more horse-like, with minimal striping.',
        descriptionPart2: 'Quaggas appeared around 4 million years ago, evolving alongside other plains zebras in Africa. They were well-adapted to the open grasslands and savannas, grazing on grasses and living in herds that provided protection from predators. Their social structures and behaviors were similar to those of other zebra species, with complex social interactions and strong herd dynamics.',
        descriptionPart3: 'Sadly, the Quagga was driven to extinction in the late 19th century due to excessive hunting by European settlers and competition with domesticated livestock. The last known Quagga died in captivity in 1883. However, efforts are underway to bring back the Quagga through selective breeding of zebras with similar traits, aiming to restore a part of this lost subspecies to its former habitat.',
        tokenStandard: 'ERC-20 Smart Contract',
        deployment: 'Ethereum Mainnet',
        contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
        price: '110.00 GBP'
    },
    12: {
        name: 'PANDA',
        descriptionPart1: 'Giant pandas are beloved around the world for their distinctive black-and-white fur and gentle demeanor. Native to the mountainous regions of central China, pandas primarily feed on bamboo, which makes up more than 99% of their diet.',
        descriptionPart2: 'Despite their bear lineage, pandas have evolved specialized adaptations for bamboo consumption, including strong jaws and a "pseudo-thumb" to grasp bamboo stalks. Giant pandas appeared around 2-3 million years ago, adapting to the dense bamboo forests of China. These solitary animals have large home ranges and spend most of their time feeding to meet their dietary needs.',
        descriptionPart3: 'Pandas are vulnerable due to habitat loss, poaching, and low reproductive rates. Conservation efforts, including habitat preservation, anti-poaching measures, and captive breeding programs, have helped increase panda populations. Today, giant pandas are a global symbol of wildlife conservation, representing the importance of preserving biodiversity and protecting endangered species.',
        tokenStandard: 'ERC-20 Smart Contract',
        deployment: 'Ethereum Mainnet',
        contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
        price: '120.00 GBP'
    },
    13: {
        name: 'PTERAN',
        descriptionPart1: 'Pteranodon was a large flying reptile, or pterosaur, that lived during the Late Cretaceous period, around 70-100 million years ago. With a wingspan reaching up to 33 feet, Pteranodon was one of the largest pterosaurs and a dominant presence in the skies of prehistoric North America.',
        descriptionPart2: 'These reptiles were well-adapted for soaring flight, with lightweight bones and a crest on their heads that likely played a role in aerodynamics or mating displays. Pteranodons appeared during the Late Cretaceous period and thrived in coastal environments, feeding primarily on fish and other marine life.',
        descriptionPart3: 'The extinction of Pteranodon, along with other pterosaurs, occurred around 65 million years ago, likely due to the same catastrophic events that led to the demise of the dinosaurs. Their fossils have provided valuable insights into the diversity and adaptation of flying reptiles, helping scientists understand the evolutionary history of these remarkable creatures.',
        tokenStandard: 'ERC-20 Smart Contract',
        deployment: 'Ethereum Mainnet',
        contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
        price: '130.00 GBP'
    },
    14: {
        name: 'THYLAC',
        descriptionPart1: 'The Thylacine, also known as the Tasmanian tiger, was a remarkable carnivorous marsupial native to Australia, Tasmania, and New Guinea. It was named for its striped lower back, which resembled a tiger\'s stripes.',
        descriptionPart2: 'The Thylacine was a top predator in its habitat, preying on small to medium-sized animals, including birds and marsupials. Thylacines appeared around 4 million years ago, evolving as one of the largest carnivorous marsupials. They had a dog-like appearance with a stiff tail and a pouch for rearing young, similar to other marsupials like kangaroos.',
        descriptionPart3: 'The extinction of the Thylacine in the 20th century was primarily due to hunting, habitat destruction, and competition with introduced species like dogs. The last known Thylacine died in captivity in 1936. Efforts to locate surviving individuals have been unsuccessful, but the Thylacine remains a potent symbol of the impact of human activity on wildlife and the importance of conservation.',
        tokenStandard: 'ERC-20 Smart Contract',
        deployment: 'Ethereum Mainnet',
        contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
        price: '140.00 GBP'
    },
    15: {
        name: 'AUROCH',
        descriptionPart1: 'Aurochs were large wild cattle that once roamed across Europe, Asia, and North Africa. They were the ancestors of modern domestic cattle and played a significant role in the ecosystems they inhabited by grazing and maintaining open grasslands.',
        descriptionPart2: 'Aurochs were powerful animals, with large, curved horns and a robust build, making them a formidable presence in their environment. Aurochs appeared around 2 million years ago and thrived in a variety of habitats, including forests, grasslands, and wetlands.',
        descriptionPart3: 'The extinction of the Aurochs in the 17th century was due to overhunting, habitat loss, and competition with domestic cattle. The last recorded Auroch died in Poland in 1627. Efforts to revive the Aurochs through selective breeding programs aim to recreate animals with similar characteristics, helping to restore their ecological role in modern landscapes.',
        tokenStandard: 'ERC-20 Smart Contract',
        deployment: 'Ethereum Mainnet',
        contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
        price: '150.00 GBP'
    },
    16: {
        name: 'IVORY',
        descriptionPart1: 'The Ivory-billed woodpecker was one of the largest and most striking woodpecker species in North America. Known for its black and white plumage and prominent ivory-colored bill, this bird inhabited the dense forests of the southeastern United States.',
        descriptionPart2: 'Its loud calls and powerful drumming were once common sounds in its habitat, as it foraged for insects in the bark of dead and dying trees. The Ivory-billed woodpecker appeared in the Pleistocene epoch and thrived in mature, swampy forests with abundant deadwood.',
        descriptionPart3: 'Despite numerous reported sightings, no definitive evidence of its existence has been confirmed in recent years. The plight of the Ivory-billed woodpecker underscores the importance of preserving critical habitats and protecting endangered species.',
        tokenStandard: 'ERC-20 Smart Contract',
        deployment: 'Ethereum Mainnet',
        contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
        price: '160.00 GBP'
    },
    17: {
        name: 'PANG',
        descriptionPart1: 'Pangolins are unique, scale-covered mammals found in Asia and Africa. They are often referred to as "scaly anteaters" due to their diet of ants and termites, which they capture using their long, sticky tongues.',
        descriptionPart2: 'Pangolins are nocturnal and solitary animals, relying on their keen sense of smell to locate food. Their scales, made of keratin, provide protection against predators. Pangolins appeared around 55 million years ago, evolving to fill a specialized ecological niche.',
        descriptionPart3: 'Pangolins are critically endangered due to illegal poaching and trafficking for their scales and meat, which are highly valued in traditional medicine and as a delicacy. Habitat loss further threatens their survival.',
        tokenStandard: 'ERC-20 Smart Contract',
        deployment: 'Ethereum Mainnet',
        contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
        price: '170.00 GBP'
    },
    18: {
        name: 'CONDOR',
        descriptionPart1: 'The California condor is a magnificent bird, known as the largest flying bird in North America with a wingspan of up to 9.5 feet. These scavengers play a crucial role in their ecosystem by feeding on carrion, thus helping to keep the environment clean.',
        descriptionPart2: 'California condors appeared around 40 million years ago, evolving to inhabit a range of environments from coastal areas to mountainous regions. They have keen eyesight and strong beaks adapted for tearing flesh, making them well-suited for their scavenging lifestyle.',
        descriptionPart3: 'The California condor was critically endangered by the 1980s, with only 27 individuals remaining in the wild due to habitat loss, lead poisoning, and hunting. Intensive conservation efforts, including captive breeding and reintroduction programs, have helped increase their numbers.',
        tokenStandard: 'ERC-20 Smart Contract',
        deployment: 'Ethereum Mainnet',
        contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
        price: '180.00 GBP'
    },
    19: {
        name: 'BAIJI',
        descriptionPart1: 'The Baiji, also known as the Yangtze river dolphin, was a freshwater dolphin native to the Yangtze River in China. This gentle and intelligent creature had a distinctive long, narrow beak and a streamlined body adapted to life in murky river waters.',
        descriptionPart2: 'Baijis appeared around 25 million years ago, evolving unique adaptations to thrive in the freshwater environment of the Yangtze River. They were social animals, often seen swimming in small groups. The Baiji\'s presence was an indicator of the health of the Yangtze River ecosystem, highlighting the interdependence of species within this complex habitat.',
        descriptionPart3: 'Tragically, the Baiji was declared functionally extinct in 2006, primarily due to human activities such as industrial pollution, overfishing, and habitat destruction. The decline of the Baiji serves as a stark reminder of the impact of unchecked development on aquatic ecosystems. Efforts to improve river health and protect other endangered species in the Yangtze River continue in the hope of preventing further losses.',
        tokenStandard: 'ERC-20 Smart Contract',
        deployment: 'Ethereum Mainnet',
        contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
        price: '190.00 GBP'
    },
    20: {
        name: 'GLYPTO',
        descriptionPart1: 'Glyptodon was a large, armored mammal that lived during the Pleistocene epoch. Resembling a giant armadillo, Glyptodon had a protective shell made of bony plates and a club-like tail for defense.',
        descriptionPart2: 'These herbivorous creatures roamed the grasslands and woodlands of South America, feeding on a variety of plant materials. Glyptodons appeared around 2.5 million years ago and were well-adapted to their environment with their heavy armor providing protection against predators like saber-toothed cats.',
        descriptionPart3: 'Glyptodons became extinct around 10,000 years ago, likely due to a combination of climate change and hunting by early humans. Their extinction marked the end of an era for many large Ice Age mammals.',
        tokenStandard: 'ERC-20 Smart Contract',
        deployment: 'Ethereum Mainnet',
        contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
        price: '200.00 GBP'
    },
    21: {
        name: 'MEGAL',
        descriptionPart1: 'Megalodon was a giant prehistoric shark, one of the most fearsome predators to have ever existed. This massive shark dominated the oceans during the Cenozoic era, reaching lengths of up to 60 feet.',
        descriptionPart2: 'Megalodon\'s teeth, which could measure over 7 inches long, are among the most iconic fossils, providing evidence of its formidable hunting capabilities. Megalodon appeared around 23 million years ago and thrived in warm ocean waters. It preyed on large marine animals, including whales, using its powerful jaws to deliver crushing bites.',
        descriptionPart3: 'Megalodon became extinct around 2.6 million years ago, possibly due to changes in ocean temperature and declining prey populations. Its extinction remains a topic of scientific interest, with ongoing research aiming to understand the environmental and ecological factors that led to the disappearance of this apex predator.',
        tokenStandard: 'ERC-20 Smart Contract',
        deployment: 'Ethereum Mainnet',
        contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
        price: '210.00 GBP'
    },
    22: {
        name: 'DIRE',
        descriptionPart1: 'Dire wolves were large, prehistoric canines that lived during the Late Pleistocene epoch. These formidable predators were larger and more robust than modern gray wolves, with powerful jaws and strong limbs adapted for hunting large prey.',
        descriptionPart2: 'Dire wolves roamed the Americas, thriving in a variety of habitats, including grasslands, forests, and mountains. Dire wolves appeared around 250,000 years ago and were apex predators in their ecosystems. They hunted in packs, using their size and strength to bring down large herbivores such as bison, horses, and mammoths.',
        descriptionPart3: 'Dire wolves became extinct around 10,000 years ago, likely due to a combination of climate change and competition with other predators, including early humans. Their fossils continue to provide valuable information about the Ice Age and the factors that influenced the survival and extinction of large mammals.',
        tokenStandard: 'ERC-20 Smart Contract',
        deployment: 'Ethereum Mainnet',
        contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
        price: '220.00 GBP'
    },
    23: {
        name: 'QUETZAL',
        descriptionPart1: 'Quetzalcoatlus was one of the largest pterosaurs to ever exist, with an impressive wingspan that could reach up to 36 feet. This giant flying reptile lived during the Late Cretaceous period, around 70 million years ago, and soared over the ancient landscapes of North America.',
        descriptionPart2: 'Quetzalcoatlus is named after the Aztec feathered serpent god, Quetzalcoatl, reflecting its awe-inspiring size and appearance. Quetzalcoatlus appeared during the Late Cretaceous period and thrived in a variety of environments, from coastal areas to inland regions.',
        descriptionPart3: 'The extinction of Quetzalcoatlus, along with other pterosaurs, occurred around 65 million years ago due to the catastrophic events that marked the end of the Cretaceous period. Fossils of Quetzalcoatlus have provided significant insights into the diversity and adaptation of flying reptiles, helping scientists understand the evolutionary history of these remarkable creatures.',
        tokenStandard: 'ERC-20 Smart Contract',
        deployment: 'Ethereum Mainnet',
        contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
        price: '230.00 GBP'
    },
    24: {
        name: 'LYNX',
        descriptionPart1: 'The Iberian lynx is a critically endangered wild cat species native to the Iberian Peninsula in southwestern Europe. Known for its tufted ears, spotted coat, and short tail, the Iberian lynx is a solitary and elusive predator that primarily hunts rabbits.',
        descriptionPart2: 'This species plays a crucial role in its ecosystem by controlling rabbit populations, which in turn affects the entire food web. Iberian lynxes appeared around 5 million years ago, evolving to adapt to the Mediterranean forests and scrublands of their region.',
        descriptionPart3: 'The Iberian lynx is critically endangered due to habitat loss, poaching, and a decline in rabbit populations caused by disease and human activities. Intensive conservation efforts, including habitat restoration, captive breeding, and reintroduction programs, have shown promising results in stabilizing and increasing their population.',
        tokenStandard: 'ERC-20 Smart Contract',
        deployment: 'Ethereum Mainnet',
        contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
        price: '240.00 GBP'
    },
    25: {
        name: 'SAURO',
        descriptionPart1: 'Brachiosaurus, commonly referred to as Sauro, was a massive herbivorous dinosaur that roamed the Earth during the Late Jurassic period, approximately 150 million years ago. Known for its long neck and towering height, Brachiosaurus could reach vegetation that other herbivores could not, making it a unique and vital part of its ecosystem.',
        descriptionPart2: 'Brachiosaurus appeared during the Late Jurassic period and thrived in the lush floodplains and forests of what is now North America. This dinosaur\'s distinctive body structure, with its front legs longer than its hind legs, allowed it to graze on high vegetation, such as coniferous trees.',
        descriptionPart3: 'Brachiosaurus became extinct around 150 million years ago, but its fossils have provided invaluable insights into the biology and ecology of sauropod dinosaurs. Ongoing research continues to uncover new details about its behavior, growth, and interactions with other species, enriching our understanding of the Late Jurassic period and the incredible diversity of life that existed during that time.',
        tokenStandard: 'ERC-20 Smart Contract',
        deployment: 'Ethereum Mainnet',
        contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
        price: '250.00 GBP'
    },
    26: {
        name: 'OKAPI',
        descriptionPart1: 'The Okapi, often referred to as the "forest giraffe," is a unique mammal native to the dense rainforests of the Democratic Republic of Congo. Despite its zebra-like stripes on its legs, the Okapi is more closely related to the giraffe.',
        descriptionPart2: 'This elusive creature is adapted to life in the forest, with a long, flexible tongue used for browsing on foliage and a keen sense of hearing to detect predators. Okapis appeared around 16 million years ago, evolving to thrive in the dense, shaded environments of Central Africa. Their solitary and secretive nature makes them difficult to observe in the wild.',
        descriptionPart3: 'The Okapi is endangered due to habitat loss, poaching, and political instability in its native range. Conservation efforts are focused on habitat protection, anti-poaching measures, and research to better understand their ecology and behavior.',
        tokenStandard: 'ERC-20 Smart Contract',
        deployment: 'Ethereum Mainnet',
        contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
        price: '260.00 GBP'
    },
    27: {
        name: 'KAKAPO',
        descriptionPart1: 'The Kakapo, a large, nocturnal parrot native to New Zealand, is known for its flightlessness and distinctive green and brown plumage that provides excellent camouflage in its forest habitat. These unique birds are critically endangered, with a population that once numbered in the millions now reduced to fewer than 200 individuals.',
        descriptionPart2: 'Kakapos have a lifespan of up to 90 years, making them one of the longest-lived bird species. Kakapos appeared around 25 million years ago, evolving in the predator-free environment of New Zealand. Their flightlessness and ground-dwelling habits were sustainable until the introduction of mammalian predators such as rats, cats, and stoats by human settlers.',
        descriptionPart3: 'Intensive conservation efforts, including a breeding program and habitat protection on predator-free islands, have been implemented to save the Kakapo from extinction. These efforts have shown positive results, with a slowly increasing population.',
        tokenStandard: 'ERC-20 Smart Contract',
        deployment: 'Ethereum Mainnet',
        contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
        price: '270.00 GBP'
    },
    28: {
        name: 'VAQUITA',
        descriptionPart1: 'The Vaquita is the world\'s smallest and most endangered marine mammal, found only in the northern part of the Gulf of California, Mexico. This elusive porpoise is characterized by its small size, rounded body, and distinctive dark rings around its eyes and mouth.',
        descriptionPart2: 'Vaquitas are shy and elusive, making them difficult to study and observe in the wild. Vaquitas appeared around 2 million years ago, evolving to thrive in the warm, shallow waters of their restricted range. They feed on a variety of fish and squid, using echolocation to navigate and hunt in the turbid waters of the Gulf.',
        descriptionPart3: 'Critically endangered, the Vaquita\'s population has been severely impacted by entanglement in gillnets used for illegal fishing of the totoaba fish, whose swim bladder is highly valued in traditional Chinese medicine. Conservation efforts include the ban on gillnets, enforcement of fishing regulations, and public awareness campaigns.',
        tokenStandard: 'ERC-20 Smart Contract',
        deployment: 'Ethereum Mainnet',
        contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
        price: '280.00 GBP'
    },
    29: {
        name: 'GORILLA',
        descriptionPart1: 'Mountain gorillas are large, powerful primates native to the forested mountains of central Africa, including Uganda, Rwanda, and the Democratic Republic of Congo. Known for their gentle nature and complex social structures, mountain gorillas live in family groups led by a dominant silverback male.',
        descriptionPart2: 'These groups exhibit a range of behaviors and vocalizations, reflecting their sophisticated social interactions. Mountain gorillas appeared around 2 million years ago, adapting to life in the high-altitude forests of the Albertine Rift. They primarily feed on a diet of leaves, shoots, and stems, which they forage for throughout the day.',
        descriptionPart3: 'Mountain gorillas are critically endangered, with habitat loss, poaching, and disease posing significant threats to their survival. Conservation efforts have led to an increase in mountain gorilla populations in recent years.',
        tokenStandard: 'ERC-20 Smart Contract',
        deployment: 'Ethereum Mainnet',
        contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
        price: '290.00 GBP'
    },
    30: {
        name: 'RED-WOLF',
        descriptionPart1: 'The Red Wolf, a North American canid, is critically endangered and one of the rarest wolf species in the world. Native to the southeastern United States, red wolves are smaller and leaner than gray wolves, with a reddish-brown coat and distinctive long legs.',
        descriptionPart2: 'They are highly adaptable predators, feeding on a variety of prey, including small mammals, birds, and deer. Red wolves appeared around 2 million years ago, evolving to thrive in the diverse habitats of the southeastern U.S., from forests and swamps to coastal prairies.',
        descriptionPart3: 'The red wolf population plummeted in the 20th century due to habitat loss, hunting, and interbreeding with coyotes. Conservation efforts, including captive breeding and reintroduction programs, have helped increase their numbers, but challenges remain.',
        tokenStandard: 'ERC-20 Smart Contract',
        deployment: 'Ethereum Mainnet',
        contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
        price: '300.00 GBP'
    },
    31: {
        name: 'TORTOISE',
        descriptionPart1: 'The Pinta Island tortoise, also known as the Abingdon Island tortoise, was a giant tortoise species native to the Galápagos Islands. This species gained worldwide attention through Lonesome George, the last known individual, who became a symbol of conservation efforts.',
        descriptionPart2: 'Pinta Island tortoises were characterized by their large size and dome-shaped shells, which protected them from predators and environmental hazards. Pinta Island tortoises appeared around 3 million years ago, evolving to adapt to the unique environment of the Galápagos Islands. They were herbivores, feeding on a variety of vegetation, including cacti, grasses, and leaves.',
        descriptionPart3: 'The Pinta Island tortoise became extinct in 2012 with the death of Lonesome George, largely due to habitat destruction and the introduction of non-native species by humans. Their extinction highlights the fragility of island ecosystems and the impact of human activities on wildlife.',
        tokenStandard: 'ERC-20 Smart Contract',
        deployment: 'Ethereum Mainnet',
        contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
        price: '310.00 GBP'
    },
    32: {
        name: 'OWL',
        descriptionPart1: 'The Spotted Owl is a nocturnal bird of prey native to the old-growth forests of western North America. Known for its distinctive dark eyes and spotted plumage, this owl relies on large, mature forests for nesting and hunting.',
        descriptionPart2: 'Spotted Owls are important indicators of forest health, as their presence reflects the integrity of their habitat. Spotted Owls appeared around 20 million years ago, evolving to adapt to the dense, multi-layered forests of their range.',
        descriptionPart3: 'They are skilled hunters, feeding primarily on small mammals such as flying squirrels and wood rats. These owls use their excellent night vision and silent flight to hunt in the darkness, often perching quietly before swooping down on their prey.',
        tokenStandard: 'ERC-20 Smart Contract',
        deployment: 'Ethereum Mainnet',
        contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
        price: '320.00 GBP'
    },
    33: {
        name: 'MANATEE',
        descriptionPart1: 'The West Indian manatee, also known as the sea cow, is a large, gentle marine mammal found in the warm coastal waters of the Caribbean, Gulf of Mexico, and southeastern United States.',
        descriptionPart2: 'Manatees are herbivores, feeding on a variety of aquatic vegetation, including seagrasses and algae. They play a crucial role in their ecosystem by maintaining healthy seagrass beds and contributing to the overall health of coastal marine environments. West Indian manatees appeared around 50 million years ago, evolving to thrive in shallow, slow-moving waters.',
        descriptionPart3: 'Despite their size, manatees are vulnerable to a variety of threats, including boat strikes, habitat loss, and entanglement in fishing gear. Conservation efforts have helped improve the status of the West Indian manatee, but they remain vulnerable.',
        tokenStandard: 'ERC-20 Smart Contract',
        deployment: 'Ethereum Mainnet',
        contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
        price: '330.00 GBP'
    },
    34: {
        name: 'GIBBON',
        descriptionPart1: 'The Hainan gibbon, a small ape native to Hainan Island in China, is critically endangered and considered the world\'s rarest primate. With fewer than 30 individuals remaining, this gibbon species is on the brink of extinction.',
        descriptionPart2: 'Hainan gibbons are known for their distinctive songs, which they use to communicate and establish territories, as well as their agile, brachiating movement through the forest canopy. Hainan gibbons appeared around 2 million years ago, evolving to adapt to the subtropical forests of Hainan Island. They are frugivores, primarily feeding on fruits, but also consume leaves, flowers, and insects.',
        descriptionPart3: 'The Hainan gibbon is critically endangered due to habitat loss from deforestation, hunting, and human encroachment. Conservation efforts focus on habitat restoration, anti-poaching measures, and research to better understand their ecology and behavior.',
        tokenStandard: 'ERC-20 Smart Contract',
        deployment: 'Ethereum Mainnet',
        contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
        price: '340.00 GBP'
    },
    35: {
        name: 'RIVER',
        descriptionPart1: 'The Chinese paddlefish, also known as the "elephant fish" due to its long, paddle-shaped snout, was a large freshwater fish native to the Yangtze River in China. This species was one of the world\'s largest freshwater fish, reaching lengths of up to 23 feet.',
        descriptionPart2: 'Paddlefish used their distinctive snouts to detect prey in the murky river waters, feeding primarily on small fish and crustaceans. Chinese paddlefish appeared around 200 million years ago, thriving in the ancient river systems of China. They were highly migratory, traveling long distances to spawn, and played a significant role in the river ecosystem as top predators.',
        descriptionPart3: 'Declared extinct in 2020, the Chinese paddlefish\'s decline was due to overfishing, habitat fragmentation from dam construction, and pollution. Their extinction highlights the profound impact of human activities on river ecosystems and the urgent need for sustainable management of freshwater resources.',
        tokenStandard: 'ERC-20 Smart Contract',
        deployment: 'Ethereum Mainnet',
        contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
        price: '350.00 GBP'
    },
    36: {
        name: 'AMUR',
        descriptionPart1: 'The Amur leopard is a critically endangered big cat native to the temperate forests of the Russian Far East and northeastern China. Known for its striking rosette-patterned coat and long legs, the Amur leopard is adapted to the cold, snowy environment of its range.',
        descriptionPart2: 'These solitary predators primarily hunt deer and other small mammals, relying on their stealth and strength to ambush prey. Amur leopards appeared around 2 million years ago, evolving to survive in harsh, mountainous regions with extreme weather conditions. They are excellent climbers and swimmers, able to navigate the rugged terrain and dense forests of their habitat.',
        descriptionPart3: 'With fewer than 100 individuals remaining in the wild, the Amur leopard is critically endangered due to habitat loss, poaching, and human-wildlife conflict. Conservation efforts include habitat protection, anti-poaching measures, and breeding programs to increase their population.',
        tokenStandard: 'ERC-20 Smart Contract',
        deployment: 'Ethereum Mainnet',
        contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
        price: '360.00 GBP'
    },
    37: {
        name: 'ADDAX',
        descriptionPart1: 'The Addax, also known as the white antelope or screwhorn antelope, is a critically endangered desert-dwelling antelope native to the Sahara Desert. These striking animals are well-adapted to their arid environment, with pale coats that reflect the sun\'s rays and long, spiral horns.',
        descriptionPart2: 'Addaxes are nomadic, traveling long distances in search of sparse vegetation and water sources. Addaxes appeared around 2 million years ago, evolving to thrive in the harsh desert conditions of North Africa. They are highly resilient animals, able to survive on minimal water and feed on tough desert plants.',
        descriptionPart3: 'Critically endangered, the Addax population has been decimated by habitat loss, hunting, and competition with livestock. Conservation efforts focus on protecting remaining habitats, reducing human-wildlife conflict, and breeding programs to increase their numbers.',
        tokenStandard: 'ERC-20 Smart Contract',
        deployment: 'Ethereum Mainnet',
        contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
        price: '370.00 GBP'
    },
    38: {
        name: 'TUATARA',
        descriptionPart1: 'The Tuatara is a unique reptile endemic to New Zealand, often referred to as a "living fossil" due to its ancient lineage. Tuataras resemble lizards but belong to a distinct order, Rhynchocephalia, that thrived during the age of the dinosaurs.',
        descriptionPart2: 'They are known for their spiny crests along their backs and their slow metabolism, which allows them to live for over 100 years. Tuataras appeared around 200 million years ago, making them one of the oldest surviving reptile species. They are adapted to a variety of habitats, from coastal forests to rocky outcrops, and primarily feed on insects, small reptiles, and bird eggs.',
        descriptionPart3: 'Endangered due to habitat loss and introduced predators such as rats and cats, Tuataras are the focus of intensive conservation efforts in New Zealand. These efforts include habitat restoration, predator control, and breeding programs to reintroduce Tuataras to their historic range.',
        tokenStandard: 'ERC-20 Smart Contract',
        deployment: 'Ethereum Mainnet',
        contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
        price: '380.00 GBP'
    },
    39: {
        name: 'IBIS',
        descriptionPart1: 'The Northern Bald Ibis is a critically endangered bird native to parts of North Africa and the Middle East. Recognizable by its bald head, long curved beak, and glossy black plumage with iridescent green and purple hues, this ibis species inhabits semi-arid habitats and cliffs.',
        descriptionPart2: 'Northern Bald Ibises are social birds, often seen foraging in groups for insects, small reptiles, and other prey. Northern Bald Ibises appeared around 20 million years ago, evolving to adapt to the arid and rocky environments of their range. They are migratory birds, traveling long distances between breeding and wintering grounds.',
        descriptionPart3: 'Critically endangered, Northern Bald Ibis populations have declined due to habitat destruction, hunting, and disturbance at breeding sites. Conservation efforts include habitat protection, anti-poaching measures, and captive breeding programs aimed at reintroducing birds to their former range.',
        tokenStandard: 'ERC-20 Smart Contract',
        deployment: 'Ethereum Mainnet',
        contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
        price: '390.00 GBP'
    },
    40: {
        name: 'ELEPHANT',
        descriptionPart1: 'The Sumatran elephant is a critically endangered subspecies of the Asian elephant, native to the island of Sumatra in Indonesia. These elephants are smaller than their mainland counterparts, with distinct patches of depigmentation on their trunks and ears.',
        descriptionPart2: 'Sumatran elephants are keystone species, playing a vital role in their ecosystem by dispersing seeds and creating clearings in dense forests. Sumatran elephants appeared around 3 million years ago, evolving to adapt to the diverse tropical rainforests of Sumatra.',
        descriptionPart3: 'Critically endangered, Sumatran elephants face severe threats from habitat loss due to deforestation, human-wildlife conflict, and poaching for their ivory. Conservation efforts focus on protecting and restoring habitats, mitigating human-elephant conflict, and anti-poaching initiatives.',
        tokenStandard: 'ERC-20 Smart Contract',
        deployment: 'Ethereum Mainnet',
        contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
        price: '400.00 GBP'
    },
    41: {
        name: 'LEMUR',
        descriptionPart1: 'The Greater Bamboo Lemur is a critically endangered primate native to the bamboo forests of Madagascar. Known for its specialized diet, this lemur primarily feeds on bamboo, including the tough and toxic parts that other animals cannot consume.',
        descriptionPart2: 'Greater Bamboo Lemurs have strong jaws and teeth adapted to processing bamboo, making them unique among primates. Greater Bamboo Lemurs appeared around 50 million years ago, evolving to thrive in the bamboo-dominated habitats of Madagascar. They live in small social groups and exhibit a range of vocalizations and behaviors for communication and coordination.',
        descriptionPart3: 'Critically endangered, Greater Bamboo Lemurs are threatened by habitat destruction, illegal logging, and hunting. Conservation efforts include habitat protection, community-based conservation programs, and research to better understand their ecology and behavior.',
        tokenStandard: 'ERC-20 Smart Contract',
        deployment: 'Ethereum Mainnet',
        contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
        price: '410.00 GBP'
    },
    42: {
        name: 'DODO',
        descriptionPart1: 'The Dodo was a flightless bird native to the island of Mauritius in the Indian Ocean. Known for its stout body, large beak, and distinctive appearance, the Dodo became a symbol of extinction and human impact on wildlife.',
        descriptionPart2: 'These birds were well-adapted to their island environment, feeding on fruits, seeds, and nuts, and nesting on the ground. Dodos appeared in the Pleistocene epoch, evolving in isolation on Mauritius without natural predators. Their lack of fear of humans and inability to fly made them highly vulnerable to hunting and introduced species.',
        descriptionPart3: 'The arrival of European settlers in the 17th century led to the rapid decline of Dodo populations due to overhunting and habitat destruction. The Dodo became extinct in the late 17th century, with the last confirmed sighting in 1662. The extinction of the Dodo serves as a poignant reminder of the fragility of island ecosystems and the profound impact of human activities on biodiversity.',
        tokenStandard: 'ERC-20 Smart Contract',
        deployment: 'Ethereum Mainnet',
        contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
        price: '420.00 GBP'
    },
    43: {
        name: 'HARPY',
        descriptionPart1: 'The Harpy Eagle is a powerful and majestic raptor native to the tropical rainforests of Central and South America. Known for its striking appearance, with a crown of feathers and powerful talons, the Harpy Eagle is one of the largest and most formidable eagles in the world.',
        descriptionPart2: 'These birds are apex predators, preying on a variety of mammals and birds, including monkeys and sloths. Harpy Eagles appeared around 20 million years ago, evolving to adapt to the dense, multi-layered forests of their range. They build large nests high in the canopy and exhibit strong pair bonds, with both parents participating in raising their young.',
        descriptionPart3: 'Near threatened, Harpy Eagles face threats from habitat loss due to deforestation and human encroachment. Conservation efforts focus on protecting their habitats, raising awareness, and promoting sustainable land-use practices.',
        tokenStandard: 'ERC-20 Smart Contract',
        deployment: 'Ethereum Mainnet',
        contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
        price: '430.00 GBP'
    },
    44: {
        name: 'WOLF',
        descriptionPart1: 'The Ethiopian wolf is a critically endangered canid native to the Ethiopian highlands. Known for its slender build and reddish-brown coat, this wolf is adapted to the high-altitude Afroalpine environment, where it preys primarily on small mammals such as rodents.',
        descriptionPart2: 'Ethiopian wolves live in social packs but hunt individually, displaying unique behavioral adaptations to their habitat. Ethiopian wolves appeared around 2 million years ago, evolving to thrive in the unique conditions of the Ethiopian plateau. They are highly specialized hunters, relying on their keen sense of sight and hearing to locate prey in the sparse grasslands.',
        descriptionPart3: 'With fewer than 500 individuals remaining, Ethiopian wolves are critically endangered due to habitat loss, disease, and human-wildlife conflict. Conservation efforts include habitat protection, disease management, and community-based initiatives to reduce conflict with livestock herders.',
        tokenStandard: 'ERC-20 Smart Contract',
        deployment: 'Ethereum Mainnet',
        contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
        price: '440.00 GBP'
    },
    45: {
        name: 'SUN-BEAR',
        descriptionPart1: 'The Sun Bear, the smallest of the bear species, is native to the tropical forests of Southeast Asia. Known for its short, sleek fur and distinctive orange-yellow chest patch, the Sun Bear is an excellent climber, often found in trees where it forages for fruits, honey, and insects.',
        descriptionPart2: 'These bears have long, curved claws and a long tongue adapted for extracting food from narrow crevices. Sun Bears appeared around 5 million years ago, evolving to thrive in the dense, tropical forests of their range. They are solitary and elusive animals, with a range of vocalizations used for communication.',
        descriptionPart3: 'Vulnerable due to habitat loss, poaching, and illegal wildlife trade, Sun Bear populations have declined significantly in recent years. Conservation efforts focus on habitat protection, anti-poaching measures, and public awareness campaigns to reduce demand for bear products.',
        tokenStandard: 'ERC-20 Smart Contract',
        deployment: 'Ethereum Mainnet',
        contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
        price: '450.00 GBP'
    },
    46: {
        name: 'ORCA',
        descriptionPart1: 'The Southern Resident Orcas are a distinct population of killer whales found in the coastal waters of the Pacific Northwest. Known for their striking black and white coloration and complex social structures, these orcas live in tight-knit family groups called pods.',
        descriptionPart2: 'Southern Resident Orcas primarily feed on salmon, making them highly dependent on the health of salmon populations. Southern Resident Orcas appeared around 11 million years ago, evolving to adapt to the rich marine environment of the Pacific Northwest. They exhibit a range of sophisticated behaviors, including vocal communication, cooperative hunting, and intricate social interactions.',
        descriptionPart3: 'Critically endangered, Southern Resident Orcas face threats from prey depletion, pollution, and habitat disturbance from shipping and boating activities. Conservation efforts include habitat restoration, salmon recovery programs, and regulations to reduce noise pollution and vessel traffic.',
        tokenStandard: 'ERC-20 Smart Contract',
        deployment: 'Ethereum Mainnet',
        contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
        price: '460.00 GBP'
    },
    47: {
        name: 'PIKA',
        descriptionPart1: 'The Ili Pika is a small, elusive mammal native to the mountainous regions of China. Known for its rounded ears, small size, and thick fur, the Ili Pika is adapted to cold, high-altitude environments.',
        descriptionPart2: 'These solitary animals are herbivores, feeding on a variety of alpine vegetation, including grasses, herbs, and mosses. Ili Pikas appeared around 20 million years ago, evolving to thrive in the rocky, high-altitude habitats of the Tianshan Mountains. They are known for their distinctive vocalizations, which they use to communicate with each other and establish territories.',
        descriptionPart3: 'Endangered due to habitat loss, climate change, and human disturbance, Ili Pika populations have declined significantly in recent years. Conservation efforts focus on habitat protection, research to better understand their ecology and behavior, and public awareness campaigns to reduce human impact.',
        tokenStandard: 'ERC-20 Smart Contract',
        deployment: 'Ethereum Mainnet',
        contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
        price: '470.00 GBP'
    },
    48: {
        name: 'SHARK',
        descriptionPart1: 'The Great Hammerhead Shark is a critically endangered species of shark known for its distinctive hammer-shaped head and impressive size. Found in warm temperate and tropical waters worldwide, these sharks are apex predators, feeding on a variety of prey, including fish, squid, and crustaceans.',
        descriptionPart2: 'The unique shape of their head enhances their ability to detect prey and navigate their environment. Great Hammerhead Sharks appeared around 20 million years ago, evolving to adapt to diverse marine environments. They are known for their wide-ranging movements, often migrating long distances in search of food and suitable breeding grounds.',
        descriptionPart3: 'Critically endangered due to overfishing, habitat loss, and bycatch in commercial fisheries, Great Hammerhead Shark populations have declined dramatically in recent years. Conservation efforts include implementing fishing regulations, creating marine protected areas, and raising public awareness about the importance of sharks in marine ecosystems.',
        tokenStandard: 'ERC-20 Smart Contract',
        deployment: 'Ethereum Mainnet',
        contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
        price: '480.00 GBP'
    },
    49: {
        name: 'MYNAH',
        descriptionPart1: 'The Bali Mynah, also known as the Bali Starling, is a critically endangered bird native to the Indonesian island of Bali. Known for its striking white plumage, blue eye patches, and elegant crest, the Bali Mynah is a symbol of beauty and conservation.',
        descriptionPart2: 'These birds inhabit dry forests and savannas, where they forage for insects, fruits, and seeds. Bali Mynahs appeared around 2 million years ago, evolving to adapt to the unique environment of Bali. They are social birds, often seen in pairs or small groups, and exhibit a range of vocalizations and behaviors for communication.',
        descriptionPart3: 'Critically endangered due to habitat loss, poaching, and illegal pet trade, Bali Mynah populations have plummeted in recent years. Conservation efforts include habitat restoration, captive breeding programs, and anti-poaching measures.',
        tokenStandard: 'ERC-20 Smart Contract',
        deployment: 'Ethereum Mainnet',
        contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
        price: '490.00 GBP'
    },
    50: {
        name: 'CAMEL',
        descriptionPart1: 'The Wild Bactrian Camel is a critically endangered species of camel native to the deserts of Central Asia, including parts of China and Mongolia. Known for its two humps, thick fur, and remarkable ability to survive in harsh desert conditions, the Wild Bactrian Camel is a symbol of resilience and adaptation.',
        descriptionPart2: 'These camels can go for long periods without water and can drink brackish water that would be undrinkable for other animals. Wild Bactrian Camels appeared around 3 million years ago, evolving to thrive in the extreme environments of the Gobi Desert and surrounding areas.',
        descriptionPart3: 'Critically endangered due to habitat loss, hunting, and competition with domestic livestock, Wild Bactrian Camel populations have declined significantly in recent years. Conservation efforts focus on habitat protection, anti-poaching measures, and research to better understand their ecology and behavior.',
        tokenStandard: 'ERC-20 Smart Contract',
        deployment: 'Ethereum Mainnet',
        contractAddress: '0xa47d4023561527a2ec51b216ab06cc5be34f38bd',
        price: '500.00 GBP'
    }
};

const TokenDetails = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const tokenId = parseInt(id, 10); // Ensure base 10 parsing
  
    const token = tokenData[tokenId];
  
    if (!token) {
      return (
        <div className="flex flex-col items-center justify-center h-screen bg-black text-white">
          <h1 className="text-3xl font-bold mb-5">Token not found</h1>
          <HeroButton
            onClick={() => navigate('/')}
            ariaLabel="Back to Main Store"
            additionalClasses="px-5 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-700 transition-all duration-300"
          >
            Back to Main
          </HeroButton>
        </div>
      );
    }
  
    // Handler Functions
    const handleBackClick = () => navigate('/');
    const handlePurchaseClick = () => alert(`Purchasing ${token.name}`);
    const handleNextClick = () => {
      if (tokenId < 50) navigate(`/token/${tokenId + 1}`);
    };
    const handlePrevClick = () => {
      if (tokenId > 1) navigate(`/token/${tokenId - 1}`);
    };
    const handleViewOnChainClick = () => {
      window.open(`https://etherscan.io/token/${token.contractAddress}`, '_blank'); // Adjust based on deployment
    };
  
    return (
      <div className="flex flex-col items-center p-5 bg-black-100 min-h-screen">
        <div className="flex flex-col md:flex-row w-full max-w-6xl bg-black shadow-md rounded-lg overflow-hidden mt-10 mb-10">
          {/* Model Viewer */}
          <div className="w-full md:w-1/2 p-5 flex items-center justify-center">
            <ModelView2 activeIndex={tokenId} />
          </div>
          {/* Token Information */}
          <div className="w-full md:w-1/2 p-5 flex flex-col justify-center">
            <h1 className="text-3xl font-bold mb-3 text-white">{token.name}</h1>
            <p className="mb-3 text-gray-200">{token.descriptionPart1}</p>
            <p className="mb-3 text-gray-200">{token.descriptionPart2}</p>
            <p className="mb-3 text-gray-200">{token.descriptionPart3}</p>
            <p className="mb-3 text-xl text-yellow-500">Price: {token.price}</p>
            <p className="mb-3 text-red-200">Token Standard: {token.tokenStandard}</p>
            <p className="mb-3 text-blue-200">Deployment: {token.deployment}</p>
            <p className="mb-3 text-green-200">Contract Address:</p>
            <p className="mb-3 text-white">{token.contractAddress}</p>
            
            {/* Navigation Buttons */}
            <div className="flex justify-center md:justify-start mb-5">
              <button
                onClick={handlePrevClick}
                disabled={tokenId === 1}
                className="px-3 py-2 bg-gray-700 text-white rounded-full m-2 hover:bg-gray-500 transition-all duration-300 disabled:opacity-50 disabled:cursor-not-allowed"
                aria-label="Previous Token"
              >
                <FaArrowLeft size={20} />
              </button>
              <button
                onClick={handleNextClick}
                disabled={tokenId === 50}
                className="px-3 py-2 bg-gray-700 text-white rounded-full m-2 hover:bg-gray-500 transition-all duration-300 disabled:opacity-50 disabled:cursor-not-allowed"
                aria-label="Next Token"
              >
                <FaArrowRight size={20} />
              </button>
            </div>
  
            {/* Action Buttons */}
            <div className="flex justify-center md:justify-start gap-x-4">
              <HeroButton
                onClick={handleBackClick}
                ariaLabel="Back to Main Store"
                additionalClasses="px-4 py-2 bg-blue-500 hover:bg-blue-700 z-30"
              >
                Back to Main
              </HeroButton>
              <HeroButton
                onClick={handlePurchaseClick}
                ariaLabel={`Purchase ${token.name}`}
                additionalClasses="px-4 py-2 bg-blue-500 hover:bg-blue-700 z-30"
              >
                Purchase
              </HeroButton>
              <HeroButton
                onClick={handleViewOnChainClick}
                ariaLabel={`View ${token.name} on Etherscan`}
                additionalClasses="px-4 py-2 bg-blue-500 hover:bg-blue-700 z-30"
              >
                View On Chain
              </HeroButton>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  };
  
  export default TokenDetails;