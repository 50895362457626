// src/components/Button.jsx

import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const Button = ({ id, title, rightIcon, leftIcon, containerClass, onClick }) => {
  return (
    <button
      id={id}
      className={clsx(
        "group relative z-10 w-fit cursor-pointer overflow-hidden rounded-full border-2 border-gray-400 bg-transparent px-7 py-3 text-white transition-colors duration-300 flex items-center",
        "hover:bg-teal-500 hover:text-white active:bg-white active:text-teal-500",
        containerClass
      )}
      onClick={onClick}
    >
      {/* Left Icon (Optional) */}
      {leftIcon && <span className="mr-2 flex-shrink-0">{leftIcon}</span>}

      {/* Animated Title */}
      <span className="relative inline-flex overflow-hidden font-sans text-xs uppercase flex-grow">
        <div className="translate-y-0 skew-y-0 transition duration-500 group-hover:translate-y-[-160%] group-hover:skew-y-12 text-center">
          {title}
        </div>
        <div className="absolute translate-y-[164%] skew-y-12 transition duration-500 group-hover:translate-y-0 group-hover:skew-y-0 text-center">
          {title}
        </div>
      </span>

      {/* Right Icon (Optional) */}
      {rightIcon && <span className="ml-2 flex-shrink-0">{rightIcon}</span>}
    </button>
  );
};

Button.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  rightIcon: PropTypes.element,
  leftIcon: PropTypes.element,
  containerClass: PropTypes.string,
  onClick: PropTypes.func,
};

Button.defaultProps = {
  rightIcon: null,
  leftIcon: null,
  containerClass: "",
  onClick: () => {},
};

export default Button;
