import React, { useRef } from 'react'
import { chipVideo, frameImg, frameVideo } from '../utils'
import { useGSAP } from '@gsap/react'
import gsap from 'gsap';
import { animateWithGsap } from '../utils/animations';

const HowItWorks = () => {
  const videoRef = useRef();

  useGSAP(() => {
    gsap.from('#chip', {
      scrollTrigger: {
        trigger: '#chip',
        start: '20% bottom'
      },
      opacity: 0,
      scale: 2,
      duration: 2,
      ease: 'power2.inOut'
    })

    animateWithGsap('.g_fadeIn', {
      opacity: 1,
      y: 0,
      duration: 1,
      ease: 'power2.inOut'
    })
  }, []);

  return (
    <section className="common-padding">
      <div className="screen-max-width">
        <div id="chip" className="flex justify-center items-center w-full my-20">          
          <video 
            playsInline 
            id="chip-video" 
            className="w-1/2 h-auto object-cover object-center" 
            preload="none" 
            muted 
            autoPlay 
            ref={videoRef}
          >
            <source src={chipVideo} type="video/mp4" />
          </video>
        </div>

        <div className="flex flex-col items-center">
          <h2 className="hiw-title">
          Zero-Blockchain Technology.
          <br /> Transforming digital banking.
          </h2>

          <p className="hiw-subtitle pb-0">
          Experience the revolution. Zero-Bank introduces the most advanced blockchain framework yet, redefining security, transparency, and speed in financial services.
          </p>
        </div>

        <div className="mt-10 md:mt-20 mb-14">
          <div className="relative h-full flex-center">
            <div className="overflow-hidden">
              <img 
                src={frameImg}
                alt="frame"
                className="bg-transparent relative z-10"
              />
            </div>
            <div className="hiw-video">
                <video className="pointer-events-none" playsInline preload="none" muted autoPlay ref={videoRef}>
                  <source src={frameVideo} type="video/mp4" />
                </video>
              </div>
          </div>
          <p className="text-gray font-semibold text-center mt-9 pt-5">Zero-Bank: Zero-Effort Investing</p>
          </div>

          <div className="hiw-text-container">
                <div className="flex flex-1 justify-center flex-col">
                  <p className="hiw-text g_fadeIn">
                  Zero-Bank's platform leverages the cutting-edge Zero-Blockchain technology&nbsp;{' '}
                    <span className="text-white">
                    to deliver unprecedented security and efficiency in digital banking
                    </span>.
                  </p>

                  <p className="hiw-text g_fadeIn">
                  Experience the future of finance with seamless transactions, {' '}
                    <span className="text-white">
                    unmatched speed, and the ultimate
                    </span>,
                    in cryptographic security.
                  </p>
                </div>
              

              <div className="flex-1 flex justify-center flex-col g_fadeIn">
                <p className="hiw-text">New on the Line</p>
                <p className="hiw-big">Zero-Blockchain.XYZ</p>
                <p className="hiw-big">Zero-Flow - Web 2.0</p>
                <p className="hiw-big">Zero-Crypto-Wallet - Web 3.0</p>
                <p className="hiw-big">Zero-Pulse - Financial Assets Application</p>
                <p className="hiw-big">Zero-Wave - Mobile Banking Application</p>
                <p className="hiw-big">Zero-Pay - Global Payments Solution</p>
                <p className="hiw-big">Zero-Comply - Reg. Compliance Automation</p>
                <p className="hiw-big">Zero-Supervise - Financial Market Monitoring</p>
                <p className="hiw-big">Zero-Lend - Blockchain Peer-to-Peer Lending</p>
                <p className="hiw-big">Zero-Core - Blockchain Banking Solutions</p>
                <p className="hiw-big">Zero-Insure - Real-Time Data-Driven Insurance</p>
                <p className="hiw-big">Zero-Wealth - Hybrid Investment Advisory</p>
                <p className="hiw-big">Zero-Green - Eco-Friendly Crowdfunding</p>
                <p className="hiw-big">Zero-Insight - AI-Powered Personal Fin. Advice</p>
                <p className="hiw-big">Zero-Trade - AI-Powered Trading Desktop App</p>
                <p className="hiw-big">Zero-Predict - Fraud Detection for Blockchain</p>
                <p className="hiw-big">Zero-Stocks - AI-Mobile Stock Market App</p>
                <p className="hiw-big">Zero-Bank - A New Way To Money Digitally</p>                
              </div>
              </div>
            </div>
    </section>
  )
}

export default HowItWorks