// src/components/Hero.jsx

import gsap from 'gsap';
import { useGSAP } from '@gsap/react';
import { heroVideo, smallHeroVideo } from '../utils';
import { useEffect, useState } from 'react';
import { TiLocationArrow } from 'react-icons/ti';
import HeroButton from './HeroButton'; // Import the HeroButton component

const Hero = () => {
  const [videoSrc, setVideoSrc] = useState(
    window.innerWidth < 760 ? smallHeroVideo : heroVideo
  );

  const handleVideoSrcSet = () => {
    if (window.innerWidth < 760) {
      setVideoSrc(smallHeroVideo);
    } else {
      setVideoSrc(heroVideo);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleVideoSrcSet);

    return () => {
      window.removeEventListener('resize', handleVideoSrcSet); // Fixed 'resize' typo
    };
  }, []);

  useGSAP(() => {
    gsap.to('#hero', { opacity: 1, delay: 2 });
    gsap.to('#cta', { opacity: 1, y: -50, delay: 2 });
  }, []);

  const handleScrollToModelView = () => {
    const modelViewSection = document.getElementById('modelViewSection');
    if (modelViewSection) {
      modelViewSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <section className="w-full bg-black relative h-screen flex flex-col justify-between">
      {/* Top Content */}
      <div className="flex flex-col items-center pt-12 space-y-6">
        <p id="hero" className="hero-title text-center">
          Welcome to Zero-Bank & Zero-Blockchain.xyz ICO
        </p>
        <div className="md:w-10/12 w-9/12 mb-6 max-h-[37.5rem] overflow-hidden">
          <video
            className="pointer-events-none w-full h-full object-cover"
            autoPlay
            muted
            loop
            playsInline={true}
            key={videoSrc}
          >
            <source src={videoSrc} type="video/mp4" />
          </video>
        </div>
      </div><br/><br/>

      {/* CTA Section */}
      <div
        id="cta"
        className="flex flex-col items-center opacity-0 translate-y-20 mb-20"
      >
      <HeroButton
        onClick={handleScrollToModelView}
        ariaLabel="Navigate to Coin and Tokens Section"
        additionalClasses="text-gray-100 border border-gray-200 mt-8 px-2 py-1 bg-teal-500 hover:bg-teal-700 z-30 flex items-center"
      >
        <span className="flex items-center text-gray-100">
          Coin & Tokens
          <TiLocationArrow className="ml-2 text-gray-100 text-xl" />
        </span>
      </HeroButton>

        <p className="mt-4 text-center text-gray-100">
          Our Native Zero-Coin a single payment of <strong>0.34 ETH</strong> or Own Our Zero-Token starting at just <strong>0.0034 ETH</strong>.
        </p>
      </div>
    </section>
  );
};

export default Hero;
